/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MediaContent, MediaType, MediaUploadData, MediaUrlData } from '../../../../shared/models';
import { ALLOWED_DOCUMENT_EXTENSIONS } from '@app/app/editor/components/editor-toolbar/file-upload/file-upload.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropDialogComponent } from '../../../../shared/components/image-crop-dialog/image-crop-dialog.component';
import {
	CoverImageUploadDialogComponent
} from '../../../../shared/components/cover-image-upload-dialog/cover-image-upload-dialog.component';
import { UnsplashPhoto } from '../../../../shared/models/images/unsplash-images.model';
import { LanguageCodeHelper } from '../../../../shared/helpers/language-code-helper';
import {
    VideoUploadDialogComponent
} from '../../../../shared/components/video-upload-dialog/video-upload-dialog.component';
import { v4 as uuidv4 } from 'uuid';
import { IMAGE_UPLOAD_DIALOG_CONFIG } from '@app/app/shared/models/config/dialog-config.model';


@Component({
	selector: 'ptl-editor-toolbar-option',
	templateUrl: './editor-toolbar-option.component.html',
	styleUrls: ['./editor-toolbar-option.component.scss']
})
export class EditorToolbarOptionComponent implements OnInit {

	@Input() buttonTitle: string;

	@Input() buttonAriaLabel: string;

	@Input() buttonIcon: string;

	@Input() buttonType: ToolbarButtonType;

	@Input() uploadType: ToolbarButtonUploadType;

	@Input() formType: ToolbarButtonFormType;

	@Output() optionSelected = new EventEmitter<ToolbarButtonType>();

  @Output() mediaContentAdded = new EventEmitter<MediaContent>();

  @ViewChild('uploadInput') private uploadInput: ElementRef;

  fileUploadTypes = '';

  private readonly fileUploadFileTypes = 'image/*,audio/*,video/*,' + ALLOWED_DOCUMENT_EXTENSIONS;
  private readonly imageUploadFileTypes = 'image/*';
  private readonly audioUploadFileTypes = 'audio/*';
  private readonly videoUploadFileTypes = 'video/*';

	constructor(
		private dialog: MatDialog
	) {
	}

	ngOnInit() {

		if ( this.uploadType ) {
			this.setUploadFileType(this.uploadType);
		}
	}

	onOptionClick() {
		this.optionSelected.emit(this.buttonType);
	}

	onFileChanged( eventData: Event ) {
		const selectedFile: File = (eventData.target as HTMLInputElement).files[0];

		const fileUploadData: MediaUploadData = {
			type: 'MEDIA_UPLOAD',
			file: selectedFile,
			mediaType: this.getFileType(selectedFile),
			replace: false,
			uuid4: uuidv4(),
		};

		if ( fileUploadData.mediaType === 'IMAGE' ) {
			const dialogRef = this.dialog.open(ImageCropDialogComponent, {
				width: '90vw',
				minWidth: '15.625rem',
				maxWidth: '37.5rem',
				maxHeight: '80vh',
				position: {
					top: '10vh'
				},
				direction: LanguageCodeHelper.getBodyLanguageDir(),
				panelClass: 'ptl-mat-dialog',
				backdropClass: 'dialog-backdrop',
				data: {
					imageFile: selectedFile,
					cropperShape: 'rectangle'
				}
			});

			const dialogSubscription = dialogRef.afterClosed().subscribe(( data ) => {
				if ( data ) {
					fileUploadData.file = data.croppedImageFile;
					this.mediaContentAdded.emit(fileUploadData);
				}

				(eventData.target as HTMLInputElement).value = '';
				dialogSubscription.unsubscribe();
			});
		} else {
			this.mediaContentAdded.emit(fileUploadData);
		}
	}

	onImageUploadClick() {
		const dialogRef = this.dialog.open(CoverImageUploadDialogComponent, {
      ...IMAGE_UPLOAD_DIALOG_CONFIG,
			direction: LanguageCodeHelper.getBodyLanguageDir(),
      data: {
        advancedImageCrop: true
      }
		});

		dialogRef.afterClosed().subscribe(( data ) => {
			if ( data ) {
				if ( data.croppedImageFile ) {
					this.handleUploadedCoverImage(data);
				} else if ( data.urls ) {
					this.handleUnsplashPhotoCoverImage(data);
				}
			}
		});
	}

  onVideoUploadClick() {
    const dialogRef = this.dialog.open(VideoUploadDialogComponent, {
        width: '47rem',
        height: '35rem',
        maxHeight: '80vh',
        position: {
            top: '10vh',
        },
        direction: LanguageCodeHelper.getBodyLanguageDir(),
        restoreFocus: true,
        panelClass: 'ptl-mat-dialog',
        backdropClass: 'dialog-backdrop',
        data: {},
    });

    dialogRef.afterClosed().subscribe((data: File) => {
      if (data) {
        const fileUploadData: MediaUploadData = {
          type: 'MEDIA_UPLOAD',
          file: data,
          mediaType: this.getFileType(data),
          replace: false,
		  uuid4: uuidv4(),
      };
      this.mediaContentAdded.emit(fileUploadData);
      }
    });
  }

  private handleUnsplashPhotoCoverImage(data: UnsplashPhoto) {
    const fileUploadData: MediaUploadData = {
        type: 'MEDIA_URL',
        replace: false,
        contentHtml: '<img src=' + data.links.download_location + '></img>',
        contentUrl: data.links.download_location,
        mediaType: 'IMAGE',
		uuid4: uuidv4(),
    } as MediaUrlData;

		this.mediaContentAdded.emit(fileUploadData);
	}

	private handleUploadedCoverImage( data ) {
		const fileType = this.getFileType(data.croppedImageFile);

		if ( fileType !== 'IMAGE' ) {
			return;
		}

		const fileUploadData: MediaUploadData = {
			type: 'MEDIA_UPLOAD',
			replace: false,
			file: data.croppedImageFile,
			mediaType: fileType,
			uuid4: uuidv4(),
		};

		this.mediaContentAdded.emit(fileUploadData);
	}

	private getFileType( file: File ): MediaType {
		if ( file.type.match('image.*') ) {
			return 'IMAGE';
		}
		if ( file.type.match('video.*') ) {
			return 'STREAM_VIDEO';
		}
		if ( file.type.match('audio.*') ) {
			return 'AUDIO';
		}
		return 'DOCUMENT';
	}

	private setUploadFileType( type: ToolbarButtonUploadType ): void {
		if ( type === 'IMAGE' ) {
			this.fileUploadTypes = this.imageUploadFileTypes;
		} else if ( type === 'AUDIO' ) {
			this.fileUploadTypes = this.audioUploadFileTypes;
		} else if ( type === 'VIDEO' ) {
			this.fileUploadTypes = this.videoUploadFileTypes;
		} else {
			this.fileUploadTypes = this.fileUploadFileTypes;
		}
	}
}

export type ToolbarButtonType = 'SEPARATOR' | 'UPLOAD' | 'EMBED' | 'LINK' | 'FORM' | 'CHART' | 'TABLE';

export type ToolbarButtonFormType =
	'TEXTBOX' |
	'PRIVATE_NOTE' |
	'USER_FILE_UPLOAD' |
	'CHECKBOX' |
	'COLLECTOR' |
	'QUIZ' |
	'BOOKING' |
	'CONNECTED_TEXTBOX' |
	'QUESTION_FORM';

export type ToolbarButtonUploadType = 'IMAGE' | 'FILE' | 'AUDIO' | 'VIDEO' | 'TABLE';
