<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->
<ng-container *ngIf="tableauV2FeatureFlagEnabled else tableauV1">
  <div class="tableau__main-container-v2">
    <tableau-viz
      *ngIf="dataIsLoaded"
      [id]="1"
      [src]="JISC_TABLEAU_URL"
      token="{{connectedAppToken}}"
      toolbar="top">
    </tableau-viz>
  </div>
</ng-container>

<ng-template #tableauV1>
  <div class="tableau__main-container">

    <div class="tableau__iframe-container" *ngIf="iframeSrc">
      <iframe [src]="iframeSrc" allowfullscreen title="{{title}}"></iframe>
    </div>

    <div class="no-data" *ngIf="!iframeSrc && dataIsLoaded">
      {{ 'translations.tableauNoData' | transloco }}
    </div>

  </div>
</ng-template>
