import {
	ToolbarButtonFormType,
	ToolbarButtonType,
	ToolbarButtonUploadType
} from '@app/app/editor/components/editor-toolbar/editor-toolbar-option/editor-toolbar-option.component';

export function getFileUploadImage( uploadType: ToolbarButtonUploadType ): string {
	if ( uploadType === 'IMAGE' ) {
		return '/assets/toolbar/image.png';
	}
	if ( uploadType === 'FILE' ) {
		return '/assets/toolbar/file.png';
	}
	if ( uploadType === 'AUDIO' ) {
		return '/assets/toolbar/audio.png';
	}
	if ( uploadType === 'VIDEO' ) {
		return '/assets/toolbar/video.png';
	}
	return '';
}

export function getTooltipImageByType( type: ToolbarButtonType, uploadType: ToolbarButtonUploadType | ToolbarButtonFormType ): string {
	if ( type === 'UPLOAD' ) {
		return getFileUploadImage(uploadType as ToolbarButtonUploadType);
	}
	if ( type === 'FORM' ) {
		return getFormTypeImage(uploadType as ToolbarButtonFormType);
	}
	if ( type === 'LINK' ) {
		return '/assets/toolbar/link.png';
	}
	if ( type === 'EMBED' ) {
		return '/assets/toolbar/code.png';
	}
	if ( type === 'SEPARATOR' ) {
		return '/assets/toolbar/divider.png';
	}
	if ( type === 'CHART' ) {
		return '/assets/toolbar/chart.png';
	}
	if ( type === 'TABLE' ) {
		return '/assets/toolbar/table.png';
	}
	return '';
}

export function etTooltipImageByType( type: ToolbarButtonType, uploadType: ToolbarButtonUploadType | ToolbarButtonFormType ): string {
	if ( type === 'UPLOAD' ) {
		return getFileUploadImage(uploadType as ToolbarButtonUploadType);
	}
	if ( type === 'FORM' ) {
		return getFormTypeImage(uploadType as ToolbarButtonFormType);
	}
	if ( type === 'LINK' ) {
		return '/assets/toolbar/link.png';
	}
	if ( type === 'EMBED' ) {
		return '/assets/toolbar/code.png';
	}
	if ( type === 'SEPARATOR' ) {
		return '/assets/toolbar/divider.png';
	}
	if ( type === 'CHART' ) {
		return '/assets/toolbar/chart.png';
	}
	if ( type === 'TABLE' ) {
		return '/assets/toolbar/table.png';
	}
	return '';
}

export function getFormTypeImage( formType: ToolbarButtonFormType ): string {
	if ( formType === 'TEXTBOX') {
		return '/assets/toolbar/text-box.png';
	}
	if ( formType === 'PRIVATE_NOTE' ) {
		return '/assets/toolbar/private.png';
	}
	if ( formType === 'CHECKBOX' ) {
		return '/assets/toolbar/check-box.png';
	}
	if ( formType === 'USER_FILE_UPLOAD' ) {
		return '/assets/toolbar/file-form.png';
	}
	if ( formType === 'COLLECTOR' ) {
		return '/assets/toolbar/quiz.png';
	}
	if ( formType === 'QUIZ' ) {
		return '/assets/toolbar/quiz.png';
	}
  if ( formType === 'BOOKING' ) {
    return '/assets/toolbar/booking.png';
  }
  if ( formType === 'QUESTION_FORM' ) {
    return '/assets/toolbar/question-form.png';
  }
  if ( formType === 'CONNECTED_TEXTBOX' ) {
    return '/assets/toolbar/connected-textbox.png';
  }
	return '';
}
