/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit, ViewChild
} from '@angular/core';
import { ChartContent, ChartType } from '../../../models/editor/chart-content.model';
import { ChartConfiguration, ChartDataset, ChartType as ChartJsType } from 'chart.js';
import { Select } from '@ngxs/store';
import { UserAuthState } from '../../../../user-auth/store/user-auth.state';
import { Observable } from 'rxjs';
import { UserDetails } from '../../../../user-auth/models';
import { LocalTimeHelper } from '../../../helpers/local-time-helper';
import { cloneDeep } from 'lodash-es';
import { SnackbarHelper } from '../../../helpers/snackbar-helper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '../../../services/translation/translation.service';
import { Organization } from '../../../models';

@Component({
  selector: 'ptl-chart-preview',
  templateUrl: './chart-preview.component.html',
  styleUrls: ['./chart-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartPreviewComponent implements OnInit, OnDestroy {

  /** Receives the Form object */
  @Input() chartContent: ChartContent;

  @Select(UserAuthState.userDetailsData)
  userDetailsData$: Observable<UserDetails>;

  @Select(UserAuthState.organizationDetails)
  private organizationData$: Observable<Organization>;


  @ViewChild('Chart') chart: ElementRef;
  @ViewChild('circlePoints') circlePoints: ElementRef;
  @ViewChild('circleLines') circleLines: ElementRef;
  chartType: ChartJsType;
  chartData: ChartDataset[] = [];
  chartDataColors: string[] = [];
  chartDataAlphaColors: string[] = [];

  chartDataLabels: string[];
  chartSectionItems: {title: string; sectionUid:string}[];

  chartId = (new Date()).getTime();
  chartOptions: ChartConfiguration['options'];
  chartPlugins = [];
  chartVersionStart: number;
  chartVersionEnd: number;

  private subscriptionEnd$ = new EventEmitter<void>();

  constructor(
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private translationService: TranslationService,
  ) {

  }


  ngOnInit() {
    if (this.chartContent) {
      const chartType = this.chartContent.chartType;
      if (this.chartContent.versionedScores?.length) {
        this.chartContent.versionedScores = this.chartContent.versionedScores.sort((a, b) => {
          return +new Date(b.completedOn) - +new Date(a.completedOn);
        });
        this.chartVersionStart = this.chartContent.versionedScores[1]?.version;
        this.chartVersionEnd = this.chartContent.versionedScores[0]?.version;
      }

      if (chartType === 'RADIAL') {
        this.setChartOptions(chartType);
        this.showPolarPieChart();
      } else if (chartType === 'BAR') {
        this.showBarChart();
      }
    }


  }

  ngOnDestroy() {
    this.subscriptionEnd$?.emit();
    document.getElementById('pics')?.remove();
  }

  scrollTo(id: string) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getLocalDateTimeFormat(date: Date) {
    return LocalTimeHelper.getLocalDateTime(date).format('DD MMMM YYYY, HH:mm');
  }

  applyDateFilter() {
    const latestScores = [];
    if (this.chartVersionStart === this.chartVersionEnd) {
      SnackbarHelper.showTranslatableSnackBar(
        this.ngZone, this.snackBar, this.translationService, 'report.message.error.selectedDifferentDates'
      );
      return;
    } else {
      latestScores.push(this.chartContent.versionedScores.find(score => this.chartVersionEnd === score.version));
      latestScores.push(this.chartContent.versionedScores.find(score => this.chartVersionStart === score.version));
    }
    const contentItems = this.chartContent.items.filter(item => item.scored !== null);
    this.chartData = this.calculateScoresAndGetChartData(latestScores, contentItems);
  }


  private showPolarPieChart() {
    this.chartType = 'polarArea';
    this.chartOptions = {
      ...this.chartOptions,
      layout: {
        padding: {
          top: 5,
          bottom: 5,
        },
      },
      onClick: (evt, items) => {
        if (items && items.length) {
          const currentItem = items[0];

          const chartSectionItemUid = this.chartSectionItems[currentItem.index]?.sectionUid;

          if (chartSectionItemUid) {
            this.scrollTo(chartSectionItemUid);
          }
        }
      },
      scales: {
        r: {
          min: 0,
          max: 100,
          ticks: {
            display: false
          }
        }
      },
      scale: {
        ticks: {
          min: 0,
          max: 100,
          stepSize: 20,
          display: false
        }
      },
    } as ChartConfiguration['options'];

    const labels: string[] = [];
    const sectionItems: {title: string; sectionUid:string}[] = [];
    const colors: string[] = [];
    const contentItems = this.chartContent.items.filter(item => item.scored !== null);
    for (const item of contentItems) {
      labels.push(item.title);
      sectionItems.push({title: item.title, sectionUid:item.sectionUid});
      colors.push(this.getTransparentColor(item.color, 0.5));
      this.chartDataColors.push(item.color);
    }
    const latestScores = cloneDeep(this.chartContent.versionedScores);
    if (latestScores.length > 2) {
      latestScores.length = 2;
    }
    this.chartData = this.calculateScoresAndGetChartData(latestScores, contentItems);
    this.chartDataAlphaColors = colors;
    this.chartDataLabels = labels;
    this.chartSectionItems = sectionItems;


    setTimeout(() => {
      this.updateCirclePoints();
      window.addEventListener('resize', () => {
        document.querySelectorAll('.circle-1').forEach(item => {
          (item as HTMLElement).innerHTML = '';
        });
        this.updateCirclePoints();
      }, false);
    }, 0);
  }

  private showBarChart() {
    const labels: string[] = [];
    const sectionItems: {title: string; sectionUid:string}[] = [];
    const contentItems = this.chartContent.items.filter(item => item.scored !== null);
    for (const item of contentItems) {
      labels.push(item.title);
      sectionItems.push({title: item.title, sectionUid:item.sectionUid});
    }
    const latestScores = cloneDeep(this.chartContent.versionedScores);
    if (latestScores.length > 2) {
      latestScores.length = 2;
    }

    this.chartData = this.calculateScoresAndGetChartData(latestScores, contentItems);
    this.chartDataLabels = labels;
    this.chartSectionItems = sectionItems;
  }

  private calculateScoresAndGetChartData(latestScores, contentItems) {
    const scoredData: ChartDataset[] = [];
    let alphaColor = 0.5;
    for (let i = 0; i < latestScores.length; i++) {
      if (i > 0) {
        alphaColor = this.chartType !== 'polarArea' ? 0.8 : 1;
      }
      const scoredItem = latestScores[i];
      let scores: number[] = [];
      let bgColors: string[] & CanvasPattern[] = [];
      let borderColor: string[] = [];
      let hoverBackgroundColor: string[] & CanvasPattern[] = [];
      let hoverBorderColor: string[] = [];
      let hoverBorderWidth: number[] = [];
      const items = scoredItem.scores.filter(item => item.userScore !== null);
      // TODO Armen or Christian please refactor this part
      for (let k = 0; k < contentItems.length; k++) {
        const color = contentItems[k].color;
        for (let j = 0; j < items.length; j++) {
          const item = items[j];
          if (contentItems[k].itemUid === item.tagId) {
            const score = Math.floor(item.userScore * 100 / item.maxScore);
            scores.push(score > 100 ? 100 : score);
            if (this.chartType === 'polarArea') {
              bgColors.push(i === 0 ? this.getTransparentColor(color, alphaColor) : 'transparent');
              borderColor.push(i === 0 ? 'transparent' : color);
              hoverBackgroundColor.push(i === 0 ? this.getTransparentColor(color, 0.3) : 'transparent');
              hoverBorderColor.push(i === 0 ? 'transparent' : color);
              hoverBorderWidth.push(i === 0 ? 0 : 2);
            } else {
              const tpColor = this.getTransparentColor(color, alphaColor)
              const gradient = `repeating-linear-gradient(45deg, ${tpColor}, ${tpColor} 0.313rem, ${color} 0.125rem, ${color} 0.438rem)`
              bgColors.push(i === 0 ? tpColor : gradient);
              hoverBackgroundColor.push(i === 0 ? tpColor : gradient);
            }
          }
        }
      }

      if (scores.length) {
        scoredData.push({
          data: scores,
          backgroundColor: bgColors,
          hoverBorderColor: hoverBorderColor,
          hoverBorderWidth: hoverBorderWidth,
          hoverBackgroundColor: hoverBackgroundColor,
          borderColor: borderColor,
          borderWidth: i === 0 ? 0 : this.chartType !== 'polarArea' ? 0 : 2,
        });
      } else {
        scores = [];
        bgColors = [];
        borderColor = [];
        hoverBackgroundColor = [];
        hoverBorderColor = [];
        hoverBorderWidth = [];
      }
    }
    return scoredData;
  }

  private getTransparentColor(color: string, opacity?: number) {
    if (!color) {
      return '';
    }
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const alpha = opacity ? opacity : 0.4;
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  }

  calculatePercentage(version: number, dataIndex: number): string {
    const versionedScore = this.chartContent.versionedScores.find(v => v.version === version);
    if (!versionedScore) return '';
    const userScore = versionedScore.scores[dataIndex]?.userScore || 0;
    const maxScore = versionedScore.scores[dataIndex]?.maxScore || 1;
    const percentage = Math.round((userScore / maxScore) * 100);
    return `  (${percentage}%)`;
  }

  private setChartOptions(chartType: ChartType) {

    /* eslint-disable-next-line @typescript-eslint/no-this-alias */
    const _self = this;
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'index',
          enabled: false,
          external: function (context) {
            let tooltipEl = document.getElementById('chartTooltip');
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartTooltip';
              tooltipEl.className = 'chart-tooltip';
              tooltipEl.innerHTML = '<div class="tooltip-content"></div>';
              document.body.appendChild(tooltipEl);
            }
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            if (tooltipModel.body) {
              let innerHtml = '<div class="tooltip-content-inner">';
              let tooltipTitles = '<div class="tooltip-titles">';
              const dataPoints = tooltipModel.dataPoints;
              if (chartType !== 'BAR') {
                dataPoints.forEach((body, i) => {
                  if (_self.chartContent.items[body.dataIndex].icon &&
                    _self.chartContent.items[body.dataIndex].icon.iconImageUrl && i === 0) {
                    innerHtml += `<div class="tooltip-image">
                      <img src="${_self.chartContent.items[body.dataIndex].icon.iconImageUrl}" alt="" width="30" height="30" /></div>`;
                  }
                });
              }
              dataPoints.forEach((body, i) => {
                let label = '';
                let percentage = '';
                let colorHtml = '';
                if (_self.chartType === 'polarArea') {
                  label = i === 0 ?
                    _self.getLocalDateTimeFormat(_self.chartContent.versionedScores.find(
                      version => version.version === _self.chartVersionEnd).completedOn
                    ) :
                    _self.getLocalDateTimeFormat(_self.chartContent.versionedScores.find(
                      version => version.version === _self.chartVersionStart).completedOn
                    );
                  if (_self.chartContent.showPercentage) {
                    percentage = i === 0
                      ? _self.calculatePercentage(_self.chartVersionEnd, body.dataIndex)
                      : _self.calculatePercentage(_self.chartVersionStart, body.dataIndex);
                  }

                  colorHtml = i === 0 ?
                    `<span class="no-border">
                            <span style="background-color: ${_self.getTransparentColor(_self.chartDataColors[body.dataIndex], 0.3)}"></span>
                          </span>`
                    : `<span style="border-color: ${_self.chartDataColors[body.dataIndex]};">
                            <span style="background-color: ${_self.getTransparentColor(_self.chartDataColors[body.dataIndex], 0.3)}"></span>
                          </span>`;
                }
                tooltipTitles += '<div class="tooltip-title">' + colorHtml + label + percentage + '</div>';
              });
              innerHtml += (tooltipTitles + '</div>');

              const tableRoot = tooltipEl.querySelector('div');
              tableRoot.innerHTML = innerHtml;
            }
            // `this` will be the overall tooltip
            // @ts-ignore: Official example from https://www.chartjs.org/docs/latest/configuration/tooltip.html#external-custom-tooltips
            const position = context.chart.canvas.getBoundingClientRect();
            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.left = (position.left + window.pageXOffset +
              tooltipModel.caretX - (chartType === 'BAR' ? tooltipModel.caretX / 2 : 0)) + 'px';
            tooltipEl.style.top = (position.top + window.pageYOffset +
              tooltipModel.caretY - (chartType === 'BAR' ? tooltipEl.offsetHeight / 2 : 0)) + 'px';
          },
          callbacks: {
            // @ts-ignore
            label: (tooltipItem, value) => {
              return `${tooltipItem.label}`
            },
          },
        },
      },
    };
  }

  private updateCirclePoints() {
    const container = this.circlePoints?.nativeElement ? this.circlePoints.nativeElement as HTMLElement : '';
    if (!container) {
      return;
    }

    const chartHeight = this.chart?.nativeElement ? (this.chart?.nativeElement as HTMLElement).offsetHeight : '';
    const circleLinesContainer = this.circleLines?.nativeElement ? this.circleLines.nativeElement as HTMLElement : '';


    if (!circleLinesContainer || !chartHeight) {
      return;
    }

    container.style.width = ((chartHeight + 50)) + 'px';
    container.style.height = ((chartHeight + 50)) + 'px';
    circleLinesContainer.style.width = ((chartHeight + 50)) + 'px';
    circleLinesContainer.style.height = ((chartHeight + 50)) + 'px';
    const points = container.querySelectorAll('.f_point');
    const width = chartHeight + 50;
    const height = chartHeight + 50;
    const radius = width / 2;
    const chartXCenter = width / 2;
    const chartYCenter = height / 2;
    const step = (2 * Math.PI) / points.length;
    let angle = -0.5 * Math.PI + step / 2;
    points.forEach((point, index) => {
      const element = (point as HTMLElement);
      element.style.fontSize = '0.875rem';

      const labelXCoord = Math.round(chartXCenter + radius * Math.cos(angle));
      const delta = (chartXCenter > labelXCoord) ? element.offsetWidth : 0;
      let labelYCoord = Math.round(chartYCenter + radius * Math.sin(angle) - element.offsetHeight);
      labelYCoord = labelXCoord >= chartXCenter ? labelYCoord + 2 : labelYCoord;

      element.style.left = ((labelXCoord - delta)) + 'px';
      element.style.top = (labelYCoord) + 'px';
      const lineXCoord = labelXCoord;
      const lineYCoord = Math.round(chartYCenter + radius * Math.sin(angle));

      angle += step;

      this.drawLine(chartXCenter, chartYCenter, lineXCoord, lineYCoord, index);
    });

  }

  private drawLine(x1: number, y1: number, x2: number, y2: number, i: number) {
    if (y1 < y2) {
      let pom = y1;
      y1 = y2;
      y2 = pom;
      pom = x1;
      x1 = x2;
      x2 = pom;
    }

    let a = Math.abs(x1 - x2);
    let b = Math.abs(y1 - y2);
    let c = 0;
    const sx = (x1 + x2) / 2;
    const sy = (y1 + y2) / 2;
    const width = Math.sqrt(a * a + b * b);
    const x = sx - width / 2;
    const y = sy;

    a = width / 2;

    c = Math.abs(sx - x);

    b = Math.sqrt(Math.abs(x1 - x) * Math.abs(x1 - x) + Math.abs(y1 - y) * Math.abs(y1 - y));

    const cosb = (b * b - a * a - c * c) / (2 * a * c);
    const rad = Math.acos(cosb);
    const deg = (rad * 180) / Math.PI;

    const div = document.createElement('div') as HTMLElement;
    div.style.left = (x) + 'px';
    div.style.top = (y) + 'px';
    div.style.transform = 'rotate(' + deg + 'deg)';
    div.style.width = (width) + 'px';
    div.style.backgroundColor = this.chartDataAlphaColors[i];
    div.setAttribute('class', 'line');
    this.circleLines.nativeElement.appendChild(div);
  }

}
