<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="admin-members-table-container">
  <div class="admin-member__list-loader" *ngIf="isLoaderActive">
    <div class="admin-member__list-loader-inner">
      <mat-spinner [diameter]="40"></mat-spinner>
    </div>
  </div>

  <div *ngIf="members?.length" [class.has-pagination]="(paginationLength > 1)">
    <div class="border-line"></div>
    <div #membersListHeader class="members-list__container members-list__container__header f_members-list__container__header"
         (scroll)="onHeaderContainerScroll()"
         [class.expanded]="expanded">
      <table
        class="members-list__main"
        tabindex="0"
        [attr.aria-label]="('translations.global.ariaLabels.usersList' | transloco)">
        <thead>
        <tr>
          <th *ngIf="isColumnEnabled('SELECT')"
              class="checkbox-cell header-align"
              [class.expanded]="expanded">

            <mat-checkbox [(ngModel)]="allUsersChecked"
                          [tabIndex]="0"
                          color="primary"
                          [attr.aria-label]="allUsersChecked ? ('translations.global.ariaLabels.uncheckAllUsers' | transloco) : ('translations.global.ariaLabels.checkAllUsers' | transloco)"
                          (change)="onAllCheckboxChange()"
                          class="members-list__checkbox">
              <span class="cdk-visually-hidden">{{ allUsersChecked ? ('translations.global.ariaLabels.uncheckAllUsers' | transloco) : ('translations.global.ariaLabels.checkAllUsers' | transloco) }}</span>
            </mat-checkbox>

          </th>

          <th *ngIf="isColumnEnabled('EXTERNAL_ID')"
              class="external-id header-align"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.ariaLabels.id' | transloco)">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.id' | transloco }}</span>
            </div>
          </th>

          <th *ngIf="isColumnEnabled('FIRST_NAME')"
              class="first-name header-align pointer"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.ariaLabels.orderByFirstName' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('firstName')"
              (click)="changeListOrdering('firstName')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.firstName' | transloco }}</span>
              <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'firstName'">
                {{ (defaultOrderBy === 'firstName' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true"
                 *ngIf="!(defaultOrderBy === 'firstName')">unfold_more</i>
            </div>
          </th>
          <th *ngIf="isColumnEnabled('LAST_NAME')"
              class="last-name header-align sticky-columns-right-border pointer"
              [class.expanded]="expanded"
              [tabIndex]="0"
              [attr.aria-label]="('translations.global.ariaLabels.orderByLastName' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('lastName')"
              (click)="changeListOrdering('lastName')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.lastName' | transloco }}</span>
              <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'lastName'">
                {{ (defaultOrderBy === 'lastName' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true"
                 *ngIf="!(defaultOrderBy === 'lastName')">unfold_more</i>
            </div>
          </th>
          <th *ngIf="isColumnEnabled('EMAIL')"
              [class.expanded]="expanded"
              [attr.aria-label]="'translations.global.ariaLabels.email' | transloco">
            <span class="column-name small bold">{{ 'translations.global.label.email' | transloco }}</span>
          </th>
          <th *ngIf="isColumnEnabled('WILL_ATTEND')"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.label.registered' | transloco)"
              (click)="changeListOrdering('enrollment.registeredOn')">
              <div class="header-title-icon-box">
                <span class="column-name small bold">{{ 'translations.global.label.registered' | transloco }}</span>
                <i *ngIf="defaultOrderBy === 'enrollment.registeredOn'"
                  class="material-icons" aria-hidden="true">
                  {{ (defaultOrderBy === 'enrollment.registeredOn' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                </i>
                <i class="material-icons small-icon" aria-hidden="true"
                   *ngIf="!(defaultOrderBy === 'enrollment.registeredOn')">unfold_more</i>
              </div>
          </th>
          <!-- class role-cell for height update -->
          <th *ngIf="isColumnEnabled('ROLES') && !isEvent"
              class="role-cell"
              [class.expanded]="expanded"
              [attr.aria-label]="'translations.global.ariaLabels.role' | transloco">
            <span class="column-name small bold">{{ 'translations.global.label.role' | transloco }}</span>
          </th>
          <th *ngIf="isColumnEnabled('STATUS')"
              class="pointer"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.ariaLabels.orderByStatus' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering(isCardPage ? 'cardStatus' : 'playlistStatus')"
              (click)="changeListOrdering(isCardPage ? 'cardStatus' : 'playlistStatus')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.status' | transloco }}</span>
              <i class="material-icons" aria-hidden="true"
                 *ngIf="defaultOrderBy === 'cardStatus' || defaultOrderBy === 'playlistStatus'">
                {{ ((defaultOrderBy === 'cardStatus' || defaultOrderBy === 'playlistStatus') && order === 'ASC') ?
                'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true"
                 *ngIf="!(defaultOrderBy === 'cardStatus' || defaultOrderBy === 'playlistStatus')">unfold_more</i>
            </div>
          </th>

          <th *ngIf="isColumnEnabled('ENROL') && (playlistHastTicketsRegistration$ | async)" class="pointer"
            [class.expanded]="expanded" tabindex="0" role="button"
            (click)="changeListOrdering('enrollment.registeredOn')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.registered' | transloco }}</span>
              <i class="material-icons" aria-hidden="true"
                *ngIf="defaultOrderBy === 'enrollment.registeredOn'">
                {{ ((defaultOrderBy === 'cardStatus' || defaultOrderBy === 'enrollment.registeredOn') && order === 'ASC') ?
                'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true"
                *ngIf="!(defaultOrderBy === 'enrollment.registeredOn')">unfold_more</i>
            </div>
          </th>

          <th *ngIf="isColumnEnabled('REVIEW_STATUS')"
              class="pointer"
              [class.expanded]="expanded"
              tabindex="0"
              role="button">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.reviewStatus' | transloco }}</span>
            </div>
          </th>

          <th *ngIf="isColumnEnabled('STARTED')"
              class="pointer"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.ariaLabels.orderByStartedOn' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('startedOn')"
              (click)="changeListOrdering('startedOn')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.started' | transloco }}</span>
              <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'startedOn'">
                {{ (defaultOrderBy === 'startedOn' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true"
                 *ngIf="!(defaultOrderBy === 'startedOn')">unfold_more</i>
            </div>
          </th>
          <th *ngIf="isColumnEnabled('COMPLETED')"
              class="pointer"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.ariaLabels.orderByCompletedOn' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('completedOn')"
              (click)="changeListOrdering('completedOn')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.completed' | transloco }}</span>
              <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'completedOn'">
                {{ (defaultOrderBy === 'completedOn' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true" *ngIf="!(defaultOrderBy === 'completedOn')">unfold_more</i>
            </div>
          </th>
          <th *ngIf="isColumnEnabled('LAST_ACTIVE')"
              class="pointer"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.ariaLabels.orderByLastActiveOn' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('lastActiveOn')"
              (click)="changeListOrdering('lastActiveOn')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.lastActive' | transloco }}</span>
              <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'lastActiveOn'">
                {{ (defaultOrderBy === 'lastActiveOn' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true" *ngIf="!(defaultOrderBy === 'lastActiveOn')">unfold_more</i>
            </div>
          </th>
          <ng-container *ngIf="isPlaylistPage">
            <ng-container *ngFor="let card of members[0].cards; let j = index;">
              <th
                [class.expanded]="expanded">
          <span
            class="small bold max-two-lines"
            [title]="card.header"
            [attr.aria-label]="card.header">
            {{card.header}}
          </span>
              </th>
            </ng-container>
          </ng-container>
          <th *ngIf="isColumnEnabled('JOIN_DATE')"
              class="pointer"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.ariaLabels.orderByJoinDate' | transloco)"
              tabindex="0"
              role="button"
              (keydown.enter)="changeListOrdering('createdAt')"
              (click)="changeListOrdering('createdAt')">
            <div class="header-title-icon-box">
              <span class="column-name small bold">{{ 'translations.global.label.joinDate' | transloco }}</span>
              <i class="material-icons" aria-hidden="true" *ngIf="defaultOrderBy === 'createdAt'">
                {{ (defaultOrderBy === 'createdAt' && order === 'ASC') ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
              </i>
              <i class="material-icons small-icon" aria-hidden="true"
                 *ngIf="!(defaultOrderBy === 'createdAt')">unfold_more</i>
            </div>
          </th>
          <th *ngIf="isColumnEnabled('RESPONSE_DATE')"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.label.responseDate' | transloco)">
            <span class="column-name small bold">{{ 'translations.global.label.responseDate' | transloco }}</span>
          </th>
          <th *ngIf="isColumnEnabled('HAS_ATTENDED')"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.label.hasAttended' | transloco)">
            <span class="column-name small bold">{{ 'translations.global.label.hasAttended' | transloco }}</span>
          </th>
          <th *ngIf="isColumnEnabled('CHECK_IN_METHOD')"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.label.checkInMethod' | transloco)">
            <span class="column-name small bold">{{ 'translations.global.label.checkInMethod' | transloco }}</span>
          </th>
          <th *ngIf="isColumnEnabled('CHECK_IN_TIME')"
              [class.expanded]="expanded"
              [attr.aria-label]="('translations.global.label.checkInTime' | transloco)">
            <span class="column-name small bold">{{ 'translations.global.label.checkInTime' | transloco }}</span>
          </th>
          <!-- class role-cell for height update -->
          <th *ngIf="isColumnEnabled('ROLES') && isEvent"
              class="role-cell"
              [class.expanded]="expanded"
              [attr.aria-label]="'translations.global.ariaLabels.role' | transloco">
            <span class="column-name small bold">{{ 'translations.global.label.role' | transloco }}</span>
          </th>
          <th *ngIf="isColumnEnabled('GROUPS')"
              [class.expanded]="expanded"
              [attr.aria-label]="'translations.global.ariaLabels.group' | transloco">
            <span class="column-name small bold">{{ 'translations.global.label.group' | transloco }}</span>
          </th>
        </tr>
        </thead>
      </table>
    </div>
  </div>


  <div class="members-list__container-outer member-list-body-container" [class.has-pagination]="(paginationLength > 1)">
    <div #membersListContainer class="members-list__container f_members-list__container"
         (scroll)="onBodyContainerScroll()"
         *ngIf="members?.length else emptyList"
         [class.expanded]="expanded">
      <table
        #membersListMain
        class="members-list__main f_members-list__main"
        tabindex="0"
        [attr.aria-label]="('translations.global.ariaLabels.usersList' | transloco)">
        <tbody>
        <ng-container
          *ngFor="let member of members; let i = index">
          <tr
            #memberRow
            class="item-row"
            tabindex="0"
            role="button"
            (mouseover)="setHoveredMember(member._id)"
            (mouseleave)="resetHoveredMember()"
            (focus)="setHoveredMember(member._id)"
            [class.is-hovered]="hoveredUserUid === member._id"
            [class.is-highlighted]="member._id === highlightedMemberId"
            [attr.aria-label]="('translations.global.ariaLabels.openUserRecord' | transloco: {title: member.firstName + ' ' + member.lastName})"
            (keydown.enter)="openUserRecordByUid(member._id)"
            (click)="openUserRecordByUid(member._id)">

            <td *ngIf="isColumnEnabled('SELECT')"
                class="checkbox-cell"
                [class.expanded]="expanded">

              <mat-checkbox
                [tabIndex]="0"
                color="primary"
                [checked]="member.checked"
                [attr.aria-label]="member.checked ? ('translations.global.ariaLabels.uncheck' | transloco: {name: (member.firstName + ' ' + member.lastName)}) : ('translations.global.ariaLabels.check' | transloco: {name: member.firstName + ' ' + member.lastName})"
                (change)="onCheckboxChange(member._id)"
                (click)="$event.stopPropagation()"
              >
                <span class="cdk-visually-hidden">{{ member.checked ? ('translations.global.ariaLabels.uncheck' | transloco: {name: (member.firstName + ' ' + member.lastName)}) : ('translations.global.ariaLabels.check' | transloco: {name: member.firstName + ' ' + member.lastName}) }}</span>
              </mat-checkbox>
            </td>

            <td *ngIf="isColumnEnabled('EXTERNAL_ID')"
                class="external-id"
                [class.expanded]="expanded"
                [attr.aria-label]="member.externalId">
              <span class="extra-small text" matTooltip="{{member.externalId}}" matTooltipClass="medium-tooltip">
                {{ member.externalId }}
              </span>
            </td>

            <td *ngIf="isColumnEnabled('FIRST_NAME')"
                class="first-name"
                [class.expanded]="expanded"
                [attr.aria-label]="member.firstName">
              <div class="profile-image-with-first-name">
                <img class=""
                     src="{{ member.imageUrl ? member.imageUrl : '../../../../../../../../assets/profile_image.png' }}"
                     alt="{{ member.firstName }} {{ member.lastName }}"/>
                <span class="small text" matTooltip="{{member.firstName}}" matTooltipClass="medium-tooltip">
                  {{ member.firstName }}
                </span>
              </div>
            </td>

            <td *ngIf="isColumnEnabled('LAST_NAME')"
                class="last-name sticky-columns-right-border"
                [class.expanded]="expanded"
                [attr.aria-label]="member.lastName">
              <div class="small text" matTooltipClass="medium-tooltip" matTooltip="{{member.lastName}}">
                {{ member.lastName }}
              </div>
            </td>

            <td *ngIf="isColumnEnabled('EMAIL')" [class.expanded]="expanded">
        <span
          class="small"
          [class.expanded]="expanded"
          matTooltip="{{member.email}}"
          matTooltipClass="medium-tooltip"
          [attr.aria-label]="member.email">
          {{ member.email }}</span>
            </td>

            <td *ngIf="isColumnEnabled('ROLES') && !isEvent"
                class="role-cell"
                [class.expanded]="expanded">
              <div #roleContent
                   class="td-expandable-container">
          <span *ngFor="let role of member.roles"
                class="small expandable-text"
                [attr.aria-label]="role">
          {{ role }}</span>

                <button
                  *ngIf="checkOverflow(roleContent)"
                  class="show-more-btn"
                  [class.expanded]="expanded"
                  [class.is-hovered]="hoveredUserUid === member._id"
                  (click)="$event.stopPropagation()"
                  (keydown.enter)="$event.stopPropagation()"
                  [matMenuTriggerFor]="rolesExpandContent">
              <span
                class="extra-small show-more-btn-content">{{ 'translations.membersNew.list.table.showMore' | transloco }}</span>
                </button>
              </div>
              <mat-menu #rolesExpandContent="matMenu">
                <div class="expanded-menu-content">
                  <span class="small">{{ showExpandedContent(member, 'ROLES') }}</span>
                </div>
              </mat-menu>
            </td>

            <td *ngIf="isColumnEnabled('STATUS')"
                [class.expanded]="expanded">
              <div class="icon-text">
                <svg class="svg" role="img" aria-hidden="true"
                     [ngClass]="{
            'positive': getMemberCompletionStatus(member) === 'COMPLETED',
            'warn': getMemberCompletionStatus(member) === 'IN_PROGRESS',
            'negative': getMemberCompletionStatus(member) === 'NOT_STARTED'
            }">
                  <use ogSvgUnify="assets/sprite/sprite.svg#ellipse_6"></use>
                </svg>
                <div [ngSwitch]="getMemberCompletionStatus(member)">
              <span class="small"
                    *ngSwitchCase="'COMPLETED'">{{ 'translations.membersNew.list.table.completed' | transloco }}</span>
                  <span class="small"
                        *ngSwitchCase="'IN_PROGRESS'">{{ 'translations.membersNew.list.table.started' | transloco }}</span>
                  <span class="small"
                        *ngSwitchCase="'NOT_STARTED'">{{ 'translations.membersNew.list.table.notStarted' | transloco }}</span>
                </div>
              </div>
            </td>

            <td *ngIf="isColumnEnabled('ENROL') && (playlistHastTicketsRegistration$ | async)"
                [class.expanded]="expanded">
                <div [class.registered-cell]="member?.enrollment?.registeredOn">
                  <div class="registered-cell--date">{{member?.enrollment?.registeredOn | dateFormat: 'D MMM yyyy HH:mm'}}</div>
                  <div class="icon-selectbox" [class.icon-selectbox--registered]="member?.enrollment?.registeredOn">
                    <svg class="svg" role="img" aria-hidden="true"
                         [class.positive]="getEnrollmentField(member) === 'Registered'"
                         [class.negative]="getEnrollmentField(member) === 'Canceled'">
                      <use ogSvgUnify="assets/sprite/sprite.svg#ellipse_6"></use>
                    </svg>
                    <mat-select
                      class="selectbox small"
                      [attr.aria-label]="('translations.global.ariaLabels.willRegisteredSelectbox' | transloco )"
                      [value]="getEnrollmentFieldNumber(member)"
                      (click)="$event.stopPropagation()"
                      (keydown.enter)="$event.stopPropagation()"
                      (selectionChange)="onEnrolSelect($event, member._id)">
                      <mat-option
                        [value]="0">
                        <span>{{ 'translations.membersNew.list.table.registered' | transloco }}</span>
                      </mat-option>
                      <mat-option
                        [value]="1">
                        <span>{{ 'translations.membersNew.list.table.notRegistered' | transloco }}</span>
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
            </td>

            <td *ngIf="isColumnEnabled('REVIEW_STATUS')"
                [attr.aria-label]="member.review"
                [class.expanded]="expanded">
              <div class="review-status-container" *ngIf="member.review?.status">
                <div class="review-status-badge-container">
                <span class="review-status_badge"
                      [ngClass]="{
                        closed: member.review?.status.open === false,
                        needsReview: member.review?.status.open,
                      }">
                  {{ (member.review?.status | learnerReviewStatus:'text') | transloco }}
                </span>
                </div>

                <div *ngIf="(canManageReviewsPrivileges$ | async)"
                     class="manage-reviews-btn extra-small"
                     role="button"
                     [attr.aria-label]="'translations.global.ariaLabels.tapToOpenReviewsModal' | transloco"
                     (keydown.enter)="openManageReviewersModal(member.review._id, $event)"
                     (click)="openManageReviewersModal(member.review._id, $event)">
                  {{'translations.cards.review.button.reviewers' | transloco }}</div>

                <div *ngIf="(userPlaylistSubmissionSummary$ | async).submissionMode === 'SINGLE'"
                     class="manage-reviews-btn extra-small"
                     role="button"
                     [attr.aria-label]="'translations.global.ariaLabels.undoReview' | transloco"
                     (keydown.enter)="openUndoReviewModal(member.review._id, $event)"
                     (click)="openUndoReviewModal(member.review._id, $event)">
                  {{'translations.cards.review.button.undo' | transloco }}</div>
              </div>
            </td>


            <td *ngIf="isColumnEnabled('STARTED')"
                [class.expanded]="expanded"
                [attr.aria-label]="member.startedOn | dateFormat: 'D MMM yyyy'">
              <span class="small">{{ member.startedOn | dateFormat: 'D MMM yyyy' }}</span>
            </td>

            <td *ngIf="isColumnEnabled('COMPLETED')"
                [class.expanded]="expanded"
                [attr.aria-label]="member.completedOn | dateFormat: 'D MMM yyyy'">
              <span class="small">{{ member.completedOn | dateFormat: 'D MMM yyyy' }}</span>
            </td>

            <td *ngIf="isColumnEnabled('LAST_ACTIVE')"
                [class.expanded]="expanded"
                [attr.aria-label]="member.lastActiveOn | dateFormat :  'D MMM yyyy'">
              <span class="small">
                {{ member.lastActiveOn | dateFormat :  'D MMM yyyy' }}
              </span>
            </td>

            <ng-container *ngIf="isPlaylistPage">
              <ng-container *ngFor="let card of member.cards; let j = index">
                <td
                  [class.expanded]="expanded">
                  <div class="card-status">
                    <ng-container *ngIf="!card.underReview">
                <span *ngIf="card.completed" class="completed-container">
                  <mat-icon aria-hidden="true" class="mat-icon completed">check</mat-icon>
                  <div class="extra-small completed-text">
                    {{ (card.completedOn | dateFormat : 'D MMM yyyy HH:mm') }}
                  </div>
                </span>
                      <span *ngIf="!card.completed && card.startedOn">
                  <mat-icon aria-hidden="true" class="mat-icon warn">loop</mat-icon>
                </span>
                    </ng-container>
                    <span *ngIf="card.underReview" class="small"
                          [attr.aria-label]="'translations.global.ariaLabels.review' | transloco">
                {{ 'translations.global.label.review' | transloco }}</span>
                  </div>
                </td>
              </ng-container>
            </ng-container>

            <td *ngIf="isColumnEnabled('JOIN_DATE')"
                [class.expanded]="expanded"
                [attr.aria-label]="member.createdAt | dateFormat: 'D MMM yyyy'">
              <span class="small">{{ member.createdAt | dateFormat: 'D MMM yyyy' }}</span>
            </td>

            <td *ngIf="isColumnEnabled('WILL_ATTEND')"
                [class.expanded]="expanded">
                <div [class.registered-cell]="member?.event?.registeredOn">
                  <div class="registered-cell--date">{{member?.event?.registeredOn | dateFormat: 'D MMM yyyy HH:mm'}}</div>
                  <div class="icon-selectbox" [class.icon-selectbox--registered]="member?.event?.registeredOn" >
                    <svg class="svg" role="img" aria-hidden="true"
                         [class.positive]="getMemberWillAttendField(member) === 'Yes'"
                         [class.negative]="getMemberWillAttendField(member) === 'No'"
                         [class.warn]="getMemberWillAttendField(member) === 'Waiting list'">
                      <use ogSvgUnify="assets/sprite/sprite.svg#ellipse_6"></use>
                    </svg>
                    <mat-select
                      class="selectbox small"
                      [attr.aria-label]="('translations.global.ariaLabels.willAttendSelectbox' | transloco )"
                      [value]="getMemberWillAttendFieldNumber(member)"
                      (click)="$event.stopPropagation()"
                      (keydown.enter)="$event.stopPropagation()"
                      (selectionChange)="onWillAttendSelect($event, member._id)">
                      <mat-option
                        [value]="0">
                        <span>{{ 'translations.membersNew.list.table.selectboxYes' | transloco }}</span>
                      </mat-option>
                      <mat-option
                        [value]="1">
                        <span>{{ 'translations.membersNew.list.table.selectboxNo' | transloco }}</span>
                      </mat-option>
                      <mat-option
                        *ngIf="isWaitingListEnabled"
                        [value]="2"
                        [disabled]="isWaitingListOperationDisabled"
                        [matTooltip]="disabledWaitingListText">
                        <span>{{ 'translations.membersNew.list.table.selectboxWaitingList' | transloco }}</span>
                      </mat-option>
                    </mat-select>
                  </div>
                </div>
            </td>

            <td *ngIf="isColumnEnabled('RESPONSE_DATE')"
                [class.expanded]="expanded"
                [attr.aria-label]="(getEventResponseDate(member) | dateFormat: 'D MMM yyyy')">
              <span class="small">{{ getEventResponseDate(member) | dateFormat: 'D MMM yyyy' }}</span>
            </td>

            <td *ngIf="isColumnEnabled('HAS_ATTENDED')"
                [class.expanded]="expanded"
                class="small"
                [attr.aria-label]="hasMemberAttendedEvent(member) ? ('translations.membersNew.list.table.selectboxYes' | transloco) : ('translations.membersNew.list.table.selectboxNo' | transloco)">
              <div class="icon-selectbox">
                <svg class="svg" role="img" aria-hidden="true"
                     [class.positive]="hasMemberAttendedEvent(member)"
                     [class.negative]="!hasMemberAttendedEvent(member)">
                  <use ogSvgUnify="assets/sprite/sprite.svg#ellipse_6"></use>
                </svg>
                <mat-select
                  class="selectbox small"
                  [attr.aria-label]="('translations.global.ariaLabels.hasAttendedSelectbox' | transloco )"
                  [value]="hasMemberAttendedEvent(member)"
                  (click)="$event.stopPropagation()"
                  (keydown.enter)="$event.stopPropagation()"
                  (selectionChange)="onHasAttendedEvent($event, member)">
                  <mat-option [value]="true">
                    {{ 'translations.membersNew.list.table.selectboxYes' | transloco }}
                  </mat-option>
                  <mat-option [value]="false">
                    {{ 'translations.membersNew.list.table.selectboxNo' | transloco }}
                  </mat-option>
                </mat-select>
              </div>
            </td>

            <td *ngIf="isColumnEnabled('CHECK_IN_METHOD')"
                [class.expanded]="expanded">
        <span *ngIf="member.event?.checkInMethod === 'MANUAL'"
              class="small"
              [attr.aria-label]="('translations.membersNew.list.table.checkInMethodManual' | transloco )">
          {{ 'translations.membersNew.list.table.checkInMethodManual' | transloco }}
        </span>
              <span *ngIf="member.event?.checkInMethod === 'QR_CODE'"
                    class="small"
                    [attr.aria-label]="('translations.membersNew.list.table.checkInMethodQrCode' | transloco )">
          {{ 'translations.membersNew.list.table.checkInMethodQrCode' | transloco }}
        </span>
              <span *ngIf="member.event?.checkInMethod === 'IMPORT'"
                    class="small"
                    [attr.aria-label]="('translations.membersNew.list.table.checkInMethodImport' | transloco )">
          {{ 'translations.membersNew.list.table.checkInMethodImport' | transloco }}
        </span>
            </td>

            <td *ngIf="isColumnEnabled('CHECK_IN_TIME')"
                [class.expanded]="expanded">
        <span *ngIf="member.event?.checkInMethod"
              class="small">
          {{ getMemberFormattedParticipationTime(member) }}
        </span>
            </td>

            <td *ngIf="isColumnEnabled('ROLES') && isEvent"
                class="role-cell"
                [class.expanded]="expanded">
              <div #roleContent
                   class="td-expandable-container">
          <span *ngFor="let role of member.roles"
                class="small expandable-text"
                [attr.aria-label]="role">
          {{ role }}</span>

                <button
                  *ngIf="checkOverflow(roleContent)"
                  class="show-more-btn"
                  [class.is-hovered]="hoveredUserUid === member._id"
                  (click)="$event.stopPropagation()"
                  (keydown.enter)="$event.stopPropagation()"
                  [matMenuTriggerFor]="rolesExpandContent">
              <span
                class="extra-small show-more-btn-content">{{ 'translations.membersNew.list.table.showMore' | transloco }}</span>
                </button>
              </div>
              <mat-menu #rolesExpandContent="matMenu">
                <div class="expanded-menu-content">
                  <span class="small">{{ showExpandedContent(member, 'ROLES') }}</span>
                </div>
              </mat-menu>
            </td>

            <td *ngIf="isColumnEnabled('GROUPS')"
                [class.expanded]="expanded">
              <div #groupContent
                   class="td-expandable-container">
          <span *ngFor="let group of member.groups"
                class="small expandable-text"
                [attr.aria-label]="group.title">
          {{ group.title }}</span>
                <button
                  *ngIf="checkOverflow(groupContent)"
                  class="show-more-btn"
                  [class.is-hovered]="hoveredUserUid === member._id"
                  (click)="$event.stopPropagation()"
                  (keydown.enter)="$event.stopPropagation()"
                  [matMenuTriggerFor]="groupsExpandContent">
              <span
                class="extra-small show-more-btn-content">{{ 'translations.membersNew.list.table.showMore' | transloco }}</span>
                </button>
              </div>
              <mat-menu #groupsExpandContent="matMenu">
                <div class="expanded-menu-content">
                  <span class="small">{{ showExpandedContent(member, 'GROUPS') }}</span>
                </div>
              </mat-menu>
            </td>

          </tr>
        </ng-container>

        </tbody>
      </table>
    </div>

    <ng-template #emptyList>
      <div class="members-list__empty">
        <div class="members-list__empty-inner">
          <div
            class="header medium bold"
            tabindex="0"
            [attr.aria-label]="'translations.global.ariaLabels.noUsersFound' | transloco">
            {{ 'translations.membersNew.list.table.noUsersFound' | transloco }}
          </div>
          <div class="small">{{ 'translations.membersNew.list.table.noUsersFoundDescription' | transloco }}</div>
        </div>
        <div *ngIf="isEvent || (isPlaylistPage && playlistHastTicketsRegistration$ | async)"
             class="add-someone-manually-btn-empty-list-box">
          <ptl-admin-add-member
            [isPlaylistPage]="isPlaylistPage"
            [isCardPage]="isCardPage"
            [isEvent]="isEvent"
            [members]="members"
            (memberActionTriggered)="onMemberSelected($event)"></ptl-admin-add-member>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="buttons-row f_buttons-row"
       *ngIf="(paginationLength
     || (isEvent || (isPlaylistPage && playlistHastTicketsRegistration$ | async)))
     && members?.length">
    <div *ngIf="isEvent || (isPlaylistPage && playlistHastTicketsRegistration$ | async)"
         class="add-someone-manually-btn-cell"
         [class.expanded]="expanded">
      <ptl-admin-add-member
        [isPlaylistPage]="isPlaylistPage"
        [isCardPage]="isCardPage"
        [isEvent]="isEvent"
        [members]="members"
        (memberActionTriggered)="onMemberSelected($event)"></ptl-admin-add-member>
    </div>
    <td class="pagination-cell" *ngIf="paginationLength" [class.expanded]="expanded">
      <ptl-pagination-block
        [pageSize]="pageSize"
        [totalNumberOfElement]="membersTotalCount"
        [currentPage]="currentPage"
        [showPageSize]="true"
        [startedFromZero]="true"
        [isPaginationVisible]="pageSize < members?.length || membersTotalCount > pageSize"
        (sizeChanged)="onSizeChange($event)"
        (pageChanged)="onPageChange($event)"></ptl-pagination-block>
    </td>

  </div>

  <div #sliderContainer class="slider-container f_slider-container" (scroll)="onCustomSliderScroll()"
       [style.visibility]="!members?.length ? 'hidden' : 'visible'">
    <div #sliderContent class="slider-content f_slider-content">

    </div>
  </div>
</div>
