/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DiagnosticFormattedQuestion,
  DiagnosticQuestionOptionRequest,
  DiagnosticQuestionRequest,
  ResourceTag,
  MediumEditorData,
  EditorContentUpdateEvent,
  DiagnosticQuestionFeedbackRequest,
  EditorContent
} from '../../../../../shared/models';
import { ActivatedRoute } from '@angular/router';
import { DynamicContentRequest } from '../../../../../shared/models/page/page.model';
import { DialogService } from '../../../../../shared/helpers/dialog/dialog.service';
import { TranslationService } from '../../../../../shared/services/translation/translation.service';
import { SupportedLanguage } from '../../../../../shared/models/languages/languages.model';

@Component({
  selector: 'ptl-edit-quiz-question',
  templateUrl: './edit-quiz-question.component.html',
  styleUrls: ['./edit-quiz-question.component.scss'],
})
export class EditQuizQuestionComponent implements OnInit, OnChanges {

  /** Receives the question to edit */
  @Input() editableQuestion: DiagnosticFormattedQuestion | undefined;

  @Input() isQuiz = false;

  /** Checking is editing form */
  @Input() isEditingQuestion = false;
  /** Receives available question types */
  @Input() questionTypes: string[] = [];

  @Input() languageCode: string | undefined;

  @Input() currentLanguage: SupportedLanguage;

  /** Emits content data on saveQuestion() */
  @Output() addedQuestion = new EventEmitter<DiagnosticQuestionRequest>();
  /** Emits content data on update questions */
  @Output() updatedQuestion = new EventEmitter<DiagnosticQuestionRequest>();
  /** Emits closure call */
  @Output() closeForm = new EventEmitter<void>();

  @Output() feedbackCleared = new EventEmitter<DiagnosticQuestionRequest>();

  content: MediumEditorData = {type: 'PARAGRAPH', content: ''};

  feedback: DiagnosticQuestionFeedbackRequest = {
    dynamicContentRequest: [{content: '', type: 'PARAGRAPH', uid: ''}],
  };

  ptlFocused = false;
  ptlFeedbackFocused = false;
  resourceUri = '';
  questionForm: FormGroup;
  questionFormOptions: FormArray;
  questionTags: ResourceTag[] = [];
  frameworkTagsPanelOpen = false;
  newQuestion = false;
  questionEmpty = false;

  prettyStatus = {
    INFO_QUESTION: {
      name: 'Info',
      image: '../../../../../../../assets/icons/info-circle.svg'
    },
    SLIDER_QUESTION: {
      name: 'Slider',
      image: '../../../../../../../assets/icons/line.svg'
    },
    ORDERING_QUESTION: {
      name: 'Ranking',
      image: '../../../../../../../assets/icons/hand.svg'
    },
    DRAGDROP_QUESTION: {
      name: 'Drag & Drop',
      image:  '../../../../../../../assets/icons/new/drag-drop.svg'
    },
    GRID_QUESTION: {
      name: 'Grid',
      image: '../../../../../../../assets/icons/grid.svg'
    },
    CHOICE_QUESTION: {
      name: 'Rating/Likert',
      image: '../../../../../../../assets/icons/circles.svg'
    },
    MULTIPLE_CHOICE_QUESTION: {
      name: 'Multiple Choice',
      image: '../../../../../../../assets/icons/new/list-bullet.svg'
    },
    RATING_QUESTION: {
      name: 'Rating/Likert',
      image: '../../../../../../../assets/icons/circles.svg'
    },
  };

  activeTypes: { [k: string]: boolean } = {
    INFO_QUESTION: false,
    SLIDER_QUESTION: false,
    ORDERING_QUESTION: false,
    DRAGDROP_QUESTION: false,
    GRID_QUESTION: false,
    CHOICE_QUESTION: false,
    MULTIPLE_CHOICE_QUESTION: false,
    RATING_QUESTION: false,
  };

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private translationService: TranslationService,
    private activeRoute: ActivatedRoute
  ) {
    const dynamicContentRequest = [{content: '', type: 'PARAGRAPH', uid: ''}];

    this.questionFormOptions = this.fb.array([]);

    this.questionForm = this.fb.group({
      type: ['CHOICE_QUESTION', [Validators.required]],
      required: [false, Validators.required],
      value: this.fb.group({
        languageCode: this.languageCode,
        value: '',
      }),
      instruction: this.fb.group({
        languageCode: this.languageCode,
        value: ['', Validators.required],
      }),
      options: this.questionFormOptions,
      feedback: this.isQuiz ? this.fb.group(dynamicContentRequest) : [],
      tags: [],
      selectionLimit: [1, Validators.required],
      optionsPerRow: [1, Validators.required],
      uid: '',
    });
  }

  ngOnInit() {
    const resourceUriParam = this.activeRoute.snapshot.paramMap.get('resourceUri');

    if (resourceUriParam && this.isQuiz) {
      this.resourceUri = resourceUriParam;

      this.questionForm.patchValue({
        type: 'RATING_QUESTION',
      });
    }

    this.populateForm();

    if (this.languageCode) {
      this.setLanguageCodeToForm();
    }

    if (this.questionForm) {
      this.checkInstructions(this.questionForm);
    }
  }

  ngOnChanges() {
    this.populateForm();

    if (this.questionTypes) {
      this.selectAvailableQuestionTypes(this.questionTypes);
    }

    this.newQuestion = !this.questionForm.value.uid;

    if (this.questionForm) {
      this.checkInstructions(this.questionForm);
    }
  }

  onInfoQuestionValueChange(questionText: string) {
    this.questionForm.patchValue({
      value: {
        languageCode: this.languageCode,
        value: questionText,
      },
    });
  }

  saveQuestion() {
    this.questionEmpty = false;
    this.ptlFocused = false;
    if (this.questionForm.valid) {

      if (this.questionForm.value.type === 'MULTIPLE_CHOICE_QUESTION') {
        this.questionForm.value.type = 'GRID_QUESTION';
      } else if (this.questionForm.value.type === 'RATING_QUESTION') {
        this.questionForm.value.type = 'CHOICE_QUESTION';
      }
      if (this.questionForm.value.uid) {
        this.questionForm.value.options = this.removeOptionUid(this.questionForm.value.options);
        this.updatedQuestion.emit({...this.questionForm.value, tags: this.questionTags});
      } else {
        this.addedQuestion.emit({...this.questionForm.value, tags: this.questionTags});
      }
    } else {
      this.markFormGroupTouched(this.questionForm);
    }
  }

  onCloseForm() {
    this.closeForm.emit();
  }

  clearFeedback() {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.clearFeedback'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.questionForm.patchValue({feedback: []});
        this.feedbackCleared.emit({...this.questionForm.value, tags: this.questionTags});
        this.feedback.dynamicContentRequest = [{content: '', type: 'PARAGRAPH', uid: ''}];
      }
    });
  }

  private setLanguageCodeToForm() {
    this.questionForm.get('instruction')?.patchValue({
      languageCode: this.languageCode,
    });
    this.questionForm.get('value')?.patchValue({
      languageCode: this.languageCode,
    });
  }

  private populateForm() {
    if (!this.editableQuestion) {
      return;
    }
    this.prepareOptions();
    const updatedQuestions = {
      ...this.editableQuestion,
      // if on resource, replace GRID_QUESTION with MULTIPLE_CHOICE_QUESTION (frontend usage only)
      type: this.resourceUri && this.editableQuestion.type === 'GRID_QUESTION'
        ? 'MULTIPLE_CHOICE_QUESTION' : this.resourceUri && this.editableQuestion.type === 'CHOICE_QUESTION'
          ? 'RATING_QUESTION' : this.editableQuestion.type,
    };
    this.questionForm.patchValue({
      ...updatedQuestions,
      feedback: this.isQuiz ? updatedQuestions.feedback : [],
      options: this.editableQuestion.choices,
    });
    if (this.editableQuestion.tags) {
      this.questionTags = [...this.editableQuestion.tags];
    }
    this.content = {
      type: 'PARAGRAPH',
      content: this.questionForm.value.instruction.value,
    };
    if (this.isQuiz && this.questionForm.value.feedback && this.questionForm.value.feedback.length) {
      this.feedback = {dynamicContentRequest: this.questionForm.value.feedback};
    }
  }

  onToggleFrameworkTagsPanel(value: boolean) {
    this.frameworkTagsPanelOpen = value;
  }

  onAddTagBulk(tags: ResourceTag[]) {
    tags.map(tag => this.questionTags.push(tag));
  }

  deleteTag(tagIndex: number) {
    this.questionTags = this.questionTags.filter((_, i) => i !== tagIndex);
  }

  private removeOptionUid(options: DiagnosticQuestionOptionRequest[]) {
    const optArr = [];
    for (const option of options) {
      delete option.uid;
      optArr.push(option);
    }
    return optArr;
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    if ((this.content && !this.content.content) && !this.questionForm.get('instruction')?.value?.value) {
      this.questionEmpty = true;
    }
    (Object.values(formGroup.controls) as FormGroup[]).forEach((control: FormGroup) => {
      if (control.controls) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  private checkInstructions(question: FormGroup) {
    const options = question.value.options;
    const optionsArray = this.questionForm.get('options') as FormArray;
    for (const [index, option] of options.entries()) {
      if (!option.instructions || option.instructions.length === 0) {
        const optionControl = optionsArray.at(index) as FormGroup;
        optionControl.patchValue({ instructions: [{type: 'PARAGRAPH', content: ''}] });
      }
    }
  }

  private prepareOptions() {
    if (!this.editableQuestion || !this.editableQuestion.options) {
      return;
    }
    // clearing the FormArray and adding blank FormGroups based on the options.length - needed for successful patchValue
    while ((this.questionForm.controls['options'] as FormArray).length !== 0) {
      (this.questionForm.controls['options'] as FormArray).removeAt(0);
    }
    this.editableQuestion.options
      .forEach(() => {
        if (this.editableQuestion) {
          this.questionFormOptions.push(this.addBlankOption(this.editableQuestion));
        }
      });
  }

  private addBlankOption(question: DiagnosticFormattedQuestion) {
    return this.fb.group({
      optionUid: '',
      type: 'CHOICE_OPTION',
      correct: undefined,
      instructions: [{ type: 'PARAGRAPH', content: '' }],
      deselectOthers: undefined,
      direction: undefined,
      score: [undefined, Validators.required],
      value: this.fb.group({
        languageCode: this.languageCode,
        value: question.type === 'CHOICE_QUESTION' ? ['', [Validators.required, Validators.maxLength(33)]] :
          ['', Validators.required],
      }),
    });
  }

  onMediumEditorUpdate(data: EditorContentUpdateEvent) {
    this.questionEmpty = !data.update.newContent.content;
    this.questionForm.get('instruction')?.patchValue({
      value: data.update.newContent.content,
    });
  }

  onFeedbackUpdate({index, update}: EditorContentUpdateEvent, level: DiagnosticQuestionFeedbackRequest) {
    level.dynamicContentRequest = level.dynamicContentRequest.map((editorContent: EditorContent, i: number) => {
      if (i === index) {
        return {...update.newContent, uid: ''};
      }
      return {...editorContent, uid: ''};
    }) as DynamicContentRequest[];
    this.questionForm.get('feedback')?.patchValue(level.dynamicContentRequest);
  }

  selectAvailableQuestionTypes(questionTypes: string[]) {
    questionTypes.forEach(type => {
      const key = type in this.activeTypes;
      if (key) {
        this.activeTypes[type] = true;
      }
    });
  }
}
