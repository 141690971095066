<!--
  ~ Copyright (C) 2024 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="date-filter-container">
  <mat-card class="date-filter-settings">
    <div class="date-filter-settings__header">
      <span>{{ 'translations.global.label.dateRangePickerHeader' | transloco }}</span>
    </div>

    <div class="date-filter-settings__content">
      <mat-calendar
        [startAt]="startDate"
        [selected]="dateRange"
        [startView]="startView"
        [minDate]="minDate"
        [maxDate]="maxDate"
        (selectedChange)="onDatesSelected($event)"
      ></mat-calendar>
      <div class="date-filter-settings__footer">
        <button
          mat-stroked-button
          (click)="applyFilter()"
          [attr.aria-label]="('translations.global.button.apply' | transloco)"
        >
          {{ 'translations.global.button.apply' | transloco }}
        </button>
      </div>
    </div>
  </mat-card>
</div>
