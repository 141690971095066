/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';

export enum CalendarStartView {
  MONTH = 'month',
  YEAR = 'year',
  MULTI_YEAR = 'multi-year',
}

@Component({
  selector: 'ptl-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() startView: CalendarStartView = CalendarStartView.MONTH;
  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;
  @Output() filterSaved = new EventEmitter<{ startDate: Date; endDate: Date }>();

  startDate: Date;
  endDate: Date;
  dateRange: DateRange<Date>;

  ngOnInit(): void {
    const selectedDate = this.maxDate;
    this.startDate = selectedDate;
    this.endDate = selectedDate;
    this.dateRange = new DateRange(this.startDate, this.endDate);
  }

  onDatesSelected(date: Date): void {
    const isSameDateSelected =
      this.startDate?.getTime() === date.getTime() && this.endDate?.getTime() === date.getTime();

    if (!this.startDate && !this.endDate) {
      this.startDate = date;
      this.endDate = date;
      this.dateRange = new DateRange(this.startDate, this.endDate);
      return;
    }

    if (isSameDateSelected) {
      this.startDate = null;
      this.endDate = null;
      this.dateRange = null;
      return;
    }

    if (this.startDate && !this.endDate) {
      if (date < this.startDate) {
        this.endDate = this.startDate;
        this.startDate = date;
      } else {
        this.endDate = date;
      }
      this.dateRange = new DateRange(this.startDate, this.endDate);
      return;
    }

    this.startDate = date;
    this.endDate = null;
    this.dateRange = new DateRange(this.startDate, null);
  }

  clearSelection(): void {
    this.startDate = null;
    this.endDate = null;
    this.dateRange = null;
  }

  applyFilter(): void {
    if (!this.endDate) {
      const endOfDay = new Date(this.startDate);
      endOfDay.setHours(23, 59, 59, 999);
      this.endDate = endOfDay;
    }
    this.filterSaved.emit({ startDate: this.startDate, endDate: this.endDate });
  }
}
