<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div  class="quiz-instruction-box">
  <div class="title small bold">{{ 'translations.quiz.instructions.title' | transloco }}</div>
  <div class="description extra-small">{{ 'translations.quiz.instructions.title' | transloco }}</div>
  <ptl-editor
    class="quiz-instruction-textarea"
    [link]="false"
    [upload]="false"
    [separator]="false"
    [placeholder]=""
    [content]="[content]"
    (contentUpdate)="onMediumEditorUpdate($event)">
  </ptl-editor>
</div>
