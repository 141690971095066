/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MediaContent } from '../../../../../shared/models';
import { MatDialog } from '@angular/material/dialog';
import { IMAGE_UPLOAD_DIALOG_CONFIG } from '@app/app/shared/models/config/dialog-config.model';
import {
  CoverImageUploadDialogComponent
} from '@app/app/shared/components/cover-image-upload-dialog/cover-image-upload-dialog.component';
import { LanguageCodeHelper } from '@app/app/shared/helpers/language-code-helper';
import { ImageUploadNameResolver } from '@app/app/shared/helpers/image-upload-name-resolver';
import { Router } from '@angular/router';
// eslint-disable-next-line max-len
const DEFAULT_URL_VALIDATION_PATTERN = '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})';

@Component({
  selector: 'ptl-media-content-view',
  templateUrl: './media-content.component.html',
  styleUrls: ['./media-content.component.scss']
})
export class MediaContentViewComponent implements OnInit, OnChanges {

  /** Receives the position of the HTML element. LEFT | RIGHT | CENTER | FULL_WIDTH | THUMBNAIL. */
  @Input() position = 'CENTER';
  @Input() caption?: string;
  @Input() url?: string;
  @Input() openUrlInTab?: boolean;
  @Input() disableToolbar = false;
  @Input() disableMediaPosition = false;
  @Input() isExternalContent = false;
  @Input() isUploadedDocument = false;
  @Input() isUploadedImage = false;
  @Input() isUploadedVideo = false;
  @Input() uploadedVideoType: 'VIDEO' | 'STREAM_VIDEO';
  @Input() isUploadedAudio = false;
  @Input() canRemoveItem = true;

  /** Outputs removing of the component */
  @Output() removeMediaContent = new EventEmitter<void>();
  /** Outputs the new position of the HTML element. */
  @Output() updateMediaPosition = new EventEmitter<string>();
  /** Outputs the media caption element. */
  @Output() updateMediaCaption = new EventEmitter<string>();
  /** Outputs the media hyperlink element. */
  @Output() updateMediaHyperlink = new EventEmitter<{ url: string; openUrlInTab: boolean }>();
  /** Outputs the replacing media element. */
  @Output() mediaContentReplaced = new EventEmitter<MediaContent>();
  @Output() regularLinkReplaced = new EventEmitter<string>();
  /** Outputs the toolbar display state. */
  @Output() toolBarState = new EventEmitter<boolean>();

  @ViewChild('media', {static: false}) public media: ElementRef | undefined;

  showToolbar = false;
  showAddCaption = false;
  showAddLink = false;
  showUpload = false;
  showReplaceLink = false;
  replacedFileType: 'IMAGE' | 'FILE' | 'VIDEO' | 'STREAM_VIDEO' | 'AUDIO';
  toolbarPosition = {
    top: 0,
    left: 0
  };

  captionControl = new FormControl('');
  hyperlinkForm: FormGroup;
  isNewEditorEnabled: boolean;
  isInResourceModule: boolean;
  private dialog: MatDialog = inject(MatDialog);

  constructor(
    private renderer: Renderer2,
    private fb: FormBuilder,
    private router: Router
  ) {

    this.hyperlinkForm = this.fb.group({
      url: ['', [Validators.pattern(DEFAULT_URL_VALIDATION_PATTERN)]],
      openUrlInTab: [true]
    });

  }

  ngOnInit(): void {
    this.captionControl.setValue(this.caption);
    this.hyperlinkForm.setValue({
      url: this.url ? this.url : '',
      openUrlInTab: this.openUrlInTab ? this.openUrlInTab : false
    });

    this.isNewEditorEnabled = JSON.parse(localStorage.getItem('enableNewEditor'));
    const currentUrl = this.router.url;
    this.isInResourceModule = currentUrl.includes('/cards/');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.position &&
      (changes.position.currentValue === 'LEFT' ||
        changes.position.currentValue === 'RIGHT' ||
        changes.position.currentValue === 'THUMBNAIL')) {
      setTimeout(() => this.addSpaces());
    } else {
      setTimeout(() => this.removeSpaces());
    }
  }

  addSpaces() {
    const contentElements = document.querySelectorAll('.f_editor-element .f_content-media-html, ' +
      '.f_editor-element .medium-editor-element, .f_editor-element .mce-content-body');

    let lastFloatedElement: HTMLElement | undefined;
    let lastTextEditorElement: HTMLElement | undefined;

    for (let i = 0; i < contentElements.length; ++i) {
      if (contentElements[i].classList.contains('medium-editor-element')) {
        lastTextEditorElement = contentElements[i] as HTMLElement;
      }

      if (i === contentElements.length - 1 || contentElements[i].classList.contains('f_content-media-html')) {
        if (lastFloatedElement && lastTextEditorElement) {
          // check if same editor
          if (lastFloatedElement?.closest('.f_editor') === lastTextEditorElement?.closest('.f_editor')) {
            while (lastTextEditorElement.offsetTop + lastTextEditorElement.offsetHeight <
            lastFloatedElement.offsetTop + lastFloatedElement.offsetHeight) {
              lastTextEditorElement.innerHTML += '<p class="floating-space"><br></p>';
            }

            lastFloatedElement = undefined;
          }
        }

        if (contentElements[i].classList.contains('content-media__html--position-LEFT') ||
          contentElements[i].classList.contains('content-media__html--position-RIGHT') ||
          contentElements[i].classList.contains('content-media__html--position-THUMBNAIL') ||
          contentElements[i].classList.contains('content-media-html--position-LEFT') ||
          contentElements[i].classList.contains('content-media-html--position-RIGHT') ||
          contentElements[i].classList.contains('content-media-html--position-THUMBNAIL')
        ) {
          lastFloatedElement = contentElements[i] as HTMLElement;
          lastTextEditorElement = undefined;
        }
      }
    }
  }

  removeSpaces() {
    const contentElements = document.querySelectorAll('.f_editor-element .f_content-media-html, ' +
      '.f_editor-element .medium-editor-element, .f_editor-element .mce-content-body');

    let isLastElementNonFloating = true;

    contentElements.forEach(contentElement => {
      const isCurrentElementTextEditor = contentElement.classList.contains('medium-editor-element');

      if (!isCurrentElementTextEditor) {
        isLastElementNonFloating =
          (!contentElement.classList.contains('content-media__html--position-LEFT') &&
            !contentElement.classList.contains('content-media__html--position-RIGHT') &&
            !contentElement.classList.contains('content-media__html--position-THUMBNAIL')) ||
          (!contentElement.classList.contains('content-media-html--position-LEFT') &&
            !contentElement.classList.contains('content-media-html--position-RIGHT') &&
            !contentElement.classList.contains('content-media-html--position-THUMBNAIL'));
      }

      if (isCurrentElementTextEditor && isLastElementNonFloating) {
        contentElement.querySelectorAll('.floating-space').forEach((item) => {
          if (!item.children[0] || item.children[0] && item.children[0].tagName !== 'BR') {
            item.classList.remove('floating-space');
          } else {
            item.remove();
          }
        });
      }
    });
  }

  removeMedia(): void {
    this.removeMediaContent.emit();
  }

  showMediaToolbar() {
    if (!this.media || this.disableToolbar || this.showAddCaption || this.showAddLink) {
      return;
    }
    this.showToolbar = true;
    this.toolBarState.emit(true);
    this.media.nativeElement.focus();

    this.toolbarPosition = {
      top: this.media.nativeElement.offsetTop - 85,
      left: 0
    };
  }

  hideMediaToolbar(event?: FocusEvent, toolbarEl?: HTMLElement) {
    if (event && toolbarEl && toolbarEl.contains(event.relatedTarget as HTMLElement)) {
      return;
    }

    this.showToolbar = false;
    this.toolBarState.emit(false);
  }

  addCaption(): void {
    if (!this.media) {
      return;
    }

    this.showToolbar = false;
    this.toolBarState.emit(false);
    this.toolbarPosition = {
      top: this.media.nativeElement.offsetTop - 120,
      left: this.media.nativeElement.offsetLeft + (this.media.nativeElement.offsetWidth / 2) -
        (this.disableMediaPosition ? 24 : 65)
    };
    this.showAddCaption = true;
    setTimeout(() => {
      const inputElement = this.renderer.selectRootElement('#contentInput');
      inputElement.focus();
    }, 0);
  }

  replaceMedia(): void {
    if (!this.isExternalContent) {
      this.replacedFileType = this.getReplacedFileType();
      if (this.replacedFileType === 'IMAGE') {
        this.handleImageUpload();
      } else {
        this.showUpload = true;
        (document.querySelector('.f_file-upload') as HTMLInputElement)?.click();
      }
    } else {
      this.showReplaceLink = true;
    }
  }

  onMediaContentAdded(mediaContentData: MediaContent): void {
    this.mediaContentReplaced.emit(mediaContentData);
    this.showUpload = false;
    this.showReplaceLink = false;
  }

  onRegularLinkAdded(link: string): void {
    this.regularLinkReplaced.emit(link);
    this.showReplaceLink = false;
  }

  addLink(): void {
    if (!this.media) {
      return;
    }

    this.showToolbar = false;
    this.toolBarState.emit(false);
    this.toolbarPosition = {
      top: this.media.nativeElement.offsetTop - 120,
      left: this.media.nativeElement.offsetLeft + (this.media.nativeElement.offsetWidth / 2) -
        (this.disableMediaPosition ? 24 : 70)
    };
    this.showAddLink = true;
    setTimeout(() => {
      const inputElement = this.renderer.selectRootElement('#linkInput');
      inputElement.focus();
    }, 0);
  }

  close(): void {
    this.showAddCaption = false;
    this.showAddLink = false;
    this.captionControl.reset(this.caption);
    this.hyperlinkForm.reset({url: this.url, openUrlInTab: this.openUrlInTab});
  }

  setMediaPosition(position: string): void {
    this.updateMediaPosition.emit(position);
  }

  setCaption(): void {
    this.showAddCaption = false;
    this.updateMediaCaption.emit(this.captionControl.value);
  }

  setHyperLink(): void {
    if (this.hyperlinkForm.valid) {
      this.showAddLink = false;
      this.updateMediaHyperlink.emit(this.hyperlinkForm.value);
    }
  }

  private getReplacedFileType() {
    if (this.isUploadedImage) {
      return 'IMAGE';
    }
    if (this.isUploadedVideo) {
      return this.uploadedVideoType;
    }
    if (this.isUploadedAudio) {
      return 'AUDIO';
    }
    return 'FILE';
  }

  private handleImageUpload(): void {
    const dialogRef = this.dialog.open(CoverImageUploadDialogComponent, {
      ...IMAGE_UPLOAD_DIALOG_CONFIG,
      direction: LanguageCodeHelper.getBodyLanguageDir(),
      data: {
        advancedImageCrop: true
      }
    });
    const dialogSubscription = dialogRef.afterClosed().subscribe(dialogResponse => {
      if (dialogResponse) {
        const file = dialogResponse['croppedImageFile']
        const formattedName = ImageUploadNameResolver.resolveImageName(file.name);
        const uploadData = {
          type: 'MEDIA_UPLOAD',
          file,
          mediaType: 'IMAGE',
          replace: true,
          realName: file.name,
          contentLength: file.size,
          name: formattedName
        };
        this.onMediaContentAdded(uploadData as MediaContent)
      }

      dialogSubscription.unsubscribe();
    });
  }
}
