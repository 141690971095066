<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<ptl-questions-quiz
  [isQuiz]="true"
  [languageCode]="languageCode"
  [formattedQuestions]="formattedQuestions"
  [currentLanguage]="currentLanguage"
  [questionTypes]="questionTypes"
  (addedQuestion)="addQuestion($event)"
  (updatedQuestion)="updateQuestion($event)"
  (copyQuestion)="copyQuestion($event)"
  (reorderedQuestions)="reorderQuestions($event)"
  (feedbackRemoved)="onFeedbackRemoved($event)"
  (deletedQuestion)="deleteQuestion($event)">
</ptl-questions-quiz>
