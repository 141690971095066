<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="embeded-code-block">

  <div class="embeded-code-toolbar f_embed-toolbar" *ngIf="showToolbar">
    <div class="embeded-code-toolbar-inner">
      <ul class="embeded-code-toolbar-items">
        <li
          class="embeded-code-toolbar-item"
          [class.embeded-code-toolbar-item-active]="position === 'LEFT'"
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.alignLeft' | transloco)"
          (click)="setMediaPosition('LEFT')"
          (keydown.enter)="setMediaPosition('LEFT')">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#align-left"></use>
          </svg>
          <span class="label small">{{'translations.editor.toolbarNew.floating.option.alignLeft' | transloco}}</span>
        </li>
        <li
          class="embeded-code-toolbar-item"
          [class.embeded-code-toolbar-item-active]="position === 'RIGHT'"
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.alignRight' | transloco)"
          (click)="setMediaPosition('RIGHT')"
          (keydown.enter)="setMediaPosition('RIGHT')">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#align-right"></use>
          </svg>
          <span class="label small">{{'translations.editor.toolbarNew.floating.option.alignRight' | transloco}}</span>
        </li>
        <li
          class="embeded-code-toolbar-item"
          [class.embeded-code-toolbar-item-active]="position === 'CENTER'"
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.alignCenter' | transloco)"
          (click)="setMediaPosition('CENTER')"
          (keydown.enter)="setMediaPosition('CENTER')">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#align-center"></use>
          </svg>
          <span
            class="label small">{{'translations.editor.toolbarNew.floating.option.alignCenter' | transloco}}</span>
        </li>
        <li
          class="embeded-code-toolbar-item"
          [class.embeded-code-toolbar-item-active]="position === 'FULL_WIDTH'"
          tabindex="0"
          [attr.aria-label]="('translations.global.ariaLabels.fullWidthAlign' | transloco)"
          (click)="setMediaPosition('FULL_WIDTH')"
          (keydown.enter)="setMediaPosition('FULL_WIDTH')">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#align-full-width"></use>
          </svg>
          <span
            class="label small">{{'translations.editor.toolbarNew.floating.option.alignFullWidth' | transloco}}</span>
        </li>
        <li
          class="embeded-code-toolbar-item"
          [attr.aria-label]="('translations.global.ariaLabels.edit' | transloco)"
          tabindex="0"
          (click)="showEmbedCode($event)"
          (keydown.enter)="showEmbedCode($event)">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
          <span class="label small">{{'translations.editor.toolbarNew.floating.option.edit' | transloco}}</span>
        </li>
        <li
          class="embeded-code-toolbar-item"
          [attr.aria-label]="('translations.global.ariaLabels.removeItem' | transloco)"
          tabindex="0"
          (click)="removeMedia()"
          (keydown.enter)="removeMedia()">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
          </svg>
          <span class="label small">{{'translations.editor.toolbarNew.floating.option.delete' | transloco}}</span>
        </li>
      </ul>
    </div>
  </div>

  <div tabindex="0" (click)="showToolbar = true" (keydown.enter)="showToolbar = true"
        [class.is_hidden]="showEmbedPopup"
        class="embeded-code-preview-box f_embed-preview embeded-code-preview-box-position-{{ position }} f_embeded-code-preview-box-position-{{ position }}">
    <div class="embeded-code-preview-box-inner">
      <button mat-icon-button (click)="showEmbedCode($event)" [attr.aria-label]="('translations.global.ariaLabels.icons.edit' | transloco)">
        <svg class="svg" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
        </svg>
      </button>
      <div class="embeded-code-preview-box-disabled small" #htmlCode></div>
    </div>
  </div>

  <div class="embeded-code-form-box" *ngIf="showEmbedPopup">
    <textarea class="embeded-code-text-area small" placeholder="{{ 'translations.embedCodePlaceholder' | transloco }}"
              [(ngModel)]="contentCode"></textarea>
    <div class="buttons-box">
      <button mat-stroked-button color="accent" (click)="hideEmbedCode()">
        {{ 'translations.global.button.cancel' | transloco }}
      </button>
      <button class="save-button" mat-flat-button color="potentially" (click)="triggerUpdate()">
        {{ 'translations.global.button.save' | transloco }}
      </button>
    </div>
  </div>
</div>

