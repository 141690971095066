<!--
  ~ Copyright (C) 2020 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="uploads-placeholder__container" [class.uploads-placeholder__container--height-max]="mediaType === 'IMAGE'">
  <p *ngIf="dataLoaded && description" class="uploads-placeholder__description">{{ description }}</p>
  <ptl-progress-bar-upload
    [content]="content"
    (contentUploadProgressEmitter)="contentUploadProgress($event)">
  </ptl-progress-bar-upload>
</div>
