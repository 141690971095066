/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  DiagnosticQuestionRequest,
  EditorContent,
  EditorContentRemovalEvent,
  EditorContentUpdateEvent
} from '@app/app/shared/models';

@Component({
  selector: 'ptl-quiz-slider-question-type',
  templateUrl: './quiz-slider-question-type.component.html',
  styleUrls: ['../add-question-section.component.scss', './quiz-slider-question-type.component.scss'],
})
export class QuizSliderQuestionTypeComponent implements OnChanges {

  /** Receives the question to edit */
  @Input() question: FormGroup;

  @Input() isQuiz: boolean;

  @Input() languageCode: string | undefined;

  @Input() isDefaultLanguage: boolean;

  /** Emits content data on form update. */
  @Output() formUpdate = new EventEmitter<DiagnosticQuestionRequest>();

  constructor(private fb: FormBuilder) {
  }

  ngOnChanges(): void {
    if (this.question) {
      this.populateForm();
    }
  }

  get showScoreControl(): FormControl {
    return this.question.get('showScore') as FormControl;
  }

  roundInputValue(inputElement: HTMLInputElement): void {
    const value = parseFloat(inputElement.value);
    if (!isNaN(value)) {
      const roundedValue = Math.ceil(value).toString();
      inputElement.value = roundedValue;
      this.question.get('slideStepBy')?.setValue(roundedValue, { emitEvent: false });
    }
  }

  populateForm() {
    // if no uid, clear current arrays and add blank ones
    if (!this.question.value.uid || !this.question.value.options.length) {
      while ((this.question.controls['options'] as FormArray).length !== 0) {
        (this.question.controls['options'] as FormArray).removeAt(0);
      }
      (this.question.controls['options'] as FormArray).push(this.addNewSliderOption('LEFT'));
      (this.question.controls['options'] as FormArray).push(this.addNewSliderOption('RIGHT'));
    }
  }

  addNewSliderOption(direction: string) {
    const dynamicContentRequest = this.fb.array([
      this.fb.group({
        content: '',
        type: 'PARAGRAPH',
        uid: ''
      })
    ]);

    const controls = {
      type: 'SLIDER_OPTION',
      direction: direction,
      instructions: dynamicContentRequest,
      score: [undefined, Validators.required],
      value: this.fb.group({
        languageCode: this.languageCode,
        value: ['', Validators.required],
      }),
    };
    if (this.isQuiz) {
      controls['correct'] = undefined;
    }
    return this.fb.group(controls);
  }

  onContentUpdate({ index, update }: EditorContentUpdateEvent, level: EditorContent[], questionIndex: number): void {
    const content = level.map((editorContent, i) => i === index ? update.newContent : editorContent);
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;
    optionControl.patchValue({ instructions: content });
  }

  onContentRemoval({ index }: EditorContentRemovalEvent, level: EditorContent[], questionIndex: number): void {
    const optionsArray = this.question.get('options') as FormArray;
    const optionControl = optionsArray.at(questionIndex) as FormGroup;
    optionControl.patchValue({ instructions: [{ type: 'PARAGRAPH', content: '' }] });
  }

  onCorrectAnswerChange(direction: string): void {
    if (direction === 'left') {
      this.question.controls['options']['controls'][0]['value']['correct'] = true;
      this.question.controls['options']['controls'][1]['value']['correct'] = false;
    } else {
      this.question.controls['options']['controls'][0]['value']['correct'] = false;
      this.question.controls['options']['controls'][1]['value']['correct'] = true;
    }
  }
}
