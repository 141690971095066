import { Component, Input, OnInit } from '@angular/core';
import {
	ToolbarButtonFormType,
	ToolbarButtonType,
	ToolbarButtonUploadType
} from '@app/app/editor/components/editor-toolbar/editor-toolbar-option/editor-toolbar-option.component';
import TooltipOptions from '@app/app/shared/editor-js/components/toolbar-tooltip/options/TooltipOptions';
import { TooltipOption } from '@app/app/shared/editor-js/models/tooltip-option.model';

@Component({
	selector: 'ptl-toolbar-tooltip',
	templateUrl: './toolbar-tooltip.component.html',
	styleUrls: ['./toolbar-tooltip.component.scss']
})
export class ToolbarTooltipComponent implements OnInit {

	@Input() type: string;
	@Input() position: number;
	@Input() leftPosition: number;

	selectedOptionButtonType: ToolbarButtonType;
	selectedOptionSubType: ToolbarButtonUploadType | ToolbarButtonFormType;
	tooltipData: TooltipOption;

	ngOnInit() {
		TooltipOptions.forEach(el => {
			if ( el.subType === this.type ) {
				this.tooltipData = el;
			}
		});
	}
}
