/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {Component, ViewChild, Input, EventEmitter, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  CustomDiagnosticFormattedQuestion,
  DiagnosticFormattedQuestion, DiagnosticQuestionCopy,
  DiagnosticQuestionRequest
} from '../../../../shared/models';
import {DialogService} from '../../../../shared/helpers/dialog/dialog.service';
import {TranslationService} from '../../../../shared/services/translation/translation.service';
import {SupportedLanguage} from '../../../../shared/models/languages/languages.model';
import {
  QuestionsQuizListComponent
} from '@app/app/editor/components/shared/questions/questions-quiz-list/questions-quiz-list.component';

@Component({
  selector: 'ptl-questions-quiz',
  templateUrl: './questions-quiz.component.html',
  styleUrls: ['./questions-quiz.component.scss'],
})
export class QuestionsQuizComponent {

  @ViewChild('tableList') tableList: QuestionsQuizListComponent | undefined;

  @Input() formattedQuestions: DiagnosticFormattedQuestion[] | undefined;

  @Input() isQuiz = false;

  @Input() languageCode: string | undefined;

  @Input() currentLanguage: SupportedLanguage;

  @Input() questionTypes: string[] | undefined;

  @Output() addedQuestion = new EventEmitter<DiagnosticQuestionRequest>();

  @Output() updatedQuestion = new EventEmitter<DiagnosticQuestionRequest>();

  @Output() feedbackRemoved = new EventEmitter<DiagnosticQuestionRequest>();

  @Output() reorderedQuestions = new EventEmitter<DiagnosticFormattedQuestion[]>();

  @Output() deletedQuestion = new EventEmitter<DiagnosticQuestionRequest>();

  @Output() copyQuestion = new EventEmitter<DiagnosticQuestionCopy>();


  isEditingQuestion = false;
  questionFormVisible = false;
  editableQuestion: DiagnosticFormattedQuestion | undefined;

  constructor(private dialogService: DialogService, private translationService: TranslationService) {
  }

  toggleQuestionForm() {
    this.questionFormVisible = !this.questionFormVisible;
    this.editableQuestion = undefined;
    this.toggleEditFormPosition(false);
  }

  addQuestion(question: DiagnosticQuestionRequest) {
    this.questionFormVisible = false;
    this.editableQuestion = undefined;
    this.toggleEditFormPosition(false);
    this.addedQuestion.emit(question);
  }

  updateQuestion(question: DiagnosticQuestionRequest) {
    this.questionFormVisible = false;
    this.editableQuestion = undefined;
    this.updatedQuestion.emit(question);
    this.toggleEditFormPosition(false);
  }

  onFeedBackClear(question: DiagnosticQuestionRequest) {
    this.feedbackRemoved.emit(question);
  }

  onItemsMove(moveEvent: CdkDragDrop<DiagnosticFormattedQuestion[]> | CustomDiagnosticFormattedQuestion) {
    if (this.tableList && this.tableList.tableData) {
      const data = (moveEvent as CdkDragDrop<DiagnosticFormattedQuestion[]>)?.item ?
        (moveEvent as CdkDragDrop<DiagnosticFormattedQuestion[]>).item.data :
        (moveEvent as CustomDiagnosticFormattedQuestion).data;

      const prevIndex = this.tableList.tableData.findIndex((d) => d === data);
      moveItemInArray(this.tableList.tableData, prevIndex, moveEvent.currentIndex);
      this.tableList.renderRows();
      this.reorderedQuestions.emit(this.tableList.tableData);
    }
  }

  onItemRemove(question: DiagnosticQuestionRequest) {
    this.dialogService.showConfirmDialog(
      this.translationService.getTranslation('dialog.title.removeQuestion'),
      this.translationService
    ).then(confirmed => {
      if (confirmed) {
        this.deletedQuestion.emit(question);
      }
    });
  }

  onIndexEdit(idx: number) {
    if (this.tableList && this.tableList.tableData) {
      this.questionFormVisible = false;
      this.questionFormVisible = true;
      this.editableQuestion = {...this.tableList.tableData[idx]};
      this.toggleEditFormPosition(true);
    }
  }

  onQuestionCopy(data: DiagnosticQuestionCopy) {
    this.copyQuestion.emit(data);
  }

  private toggleEditFormPosition(isFloating: boolean): void {
    if (isFloating) {
      this.isEditingQuestion = true;
      document.documentElement.classList.add('has_floating-form');
    } else {
      this.isEditingQuestion = false;
      document.documentElement.classList.remove('has_floating-form');
    }
  }

}
