/*
 * Copyright (C) 2024 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { CalendarStartView } from '@app/app/shared/components/date-range-picker/date-range-picker.component';

@Component({
  selector: 'ptl-datapoint-selection-dialog',
  templateUrl: './datapoint-selection-dialog.component.html',
  styleUrls: ['./datapoint-selection-dialog.component.scss']
})
export class DatapointSelectionDialogComponent implements OnInit {
  startView: CalendarStartView = CalendarStartView.YEAR;
  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;
  @Output() applySelectionEmitter = new EventEmitter<DateRange<Date>>();
  @Output() closeDialog = new EventEmitter<void>();

  private isClickDetectionActive = false;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.evaluateStartView();
    this.activateClickDetection();
  }

  onDateRangeSelected($event: { startDate?: Date; endDate?: Date }): void {
    if ($event.startDate && $event.endDate) {
      const dateRange = new DateRange($event.startDate, $event.endDate);
      this.applySelectionEmitter.emit(dateRange);
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscapeKeydown(): void {
    this.closeDialog.emit();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.isClickDetectionActive) {
      return;
    }

    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    const clickedInsideCalendar = (event.target as HTMLElement).closest('.mat-calendar-body-cell');
    if (!clickedInside && !clickedInsideCalendar) {
      this.closeDialog.emit();
    }
  }

  private activateClickDetection(): void {
    setTimeout(() => {
      this.isClickDetectionActive = true;
    }, 300);
  }

  private evaluateStartView(): void {
    if (this.minDate && this.maxDate) {
      const yearDifference = this.maxDate.getFullYear() - this.minDate.getFullYear();
      const monthDifference = yearDifference * 12 + (this.maxDate.getMonth() - this.minDate.getMonth());

      if (yearDifference > 0) {
        this.startView = CalendarStartView.MULTI_YEAR;
      } else if (monthDifference > 0) {
        this.startView = CalendarStartView.YEAR;
      } else {
        this.startView = CalendarStartView.MONTH;
      }
    } else {
      this.startView = CalendarStartView.YEAR;
    }
  }
}
