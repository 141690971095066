<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="framework-tags-container f_framework-tags-container"
     ogClickOutside
     [clickableContainers]="[
      'f_framework-tags-container',
      'f_framework-tags-container-footer',
      'f_tags-nested-list',
      'f_inside-dialog' ]"
     (clickOutside)="closeComponent()"
     [isPanelOpen]="opened"
     [class.admin]="type === 'ADMIN'">
  <div class="framework-tags-container-top" *ngIf="type === 'DEFAULT'">
    <div class="framework-tags-container-top-title medium bold"
         [style.visibility]="selectContentStoreTags || selectDiscoveryTags ? 'hidden' : ''">
      <svg class="svg" role="img" aria-hidden="true">
        <use xlink:href="assets/sprite/sprite.svg#framework"></use>
      </svg>
      <span *ngIf="!selectFrameworks"
            [attr.aria-label]="frameworkType === 'STANDARD' ? ('translations.framework.label.addTagsFromFramework' | transloco) : ('translations.framework.label.addCategories' | transloco)">
          {{ frameworkType === 'STANDARD' ? ('translations.framework.label.addTagsFromFramework' | transloco) : ('translations.framework.label.addCategories' | transloco) }}
        </span>
      <span *ngIf="selectFrameworks"
            [attr.aria-label]="'translations.framework.label.addFrameworkFromFrameworks' | transloco">
          {{ 'translations.framework.label.addFrameworkFromFrameworks' | transloco }}
        </span>
    </div>
    <button class="close-button" mat-icon-button color="primary" (click)="onCancel()"
            [attr.aria-label]="('translations.global.ariaLabels.icons.close' | transloco)">
      <svg class="svg" role="img" aria-hidden="true">
        <use xlink:href="assets/sprite/sprite.svg#close"></use>
      </svg>
    </button>
  </div>

  <ptl-skeleton-view class="framework-skeleton-container"
                     [countInput]="6"
                     [state]="frameworks$ | async"
                     [viewType]="'table'">
    <div class="framework-tags-body" [class.border-top]="type === 'DEFAULT'">

      <div class="frameworks-cell"
           [class.hide]="selectContentStoreTags || selectDiscoveryTags || frameworkType === 'CATEGORIES'">
        <ptl-frameworks-list
          [isLtiModule]="isLtiModule"
          [frameworks]="frameworks"
          [selectFrameworks]="selectFrameworks"
          (frameworkCreate)="onOpenAddFrameworkView()"
          (loadFrameworkTags)="onLoadFrameworkTags($event)"
          (frameworkSelected)="onFrameworkSelected($event)"
        ></ptl-frameworks-list>
      </div>

      <div class="tags-cell" *ngIf="!selectFrameworks && !isCreateFrameworkState">
        <ptl-skeleton-view class="framework-skeleton-container" [state]="frameworkDescendants$ | async"
                           [viewType]="'table'">
          <ptl-framework-tags-list
            [selectedTagIds]="selectedTagIds"
            [isTagDisabled]="isTagDisabled"
            [frameworkTags]="frameworkTags"
            [selectedFramework]="selectedFramework"
            [frameworkNestedTags]="nestedTags"
            [singleSelection]="singleSelection"
            [type]="type"
            [frameworkType]="frameworkType"
            (loadNestedTags)="onLoadNestedTags($event)"
            (backToPreviousTags)="onNestedTagsBack()"
            (addRemoveTag)="onCheckboxChange($event)">
          </ptl-framework-tags-list>
        </ptl-skeleton-view>
      </div>


      <div class="tags-cell" *ngIf="isCreateFrameworkState">
        <ptl-frameworks-create
          #frameworkCreateComponent
          [isLtiModule]="isLtiModule"
          [editableFrameworkId]="selectedFrameworkId"
          [frameworkType]="frameworkType"
          (frameworkCreated)="onFrameworkCreated()">
        </ptl-frameworks-create>
      </div>
    </div>
  </ptl-skeleton-view>

  <div class="framework-tags-container-footer f_framework-tags-container-footer"
       *ngIf="!singleSelection && !isCreateFrameworkState" [class.flex-end]="type === 'ADMIN'">
      <span *ngIf="type === 'DEFAULT' && frameworkType === 'STANDARD'" class="small selected-tags-text">
        {{
          selectedTags.length ? (selectedTags.length === 1 ?
                ('translations.framework.label.oneTagSelected' | transloco : {tagsCount: selectedTags.length})
                :
                ('translations.framework.label.multipleTagsSelected' | transloco : {tagsCount: selectedTags.length})
            )
            : ('translations.framework.label.noTagsSelected' | transloco)
        }}
      </span>
    <span *ngIf="type === 'DEFAULT' && frameworkType === 'CATEGORIES'" class="small selected-tags-text">
        {{
        selectedTags.length ? (selectedTags.length === 1 ?
              ('translations.framework.label.oneCategorySelected' | transloco : {tagsCount: selectedTags.length})
              :
              ('translations.framework.label.multipleCategoriesSelected' | transloco : {tagsCount: selectedTags.length})
          )
          : ('translations.framework.label.noCategoriesSelected' | transloco)
      }}
      </span>

    <div class="buttons-container">
      <button
        [disabled]="!((isUserAdmin$ | async) ||
          (isUserSuperAdmin$ | async) ||
          ((userDetailsData$ | async)?.uid === selectedFramework?.creatorUid))"
        mat-flat-button
        color="accent"
        class="edit-btn"
        (click)="onEditTag()"
        *ngIf="!selectFrameworks"
        [title]="'translations.global.button.edit' | transloco"
        [attr.aria-label]="('translations.global.button.edit' | transloco)">
        <svg class="svg" role="img" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
        </svg>
        {{ 'translations.global.button.edit' | transloco }}
      </button>

      <button
        mat-flat-button
        color="potentially"
        class="add-button"
        *ngIf="!selectFrameworks && type === 'DEFAULT'"
        type="button"
        (click)="onAddTag()">
        {{ frameworkType === 'STANDARD' ? ('translations.framework.button.addTags' | transloco) : ('translations.framework.button.addCategories' | transloco) }}
      </button>

      <button
        mat-flat-button
        color="potentially"
        class="add-button"
        *ngIf="selectFrameworks"
        type="button"
        (click)="onAddFramework()">{{ 'translations.framework.button.addFrameworks' | transloco }}
      </button>
    </div>
  </div>
  <div class="framework-tags-container-footer f_framework-tags-container-footer flex-end"
       *ngIf="isCreateFrameworkState">

    <div class="buttons-wrapper">
      <button
        mat-flat-button
        color="accent"
        class="cancel-btn"
        type="button"
        (click)="onCancel()">
        {{ 'translations.global.button.cancel' | transloco }}
      </button>

      <button
        *ngIf="!selectedFrameworkId"
        mat-flat-button
        color="potentially"
        class="add-button"
        type="button"
        (click)="onCreateFramework()">{{ 'translations.framework.button.createNewFramework' | transloco }}
      </button>

      <button
        *ngIf="selectedFrameworkId"
        mat-flat-button
        color="potentially"
        class="add-button"
        type="button"
        (click)="onUpdateFramework()">{{ 'translations.framework.button.doneEditing' | transloco }}
      </button>
    </div>
  </div>
</div>
