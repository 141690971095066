/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Inject, Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import initHelpHero from 'helphero';
import { Observable, from } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EnvironmentCode } from '../../../environments/environment.model';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../page-modules/account/services/account-data.service';
import * as LanguagesActions from '../../page-modules/admin/store/languages/languages.actions';
import { RedirectHelper } from '../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { HELPHERO_ID, REDIRECT_AFTER_LOGIN } from '../../shared/constants/constants';
import { ContentHelper } from '../../shared/helpers/content-helper';
import { CookieHelper } from '../../shared/helpers/cookie-helper';
import { LanguageCodeHelper } from '../../shared/helpers/language-code-helper';
import { PrintPageHelper } from '../../shared/helpers/print-page/print-page.helper';
import { SnackbarHelper } from '../../shared/helpers/snackbar-helper';
import { Organization, OrganizationStatus, OrganizationTheme } from '../../shared/models';
import { UserWorkspaces } from '../../shared/models/account/account.model';
import { Publisher } from '../../shared/models/content-store/content-store.model';
import { isPublicFolioUrl } from '../../shared/paths/folio/folio-paths';
import {
  ORGANIZATION_RETRIEVAL_DATA_SERVICE,
  OrganizationRetrievalDataService
} from '../../shared/services/oraganization-retrieval/oraganization-retrieval-data.service';
import { TranslationService } from '../../shared/services/translation/translation.service';
import {
  LoadableState,
  ObservableResult,
  dataLoadedState,
  defaultLoadableState,
  errorState,
  loadingState
} from '../../shared/store';
import { UserAccountCreationRequest, UserDetails, UserDetailsSummary, UserRole } from '../models';
import { ACCESS_TOKEN_STORE_KEY } from '../services/access-token.service';
import { AUTH_SERVICE, BasicAuthService } from '../services/basic-auth.service';
import * as UserAuthActions from './user-auth.actions';
import {
  GetHomePageTitleAndUri,
  LoadOrganizationPublisher,
  UnauthenticateUser,
  UpdateOrganizationStyles
} from './user-auth.actions';
import { ProjectHelper } from '../../page-modules/project/helpers/project.helper';
import { WEBSOCKET_SERVICE, WebsocketService } from '../../shared/services/websocket/websocket.service';
import { FeatureCheckModel } from '../models/feature-check.model';
// eslint-disable-next-line
declare var gtag: Function;


/** The account creation state details. */
export interface AccountCreationState {
  /** The details provided by the user on signup. */
  accountCreationRequest: UserAccountCreationRequest;
  /** Defines if the signup process is in progress, i.e. user attempts to signup */
  accountCreationInProgress: boolean;
  /** The error message to display when account creation failed */
  accountCreationError: string;
  /** Defines if the account creation was successful. */
  accountCreationSucceeded: boolean;
}


/** The account verification state details. */
export interface AccountVerificationState {
  /** Defines if the account verification s is in progress, i.e. user attempts has entered a verification code. */
  accountVerificationInProgress: boolean;
  /** Defines if the account verification s is in progress, i.e. user attempts has entered a verification code. */
  accountVerificationError: string;
  /** Defines if the account verification has succeeded. */
  accountVerificationSucceeded: boolean;
}

export interface FeatureFlagsState {
  aiAssistantFeatureFlagEnabled: boolean;
  lmsSkillsFeatureFlagEnabled: boolean;
  editorJsFeatureFlagEnabled: boolean;
  profileCertificateFeatureFlagEnabled: boolean;
  enrollmentFeatureFlagEnabled: boolean;
  emailNotificationsFeatureFlagEnabled: boolean;
  categoriesFeatureFlagEnabled: boolean;
  eventsTopBarHiddenFeatureFlagEnabled: boolean;
  tableauV2FeatureFlagEnabled: boolean;
}

/** User state. */
export interface UserAuthStateModel {
  /** Contains the details of the user currently logged in. */
  userDetails: LoadableState<UserDetailsSummary>;
  /** Defines the account creation state. */
  accountCreationState: AccountCreationState;
  /** Defines the account verification state. */
  accountVerificationState: AccountVerificationState;
  featureFlagsState: FeatureFlagsState;
  /** Contains the details of the user organization based on the domain. */
  orgDetails: LoadableState<Organization>;
  /** Contains the details which defines if user has navigated from the sign in/up pages. */
  userNavigatedFromSignInUpPages: LoadableState<boolean>;
  /** Defines if user has previously logged in. */
  userPreviouslyLoggedIn: boolean;
  /** Defines if user submitted on board data. */
  onBoardDataSubmitted: boolean;
  /** Defines home page title. */
  homePageTitle: string;
  /** Defines home page uri for the user. */
  homePageUri: string;
  publisher: Publisher;
}


const getOrganizationDomain = () => {
  let domain: string;

  if ( [EnvironmentCode.dev, EnvironmentCode.devLocal].includes(environment.env) && window.location.hostname.includes('localhost') ) {
    domain = 'my.yuna.dev.potential.ly';
  } else if ( environment.env === EnvironmentCode.prod && window.location.hostname.includes('localhost') ) {
    domain = 'my.potential.ly';
  } else {
    domain = window.location.hostname;
  }

  return domain;
};


@State<UserAuthStateModel>({
  name: 'userAuth',
  defaults: {
    userNavigatedFromSignInUpPages: defaultLoadableState(),
    orgDetails: defaultLoadableState(),
    userDetails: defaultLoadableState(),
    homePageTitle: null,
    homePageUri: '/',
    publisher: undefined,
    accountCreationState: {
      accountCreationRequest: undefined,
      accountCreationInProgress: false,
      accountCreationError: undefined,
      accountCreationSucceeded: false,
    },
    accountVerificationState: {
      accountVerificationError: undefined,
      accountVerificationInProgress: false,
      accountVerificationSucceeded: false,
    },
    featureFlagsState: {
      aiAssistantFeatureFlagEnabled: false,
      lmsSkillsFeatureFlagEnabled: false,
      profileCertificateFeatureFlagEnabled: false,
      editorJsFeatureFlagEnabled: false,
      enrollmentFeatureFlagEnabled: false,
      emailNotificationsFeatureFlagEnabled: false,
      categoriesFeatureFlagEnabled: false,
      eventsTopBarHiddenFeatureFlagEnabled: false,
      tableauV2FeatureFlagEnabled: false
    },
    userPreviouslyLoggedIn: localStorage.getItem('userPreviouslyLoggedIn') === 'true',
    onBoardDataSubmitted: undefined,
  },
})
@Injectable()
export class UserAuthState {

  private readonly styleElement: HTMLStyleElement

  @Selector()
  static isAccountCreationInProgress( { accountCreationState }: UserAuthStateModel ): boolean {
    return accountCreationState.accountCreationInProgress;
  }

  @Selector()
  static onBoardDataIsSubmitted( { onBoardDataSubmitted, userDetails }: UserAuthStateModel ): boolean | undefined {
    return userDetails.data ? onBoardDataSubmitted : undefined;
  }

  @Selector()
  static userNavigatedFromSignInUpPages( { userNavigatedFromSignInUpPages }: UserAuthStateModel ): LoadableState<boolean> {
    return userNavigatedFromSignInUpPages;
  }

  @Selector()
  static isLoginInProgress( { userDetails }: UserAuthStateModel ): boolean {
    return userDetails.loading;
  }

  @Selector()
  static accountCreationError( { accountCreationState }: UserAuthStateModel ): string {
    return accountCreationState.accountCreationError;
  }

  @Selector()
  static accountCreationSucceeded( { accountCreationState }: UserAuthStateModel ): boolean {
    return accountCreationState.accountCreationSucceeded;
  }

  @Selector()
  static isAccountVerificationInProgress( { accountVerificationState }: UserAuthStateModel ): boolean {
    return accountVerificationState.accountVerificationInProgress;
  }

  @Selector()
  static accountVerificationError( { accountVerificationState }: UserAuthStateModel ): string {
    return accountVerificationState.accountVerificationError;
  }

  @Selector()
  static accountVerificationSucceeded( { accountVerificationState }: UserAuthStateModel ): boolean {
    return accountVerificationState.accountVerificationSucceeded;
  }

  @Selector()
  static userDetailsData( { userDetails }: UserAuthStateModel ): UserDetailsSummary {
    return userDetails.data;
  }

  @Selector()
  static userDetailsName( { userDetails }: UserAuthStateModel ): string {
    return userDetails.data ? (userDetails.data.firstName + ' ' + userDetails.data.lastName) : '';
  }

  @Selector()
  static userDetailsError( { userDetails }: UserAuthStateModel ): string {
    return userDetails.error;
  }

  @Selector()
  static userHasSuperAdminRole( { userDetails }: UserAuthStateModel ): boolean {
    return ( !!userDetails.data.roles.find(role => role.type === 'SUPER_ADMIN'));
  }

  @Selector()
  static userHasAdminRole( { userDetails }: UserAuthStateModel ): boolean {
    return ( !!userDetails.data.roles.find(role => role.type === 'ADMIN' || role.type === 'ACCOUNT_OWNER'));
  }

  @Selector()
  static userHasCreatePlaylistPrivileges( { userDetails }: UserAuthStateModel ): boolean {
    let canCreate = false;
    for ( const role of userDetails.data.roles ) {
      for ( const privilege of role.privileges ) {
        if ( privilege.name === 'can_create_playlists' || privilege.name === 'can_edit_any_playlist' ) {
          canCreate = privilege.assigned;
          if ( canCreate ) {
            break;
          }
        }
      }
      if ( canCreate ) {
        break;
      }
    }
    return canCreate;
  }

  @Selector()
  static canManageReviewsPrivileges( { userDetails }: UserAuthStateModel ): boolean {
    let canManage = false;
    for ( const role of userDetails.data.roles ) {
      for ( const privilege of role.privileges ) {
        if ( privilege.name === 'can_manage_reviews' ) {
          canManage = privilege.assigned;
          if ( canManage ) {
            break;
          }
        }
      }
      if ( canManage ) {
        break;
      }
    }
    return canManage;
  }

  @Selector()
  static canManageUserImportPrivileges( { userDetails }: UserAuthStateModel ): boolean {
    let canManage = false;
    for ( const role of userDetails.data.roles ) {
      for ( const privilege of role.privileges ) {
        if ( privilege.name === 'can_manage_user_import' ) {
          canManage = privilege.assigned;
          if ( canManage ) {
            break;
          }
        }
      }
      if ( canManage ) {
        break;
      }
    }
    return canManage;
  }

  @Selector()
  static userRoles( { userDetails }: UserAuthStateModel ): UserRole[] {
    return userDetails.data.roles;
  }

  @Selector()
  static userWorkspaces( { userDetails }: UserAuthStateModel ): UserWorkspaces[] {
    if ( userDetails.data ) {
      return userDetails.data?.workspaces;
    }
    return [];
  }

  @Selector()
  static isAlumniUser( { orgDetails, userDetails }: UserAuthStateModel ): boolean {
    if ( userDetails.data && orgDetails.data ) {
      const orgDomain = orgDetails.data?.domain;
      const workspaces = userDetails.data.workspaces;
      const currentWorkspace = workspaces.find(workspace => workspace.domain = orgDomain);
      if ( currentWorkspace ) {
        return currentWorkspace.memberDetails?.status === 'ALUMNI';
      } else {
        return false
      }
    }
    return false;
  }

  @Selector()
  static organizationDetails( { orgDetails }: UserAuthStateModel ): Organization {
    return orgDetails.data;
  }

  @Selector()
  static organizationName( { orgDetails }: UserAuthStateModel ): string {
    return orgDetails.data.name;
  }

  @Selector()
  static organizationDomain( { orgDetails }: UserAuthStateModel ): string {
    return orgDetails.data.domain;
  }

  @Selector()
  static organizationId( { orgDetails }: UserAuthStateModel ): string {
    return orgDetails.data._id;
  }

  @Selector()
  static userPreviouslyLoggedIn( { userPreviouslyLoggedIn }: UserAuthStateModel ): boolean {
    return userPreviouslyLoggedIn;
  }

  @Selector()
  static isDiscoveryPageAvailable( { orgDetails, userDetails }: UserAuthStateModel ): boolean {
    return orgDetails.data.discoveryPage.status === OrganizationStatus.ACTIVE ||
      !!userDetails.data.roles.find(role => role.type === 'ADMIN' || role.type === 'ACCOUNT_OWNER');
  }

  @Selector()
  static canAddNewWorkspace( { orgDetails, userDetails }: UserAuthStateModel ): boolean {
    if ( orgDetails.data && userDetails.data ) {
      const hasNoFolioTypeWorkspace = !userDetails.data.workspaces.find(item => item.type === 'FOLIO');
      return hasNoFolioTypeWorkspace && orgDetails.data.type !== 'FOLIO';
    }

    return false;
  }

  @Selector()
  static getHomePageTitle( { homePageTitle }: UserAuthStateModel ): string {
    return homePageTitle;
  }

  @Selector()
  static homePageUri( { homePageUri }: UserAuthStateModel ): string {
    return homePageUri;
  }

  @Selector()
  static getOrganizationPublisher( { publisher }: UserAuthStateModel ): Publisher {
    return publisher;
  }

  @Selector()
  static aiAssistantFeatureFlag( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.aiAssistantFeatureFlagEnabled;
  }

  @Selector()
  static lmsSkillsFeatureFlag( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.lmsSkillsFeatureFlagEnabled;
  }

  @Selector()
  static enrollmentFeatureFlag( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.enrollmentFeatureFlagEnabled;
  }

  @Selector()
  static emailNotificationsFeatureFlag( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.emailNotificationsFeatureFlagEnabled;
  }

  @Selector()
  static categoriesFeatureFlag( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.categoriesFeatureFlagEnabled;
  }

  @Selector()
  static tableauV2FeatureFlagEnabled( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.tableauV2FeatureFlagEnabled;
  }

  @Selector()
  static profileCertFlag( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.profileCertificateFeatureFlagEnabled;
  }

  @Selector()
  static editorJsFeatureFlag( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.editorJsFeatureFlagEnabled;
  }

  @Selector()
  static eventsTopBarHiddenFeatureFlagEnabled( { featureFlagsState }: UserAuthStateModel ): boolean {
    return featureFlagsState.eventsTopBarHiddenFeatureFlagEnabled;
  }


  static setPreviousUrlAfterLogin( url?: string ) {
    const previousUrlPath = !url ? this.checkIfShouldSetUrl() : url;
    if ( previousUrlPath ) {
      localStorage.setItem(REDIRECT_AFTER_LOGIN, previousUrlPath);
    }
  }

  static checkIfShouldSetUrl() {
    if ( location.pathname.includes('/account-verification')
      || location.pathname.includes('/signin')
      || location.pathname.includes('/signup')
      || location.pathname.includes('/invitation')
      || location.pathname.includes('/support/signin')
      || location.pathname.includes('/signin/classof2020')
      || location.pathname.includes('/graduate/signin')
      || location.pathname.includes('/signup/classof2020')
      || location.pathname.includes('/lti/launch')
      || location.pathname.includes('/lti/deeplink')
      || location.pathname.includes('/lti/course')
      || location.pathname.includes('/saml/signin')
      || location.pathname.includes('/saml/signin-error')
      || location.pathname.includes('/lti/playlists/edit')
      || location.pathname.includes('/lti/registration/error')
      || location.pathname.includes('/reset-password')
      || location.pathname.includes('/forgot-password')
      || location.pathname.includes('/reset-email')
      || location.pathname.includes('/verify-email')
      || location.pathname.includes('/confirm-email')
      || location.pathname.includes('/unverified-email')
      || location.pathname.includes('/verify-personal-email')
      || location.pathname.includes('/verify-alumni-email')
      || location.pathname.includes('/verify-pre-arrival')
      || location.pathname.includes('/verify-pre-arrival-with-folio')
      || location.pathname.includes('/verify-organisation-email')
      || location.pathname.includes('/verify-organisation-email-with-folio')
      || location.pathname.includes('/prearrivals/signin')
      || location.pathname.includes('/prearrivals/signup')
      || location.pathname.includes('/prearrivals/forgot-password')
      || location.pathname.includes('/verify-folio')
      || location.pathname.includes('/verify-alumni')
      || location.pathname.includes('/iframe-logout')
      || location.pathname.includes('/folio/badge')
      || isPublicFolioUrl(location.pathname)
    ) {
      return '';
    }
    return location.pathname + location.search;
  }

  constructor(
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    @Inject(ORGANIZATION_RETRIEVAL_DATA_SERVICE) private organizationRetrievalService: OrganizationRetrievalDataService,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    @Inject(WEBSOCKET_SERVICE) private websocketService: WebsocketService,
    private translationService: TranslationService,
    private store: Store,
    private router: Router,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
  ) {
    this.styleElement = document.createElement('style');
    document.head.appendChild(this.styleElement);
  }

  @Action(UserAuthActions.TriggerUserLogin)
  triggerUserLogin(
    context: StateContext<UserAuthStateModel>,
    { email, password }: UserAuthActions.TriggerUserLogin
  ): ObservableResult<UserDetails> {
    const { patchState, dispatch } = context;
    patchState({
      userDetails: loadingState(),
    });
    const organizationDomain = this.store.selectSnapshot(UserAuthState.organizationDetails)?.domain;
    return this.authService.authenticate(email, password, organizationDomain).pipe(
      tap(
        (( { isSuccess, value, error } ) =>
          isSuccess ? this.handleSuccessfulAuthentication(value, context, email) : dispatch(new UserAuthActions.UserLoginFailed(error)))
      )
    );
  }

  @Action(UserAuthActions.TriggerFolioUserLogin)
  triggerFolioUserLogin(
    context: StateContext<UserAuthStateModel>,
    { email, password, targetOrganization }: UserAuthActions.TriggerFolioUserLogin
  ): ObservableResult<UserDetails> {
    const { patchState, dispatch } = context;
    patchState({
      userDetails: loadingState(),
    });
    const organizationDomain = this.store.selectSnapshot(UserAuthState.organizationDetails)?.domain;
    return this.authService.authenticate(email, password, organizationDomain).pipe(
      tap(
        (( { isSuccess, error } ) =>
          isSuccess ? this.handleSuccessfulFolioUserAuthentication(context, targetOrganization, email) :
            dispatch(new UserAuthActions.UserLoginFailed(error)))
      )
    );
  }

  private handleSuccessfulAuthentication(
    userDetails: UserDetails,
    { getState, patchState }: StateContext<UserAuthStateModel>,
    email: string,
  ): void {
    const state = getState();
    const currentOrganization = state.orgDetails.data;
    userDetails.organization = {
      _id: currentOrganization._id,
      name: currentOrganization.name,
    };
    patchState({
      userDetails: dataLoadedState(userDetails),
    });
    this.authService.finalize(email).subscribe(( { isSuccess, error } ) => {
      if ( isSuccess ) {
        this.proceed(patchState, currentOrganization);
        this.websocketService.openConnection();
      } else if ( error ) {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

  private handleSuccessfulFolioUserAuthentication(
    { getState, patchState }: StateContext<UserAuthStateModel>,
    destinationOrganization: string,
    email: string,
  ): void {
    const state = getState();
    const currentOrganization = state.orgDetails.data;
    this.authService.finalizeFolioLogin(destinationOrganization, true, email)
      .subscribe(( { value, isSuccess, error } ) => {
        if ( isSuccess ) {
          this.proceed(patchState, currentOrganization);
          this.websocketService.openConnection();
        } else if ( error ) {
          localStorage.setItem('user_is_logged_in', 'false');
          localStorage.removeItem('auth_access_token');
          this.setPreviouslyLoggedInState(false, patchState);
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      });
  }

  private proceed(
    patchState: ( val: Partial<UserAuthStateModel> ) => UserAuthStateModel,
    organization: Organization,
  ): void {
    this.setPreviouslyLoggedInState(true, patchState);
    this.listenForRouteChanges();
    this.accountService.getPersonalData().subscribe(( data ) => {
      const userLanguage = data.value?.userSettings.language;
      const storedLanguage = this.translationService.getLanguage();
      this.translationService.setLanguage((userLanguage !== storedLanguage) ? userLanguage : storedLanguage);
      this.checkUserSelectedLanguageOrientation(organization, (userLanguage !== storedLanguage) ? userLanguage : storedLanguage)
      this.store.dispatch(new LanguagesActions.LoadOrganizationLanguages(organization._id));
      this.store.dispatch(new UserAuthActions.UpdateUserPersonalDetails(data.value));
      this.store.dispatch(new LoadOrganizationPublisher());
      this.store.dispatch(new GetHomePageTitleAndUri()).toPromise().then(() => this.redirectFurther());
      this.setOnBoardingDataSubmitStatus(data.value.onBoardingSubmitted, patchState);
    });
  }

  private listenForRouteChanges() {
    const propertyId = environment.googleAnalyticsPropertyId;
    if ( propertyId ) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      this.router.events.subscribe(( event: any ) => {
        if ( event.routerEvent instanceof NavigationEnd ) {
          gtag('config', propertyId, {
            page_path: event.routerEvent.urlAfterRedirects,
          });
        }
      });
    }
  }

  private initializeGoogleAnalytics() {
    try {
      const propertyId = environment.googleAnalyticsPropertyId;
      if ( propertyId ) {
        const firstScript = document.createElement('script');
        firstScript.async = true;
        firstScript.src = `https://www.googletagmanager.com/gtag/js?id=${propertyId}`;
        document.head.appendChild(firstScript);
        const secondScript = document.createElement('script');
        secondScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${propertyId}', {'send_page_view': false, 'cookieDomain': 'auto'});
      `;
        document.head.appendChild(secondScript);
      }
    } catch ( ex ) {
      console.error(ex);
    }
  }

  private redirectFurther() {
    let redirectAfterLoginUrl = CookieHelper.GetCookie(REDIRECT_AFTER_LOGIN);
    if ( !redirectAfterLoginUrl ) {
      redirectAfterLoginUrl = localStorage.getItem(REDIRECT_AFTER_LOGIN);
    }
    if ( redirectAfterLoginUrl ) {
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, redirectAfterLoginUrl);
      localStorage.removeItem(REDIRECT_AFTER_LOGIN);
      CookieHelper.DeleteCookie(REDIRECT_AFTER_LOGIN);
    } else {
      const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
      RedirectHelper.redirectToHomePage(this.ngZone, this.router, this.activatedRoute, homePageUri);
    }
  }

  @Action(UserAuthActions.TriggerUserAccountCreation)
  triggerAccountCreation(
    { patchState, dispatch, getState }: StateContext<UserAuthStateModel>,
    { accountCreationRequest, invitationId, token }: UserAuthActions.TriggerUserAccountCreation
  ) {

    const orgDetails = getState().orgDetails.data;

    patchState({
      accountCreationState: {
        ...getState().accountCreationState,
        accountCreationRequest,
        accountCreationInProgress: true,
      },
    });

    if ( invitationId ) {
      return this.authService.createAccountWithInvitationToken(accountCreationRequest, invitationId, token, orgDetails.domain)
        .pipe(
          map((( { isSuccess, error } ) => isSuccess
            ? dispatch(new UserAuthActions.UserAccountCreationSuccess(false))
            : dispatch(new UserAuthActions.UserAccountCreationError(error))))
        );
    } else {
      return this.authService.createAccount(accountCreationRequest, orgDetails._id, orgDetails.domain, orgDetails.name)
        .pipe(
          map((( { isSuccess, error } ) => isSuccess
            ? dispatch(new UserAuthActions.UserAccountCreationSuccess(true))
            : dispatch(new UserAuthActions.UserAccountCreationError(error))))
        );
    }
  }

  @Action(UserAuthActions.UserAccountCreationSuccess)
  accountCreationSuccess(
    { patchState, getState }: StateContext<UserAuthStateModel>,
    { emailVerificationIsNeeded }: UserAuthActions.UserAccountCreationSuccess
  ) {

    patchState({
      accountCreationState: {
        ...getState().accountCreationState,
        accountCreationInProgress: false,
        accountCreationSucceeded: true,
      },
    });

    const url = emailVerificationIsNeeded ? '/unverified-email' : '/signin';
    const extraParams = emailVerificationIsNeeded ?
      {
        queryParams: {
          email: getState().accountCreationState.accountCreationRequest.email, register: true,
        },
      } :
      undefined;

    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, url, extraParams);
  }

  @Action(UserAuthActions.UserAccountCreationError)
  accountCreationError(
    { patchState, getState }: StateContext<UserAuthStateModel>,
    { error }: UserAuthActions.UserAccountCreationError
  ) {

    patchState({
      accountCreationState: {
        ...getState().accountCreationState,
        accountCreationInProgress: false,
        accountCreationSucceeded: false,
        accountCreationError: error,
      },
    });
  }

  @Action(UserAuthActions.TriggerUserAccountVerification)
  triggerAccountVerification(
    { patchState, dispatch, getState }: StateContext<UserAuthStateModel>,
    { token }: UserAuthActions.TriggerUserAccountVerification
  ) {

    patchState({
      accountVerificationState: {
        ...getState().accountVerificationState,
        accountVerificationInProgress: true,
      },
    });

    return this.authService.verifyAccount(token).pipe(
      map((( { isSuccess, error } ) => isSuccess
        ? dispatch(new UserAuthActions.UserAccountVerificationSuccess())
        : dispatch(new UserAuthActions.TriggerUserAccountVerificationError(error)))));
  }

  @Action(UserAuthActions.UserAccountVerificationSuccess)
  accountVerificationSuccess(
    { patchState, getState }: StateContext<UserAuthStateModel> ) {

    patchState({
      accountVerificationState: {
        ...getState().accountVerificationState,
        accountVerificationInProgress: false,
        accountVerificationSucceeded: true,
      },
    });

    const message = this.translationService.getTranslation('public.message.success.accountVerified');
    RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
    this.ngZone.run(() => {
      this.snackBar.open(
        message,
        '',
        {
          panelClass: 'success',
          politeness: 'polite',
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
        }
      );
    });
  }

  @Action(UserAuthActions.TriggerUserAccountVerificationError)
  accountVerificationError(
    { patchState, getState }: StateContext<UserAuthStateModel>,
    { error }: UserAuthActions.TriggerUserAccountVerificationError
  ) {

    patchState({
      accountVerificationState: {
        ...getState().accountVerificationState,
        accountVerificationError: error,
      },
    });
  }

  @Action(UserAuthActions.RedirectToAuthenticatedApp)
  redirectToAuthenticatedApp( { dispatch, patchState }: StateContext<UserAuthStateModel> ) {
    return this.accountService.getPersonalData().pipe(filter(data => !!data),
      map(( { isSuccess, value } ) => {
        if ( isSuccess && value ) {
          this.setOnBoardingDataSubmitStatus(value.onBoardingSubmitted, patchState);
          const userLanguage = value.userSettings.language;
          const storedLanguage = this.translationService.getLanguage();
          const organization = this.store.selectSnapshot(UserAuthState.organizationDetails);
          this.checkUserSelectedLanguageOrientation(organization, (userLanguage !== storedLanguage) ? userLanguage : storedLanguage)
          this.translationService.setLanguage((userLanguage !== storedLanguage) ? userLanguage : storedLanguage);
          this.store.dispatch(new UserAuthActions.UpdateUserPersonalDetails(value));
        }
        return isSuccess
          ? dispatch([new UserAuthActions.RedirectToAuthenticatedAppSuccess()])
          : dispatch(new UserAuthActions.RedirectToAuthenticatedAppFailure());
      }));
  }

  @Action(UserAuthActions.UserLoginFailed)
  userLoginFailed( { patchState }: StateContext<UserAuthStateModel> ) {
    this.setPreviouslyLoggedInState(false, patchState);

    patchState({
      userDetails: defaultLoadableState(),
    });

    const message = this.translationService.getTranslation('public.message.error.loginFailed');
    this.ngZone.run(() =>
      this.snackBar.open(
        message,
        '',
        {
          panelClass: 'error',
          politeness: 'assertive',
          duration: 4000,
          verticalPosition: 'top',
          horizontalPosition: 'center',
        }
      )
    );
  }

  @Action(UserAuthActions.UpdateUserPersonalDetails)
  updateUserPersonalDetails(
    { patchState, getState }: StateContext<UserAuthStateModel>,
    { userPersonalData }: UserAuthActions.UpdateUserPersonalDetails
  ) {
    patchState({
      ...getState(),
      userDetails: {
        ...getState().userDetails,
        data: {
          ...getState().userDetails.data,
          uid: userPersonalData.uid,
          firstName: userPersonalData.firstName,
          lastName: userPersonalData.lastName,
          email: userPersonalData.email,
          imageUrl: userPersonalData.imageUrl ? userPersonalData.imageUrl : 'assets/profile_image.png',
          description: userPersonalData.description,
          roles: userPersonalData.roles,
          organization: { _id: getState().orgDetails.data._id, name: getState().orgDetails.data.name },
          workspaces: userPersonalData.workspaces,
          userSettings: userPersonalData.userSettings,
          eptid: userPersonalData.eptid,
        },
      },
    });

    this.setPreviouslyLoggedInState(true, patchState);
  }

  @Action(UserAuthActions.UpdateUserJiscUsage)
  updateUserJiscUsage(
    { patchState, getState }: StateContext<UserAuthStateModel>,
    { hasUsedCpd }: UserAuthActions.UpdateUserJiscUsage
  ) {
    const organizationId = getState().orgDetails.data._id;
    this.accountService.updateJiscUsage(hasUsedCpd).subscribe(( { isSuccess, error } ) => {
      if ( isSuccess ) {
        patchState({
          ...getState(),
          userDetails: {
            ...getState().userDetails,
            data: {
              ...getState().userDetails.data,
              workspaces: getState().userDetails.data.workspaces.map(item => {
                if ( item.organizationUid === organizationId ) {
                  item.memberDetails.hasUsedCpd = hasUsedCpd;
                }
                return item;
              }),
            },
          },
        });
      } else if ( error ) {
        SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
      }
    });
  }

  @Action(UserAuthActions.RedirectToAuthenticatedAppSuccess)
  redirectToAuthenticatedAppSuccess() {
    this.listenForRouteChanges();
    if ( location.pathname.includes('/signin') || location.pathname.includes('/signup') || !!location.hash ) {
      // Wrapped with ngZone to prevent Angular timing rendering issues
      const redirectAfterLoginUrl = localStorage.getItem(REDIRECT_AFTER_LOGIN);
      if ( redirectAfterLoginUrl ) {
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, redirectAfterLoginUrl);
        localStorage.removeItem(REDIRECT_AFTER_LOGIN);
      } else {
        const homePageUri = this.store.selectSnapshot(UserAuthState.homePageUri);
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, homePageUri);
      }
    }
  }

  @Action(UserAuthActions.RedirectToAuthenticatedAppFailure)
  redirectToAuthenticatedFailure() {
    if ( !location.pathname.includes('/account-verification') ) {
      if ( location.pathname.includes('/signup')
        && !location.pathname.includes('/signup/classof2020')
        && !location.pathname.includes('/prearrivals/signup')
      ) {
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signup');
        return;
      }
      if ( location.pathname.includes('/support/signin') ||
        location.pathname.includes('/graduate/signin') ||
        location.pathname.includes('/signin/classof2020') ||
        location.pathname.includes('/signup/classof2020') ||
        location.pathname.includes('/invitation/') ||
        location.pathname.includes('/reset-password') ||
        location.pathname.includes('/forgot-password') ||
        location.pathname.includes('/reset-email') ||
        location.pathname.includes('/verify-email') ||
        location.pathname.includes('/confirm-email') ||
        location.pathname.includes('/unverified-email') ||
        location.pathname.includes('/verify-personal-email') ||
        location.pathname.includes('/verify-alumni-email') ||
        location.pathname.includes('/verify-pre-arrival') ||
        location.pathname.includes('/verify-pre-arrival-with-folio') ||
        location.pathname.includes('/verify-organisation-email') ||
        location.pathname.includes('/verify-organisation-email-with-folio') ||
        location.pathname.includes('/prearrivals/signin') ||
        location.pathname.includes('/prearrivals/signup') ||
        location.pathname.includes('/prearrivals/forgot-password') ||
        location.pathname.includes('/verify-folio') ||
        location.pathname.includes('/verify-alumni') ||
        location.pathname.includes('/verify-pre-arrival') ||
        location.pathname.includes('/verify-pre-arrival-with-folio') ||
        location.pathname.includes('/lti/launch') ||
        location.pathname.includes('/lti/deeplink') ||
        location.pathname.includes('/lti/course') ||
        location.pathname.includes('/saml/signin') ||
        location.pathname.includes('/lti/playlists/edit') ||
        location.pathname.includes('/iframe-logout') ||
        location.pathname.includes('/saml/signin-error') ||
        location.pathname.includes('/lti/registration/error') ||
        location.pathname.includes('/folio/badge') ||
        isPublicFolioUrl(location.pathname) ||
        ProjectHelper.isProjectPublicView()
      ) {
        return;
      }
      if ( !localStorage.getItem(REDIRECT_AFTER_LOGIN) ) {
        UserAuthState.setPreviousUrlAfterLogin();
      }
      if ( ContentHelper.isFrameMode() ) {
        // for iframe redirect to iframe error page
        RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/iframe-logout');
        return;
      }
      RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
    }
  }

  @Action(UserAuthActions.FetchUserDetails)
  fetchUserDetails(
    { getState, patchState, dispatch }: StateContext<UserAuthStateModel> ) {
    patchState({
      userDetails: loadingState(),
    });
    if ( getState().userDetails.data ) {
      patchState({ userDetails: dataLoadedState(getState().userDetails.data) });
    } else {
      patchState({ userDetails: errorState('User detail is not defined') });
    }
    this.usePersonalData(getState().userDetails.data, patchState, getState, dispatch);
  }

  @Action(UserAuthActions.SendOnBoardingData)
  sendOnBoardingData(
    { patchState }: StateContext<UserAuthStateModel>,
    { data }: UserAuthActions.SendOnBoardingData
  ) {
    this.setOnBoardingDataSubmitStatus(true, patchState);
    this.authService.sendOnBoardData(data).subscribe();
  }

  @Action(UserAuthActions.GetHomePageTitleAndUri)
  getHomePageTitle(
    { patchState, getState }: StateContext<UserAuthStateModel>
  ) {
    const homePageTitle = getState().homePageTitle;
    const homePageUri = getState().homePageUri;
    if ( homePageTitle && homePageUri ) {
      return false;
    }
    const isAlumni = this.store.selectSnapshot(UserAuthState.isAlumniUser);
    return this.organizationRetrievalService.getHomePage(isAlumni).pipe(
      tap(( { isSuccess, value, error } ) => {
          if ( isSuccess ) {
            patchState({
              homePageTitle: value.headline,
              homePageUri: value.type === 'HOME_PAGE' ? '/' : value.uri
            });
          } else {
            patchState({
              homePageTitle: '',
              homePageUri: '/'
            });
          }
        }
      ));
  }

  @Action(UserAuthActions.UpdateHomePageTitle)
  updateHomePageTitle(
    { patchState }: StateContext<UserAuthStateModel>,
    { title }: UserAuthActions.UpdateHomePageTitle
  ) {
    patchState({
      homePageTitle: title,
    });
  }

  @Action(UserAuthActions.InitHelpHero)
  initHelpHero(
    { getState }: StateContext<UserAuthStateModel> ) {
    const domain = getState().orgDetails.data?.domain;

    if ( domain === 'fab-college.potential.ly' || domain === 'uzh.potential.ly' || domain === 'my.yuna.dev.potential.ly' ) {
      const userUid = getState().userDetails.data?.uid;
      const hlp = initHelpHero(HELPHERO_ID);
      if ( userUid ) {
        hlp.identify(userUid);
      } else {
        hlp.anonymous();
      }
    }
  }


  private setOnBoardingDataSubmitStatus(
    status: boolean,
    patchState: ( val: Partial<UserAuthStateModel> ) => UserAuthStateModel
  ) {
    patchState({
      onBoardDataSubmitted: status,
    });
  }

  private usePersonalData(
    personalData: UserDetailsSummary | undefined,
    patchState: ( val: Partial<UserAuthStateModel> ) => UserAuthStateModel,
    getState: () => UserAuthStateModel,
    dispatch: ( actions: UnauthenticateUser ) => Observable<void>
  ) {
    this.accountService.getPersonalData().subscribe(( { isSuccess, value, error } ) => {
      if ( isSuccess ) {
        if ( personalData ) {
          patchState({
            ...getState(),
            userDetails: {
              ...getState().userDetails,
              data: {
                ...getState().userDetails.data,
                organization: { _id: getState().orgDetails.data._id, name: getState().orgDetails.data.name },
                uid: value.uid,
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                eptid: value.eptid,
                imageUrl: value.imageUrl ? value.imageUrl : personalData.imageUrl ? personalData.imageUrl : 'assets/profile_image.png',
                description: value.description,
                roles: value.roles,
                workspaces: value.workspaces,
                userSettings: value.userSettings,
              },
            },
          });
        } else {
          patchState({
            ...getState(),
            userDetails: {
              ...getState().userDetails,
              error: undefined,
              data: {
                ...getState().userDetails.data,
                organization: { _id: getState().orgDetails.data._id, name: getState().orgDetails.data.name },
                uid: value.uid,
                firstName: value.firstName,
                lastName: value.lastName,
                email: value.email,
                imageUrl: value.imageUrl ? value.imageUrl : 'assets/profile_image.png',
                description: value.description,
                roles: value.roles,
                eptid: value.eptid,
                workspaces: value.workspaces,
                userSettings: value.userSettings,
              },
            },
          });
        }
        if ( value.organization && !value.organization?._id.includes(getState().orgDetails.data._id) ) {
          dispatch(new UserAuthActions.UnauthenticateUser());
          return;
        }
      } else {
        patchState({ userDetails: errorState(error) });
      }
    });
  }

  @Action(UserAuthActions.UpdateOrganizationPublisher)
  updateOrganizationPublisher(
    { patchState }: StateContext<UserAuthStateModel>,
    { publisher }: UserAuthActions.UpdateOrganizationPublisher
  ) {
    patchState({ publisher: publisher });
  }

  @Action(UserAuthActions.LoadOrganizationPublisher)
  loadOrganizationPublisher( { patchState }: StateContext<UserAuthStateModel> ) {
    return this.organizationRetrievalService.getCurrentOrganizationPublisher().pipe(
      tap(( { isSuccess, value } ) => {
        if ( isSuccess ) {
          patchState({ publisher: value });
        } else {
          patchState({ publisher: undefined });
        }
      }));
  }

  @Action(UserAuthActions.LoadOrganizationDetails)
  loadOrganizationDetails( { patchState }: StateContext<UserAuthStateModel> ) {
    const domain = getOrganizationDomain();
    this.initializeGoogleAnalytics();
    return this.organizationRetrievalService.findOrganizationForDomain(domain).pipe(
      tap(( { isSuccess, value, error } ) => {
        if ( isSuccess ) {
          this.translationService.setOrganizationDefaultLanguage(value.defaultLanguage);
          this.translationService.setOrganizationLanguage(LanguageCodeHelper.getOrganizationDefaultLanguage(value));
          const accessToken = localStorage.getItem(ACCESS_TOKEN_STORE_KEY);
          if ( accessToken ) {
            this.store.dispatch(new LanguagesActions.LoadOrganizationLanguages(value._id));
          }
          ContentHelper.setOrgDiscoveryUrl(value.discoveryPage?.uri);
          patchState({ orgDetails: dataLoadedState(value) });
          if ( value.pointClick ) {
            this.store.dispatch(new UpdateOrganizationStyles(value.pointClick));
          }
        } else {
          patchState({ orgDetails: errorState(error) });
        }
      }));
  }

  @Action(UserAuthActions.CheckOrganizationFeature)
  checkOrganizationFeature(
    { patchState, getState }: StateContext<UserAuthStateModel>,
    { feature }: UserAuthActions.CheckOrganizationFeature
  ) {
    const organizationUid = getState().orgDetails.data._id;

    const featureFlagMapping = {
      [FeatureCheckModel.AI_ASSISTANT]: 'aiAssistantFeatureFlagEnabled',
      [FeatureCheckModel.LMS_SKILLS]: 'lmsSkillsFeatureFlagEnabled',
      [FeatureCheckModel.PROFILE_CERTS]: 'profileCertificateFeatureFlagEnabled',
      [FeatureCheckModel.ENROLLMENT]: 'enrollmentFeatureFlagEnabled',
      [FeatureCheckModel.EMAIL_NOTIFICATIONS]: 'emailNotificationsFeatureFlagEnabled',
      [FeatureCheckModel.CATEGORIES]: 'categoriesFeatureFlagEnabled',
      [FeatureCheckModel.EVENTS_TOP_BAR_HIDDEN]: 'eventsTopBarHiddenFeatureFlagEnabled',
      [FeatureCheckModel.TABLEAU_V2_ENABLED]: 'tableauV2FeatureFlagEnabled',
    };

    return this.organizationRetrievalService.checkFeatureForOrganization(organizationUid, feature).pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          const state = getState();
          const flagKey = featureFlagMapping[feature];
          if (flagKey) {
            patchState({
              featureFlagsState: {
                ...state.featureFlagsState,
                [flagKey]: value,
              },
            });
          }
        } else {
          console.log(error);
        }
      })
    );
  }

  @Action(UserAuthActions.EditorJsFeatureFlag)
  checkEditorJsFeatureFlag(
    { patchState, getState }: StateContext<UserAuthStateModel>
  ) {
    return this.organizationRetrievalService.checkFeatureForOrganization(getState().orgDetails.data._id,
      FeatureCheckModel.EDITOR_JS).pipe(
      tap(( { isSuccess, value, error } ) => {
        if ( isSuccess ) {
          const state = getState();

          patchState({
            featureFlagsState: {
              ...state.featureFlagsState,
              editorJsFeatureFlagEnabled: value
            }
          });
        } else {
          console.log(error);
        }
      }));
  }

  @Action(UserAuthActions.LoadOrganizationFeatures)
  loadOrganizationFeatures(
    { patchState }: StateContext<UserAuthStateModel>,
    { orgUid }: UserAuthActions.LoadOrganizationFeatures
  ) {
    return this.organizationRetrievalService.getOrganizationFeatures(orgUid).pipe(
      tap(( { isSuccess, value, error } ) => {
        if ( isSuccess ) {
          console.log(value);
        } else {
          console.log(error);
        }
      }));
  }

  @Action(UserAuthActions.AddDomainToOrganization)
  addDomainToOrganization(
    { getState, patchState }: StateContext<UserAuthStateModel>,
    { domain, direction }: UserAuthActions.AddDomainToOrganization
  ) {
    const organization = getState().orgDetails.data;
    const observable = direction === 'white' ?
      this.organizationRetrievalService.addDomainToWhitelist(domain) :
      this.organizationRetrievalService.addDomainToBlacklist(domain);
    return observable.pipe(
      tap(( { isSuccess, error } ) => {
        if ( isSuccess ) {
          const messageKey = direction === 'white' ?
            'whitelist.message.success.addedToWhitelist' : 'whitelist.message.success.addedToBlacklist';
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, messageKey);
          if ( direction === 'white' ) {
            if ( !organization.whitelist ) {
              organization.whitelist = [];
            }
            organization.whitelist.push(domain);
          } else {
            if ( !organization.blacklist ) {
              organization.blacklist = [];
            }
            organization.blacklist.push(domain);
          }
          patchState({ orgDetails: dataLoadedState(organization) });
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      }));
  }


  @Action(UserAuthActions.RemoveDomainFromOrganization)
  removeDomainFromOrganization(
    { getState, patchState }: StateContext<UserAuthStateModel>,
    { domain, direction }: UserAuthActions.RemoveDomainFromOrganization
  ) {
    const organization = getState().orgDetails.data;
    const observable = direction === 'white' ?
      this.organizationRetrievalService.removeDomainFromWhitelist(domain) :
      this.organizationRetrievalService.removeDomainFromBlacklist(domain);
    return observable.pipe(
      tap(( { isSuccess, error } ) => {
        if ( isSuccess ) {
          const messageKey = direction === 'white' ?
            'whitelist.message.success.removeFromWhitelist' : 'whitelist.message.success.removeFromBlackList';
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, messageKey);
          if ( direction === 'white' ) {
            organization.whitelist = organization.whitelist.filter(item => item !== domain);
          } else {
            organization.blacklist = organization.blacklist.filter(item => item !== domain);
          }
          patchState({ orgDetails: dataLoadedState(organization) });
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      }));
  }


  @Action(UserAuthActions.RemoveAllDomainsFromOrganization)
  removeAllDomainsFromOrganization(
    { getState, patchState }: StateContext<UserAuthStateModel>,
    { direction }: UserAuthActions.RemoveAllDomainsFromOrganization
  ) {
    const organization = getState().orgDetails.data;
    const observable = direction === 'white' ?
      this.organizationRetrievalService.clearWhitelistDomains() :
      this.organizationRetrievalService.clearBlacklistDomains();
    return observable.pipe(
      tap(( { isSuccess, error } ) => {
        if ( isSuccess ) {
          const messageKey = direction === 'white' ?
            'whitelist.message.success.domainsRemovedFromWhitelist' : 'whitelist.message.success.domainsRemovedFromBlacklist';
          SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, messageKey);
          if ( direction === 'white' ) {
            organization.whitelist = [];
          } else {
            organization.blacklist = [];
          }
          patchState({ orgDetails: dataLoadedState(organization) });
        } else {
          SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
        }
      }));
  }

  @Action(UserAuthActions.UpdateOrganizationStyles)
  updateOrganizationStyles(
    { getState, patchState }: StateContext<UserAuthStateModel>,
    { pointClicks }: UserAuthActions.UpdateOrganizationStyles
  ) {

    const organization = getState().orgDetails.data;
    organization.pointClick = pointClicks;
    patchState({ orgDetails: dataLoadedState(organization) });
    this.updateRootStyles(pointClicks);
  }

  @Action(UserAuthActions.UnauthenticateUser)
  handlerUnauthentication(
    _: StateContext<UserAuthStateModel>,
    { redirectUri }: UserAuthActions.UnauthenticateUser
  ) {
    return this.authService.unauthenticate(redirectUri);
  }

  @Action(UserAuthActions.FetchProfileImageDataUrl)
  fetchProfileImageDataUrl(
    { patchState, getState }: StateContext<UserAuthStateModel>
  ) {
    const promise = new Promise<void>(resolve => {
      const imageUrl = getState().userDetails?.data?.imageUrl;

      if ( imageUrl?.startsWith('http') ) {
        PrintPageHelper.fetchImageDataUrls([fetch(imageUrl)]).then(mapping => {
          if ( mapping[imageUrl] ) {
            patchState({
              ...getState(),
              userDetails: {
                data: {
                  ...getState().userDetails.data,
                  imageUrl: mapping[imageUrl],
                },
                loading: false,
              },
            });
          }

          resolve();
        });
      } else {
        resolve();
      }
    });

    return from(promise);
  }

  @Action(UserAuthActions.LmsSkillsFeatureFlag)
  checkLmsSkillsFeatureFlag(
    { getState, patchState }: StateContext<UserAuthStateModel>,
  ) {
    return this.organizationRetrievalService.checkFeatureForOrganization(getState().orgDetails.data._id,
      FeatureCheckModel.LMS_SKILLS).pipe(
      tap(( { isSuccess, value, error } ) => {
        if ( isSuccess ) {
          const state = getState();

          patchState({ featureFlagsState: {
              ...state.featureFlagsState,
              lmsSkillsFeatureFlagEnabled: value
            }});
        } else {
          console.log(error);
        }
      }));
  }

  @Action(UserAuthActions.EmailNotificationsFeatureFlag)
  checkEmailNotificationsFeatureFlag(
    { getState, patchState }: StateContext<UserAuthStateModel>
  ) {
    return this.organizationRetrievalService.checkFeatureForOrganization(getState().orgDetails.data._id,
      FeatureCheckModel.EMAIL_NOTIFICATIONS).pipe(
      tap(({ isSuccess, value, error }) => {
        if (isSuccess) {
          const state = getState();

          patchState({
            featureFlagsState: {
              ...state.featureFlagsState,
              emailNotificationsFeatureFlagEnabled: value
            }
          });
        } else {
          console.log(error);
        }
      }));
  }

  private setPreviouslyLoggedInState( previouslyLoggedIn: boolean, patchState: ( p: Partial<UserAuthStateModel> )
    => Partial<UserAuthStateModel> ) {
    patchState({
      userPreviouslyLoggedIn: previouslyLoggedIn,
    });

    if ( previouslyLoggedIn ) {
      localStorage.setItem('userPreviouslyLoggedIn', 'true');
    } else {
      localStorage.removeItem('userPreviouslyLoggedIn');
    }
  }

  private checkUserSelectedLanguageOrientation(organization: Organization, languageCode: string) {
    if (!organization) {
      return;
    }
    const dir = organization.languages.find(lang => lang.code === languageCode)?.orientation;
    if (dir) {
      LanguageCodeHelper.setBodyLanguageDir(dir);
      document.body.setAttribute('dir', dir === 'LEFT_TO_RIGHT' ? 'ltr' : 'rtl');
    }
  }

  private updateRootStyles( pointClick: OrganizationTheme ) {
    this.styleElement.textContent = `:root {
      --theme-primary-color: ${pointClick.primaryThemeColor};
      --theme-primary-light-color: ${pointClick.primaryThemeLiteColor};
      --g-color11: ${pointClick.primaryColour};
      --g-color12: ${pointClick.primaryLiteColor};
      --g-color13: ${pointClick.interactiveColor};
      --g-color14: ${pointClick.interactiveLightColor};
      --g-color23: ${pointClick.adminColor};
      --g-color22: ${pointClick.adminLightColor};
     }`;
  }
}
