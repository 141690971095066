/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output, Renderer2, SimpleChanges, OnChanges } from '@angular/core';
import { Framework } from '../../../models/admin/admin.model';

@Component({
  selector: 'ptl-frameworks-list',
  templateUrl: './frameworks-list.component.html',
  styleUrls: ['./frameworks-list.component.scss'],
})
export class FrameworksListComponent implements OnChanges {

  @Input() isLtiModule = false;

  /** The selected framework tags */
  @Input() frameworks: Framework[];

  @Input() selectFrameworks: boolean;

  /** Emits when framework is selected for loading tags. */
  @Output() loadFrameworkTags = new EventEmitter<string>();

  /** Emits when framework is selected. */
  @Output() frameworkSelected = new EventEmitter<Framework>();

  /** Emits when create new framework selected. */
  @Output() frameworkCreate = new EventEmitter<Framework>();

  createNewFrameworkVisible: boolean;

  constructor(private renderer: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // when frameworks load, preselect first availible framework
    if (
      changes.frameworks &&
      !changes.frameworks.previousValue &&
      this.frameworks &&
      this.frameworks.length
    ) {
      if (!this.selectFrameworks) {
        this.loadFrameworkTags.emit(this.frameworks[0]._id ?? this.frameworks[0].uid);
        this.frameworks[0].active = true;
      }
    }
  }

  onLoadFrameworkTags(event, framework: Framework) {
    this.createNewFrameworkVisible = false;

    if (!this.selectFrameworks) {
      this.resetFrameworksActiveState();
      framework.active = !framework.active;
      this.loadFrameworkTags.emit(framework._id ?? framework.uid);
    } else {
      framework.active = !framework.active;
      this.frameworkSelected.emit(framework);
    }
  }

  onOpenCreateFramework() {
    this.createNewFrameworkVisible = true;
    this.resetFrameworksActiveState();
    this.frameworkCreate.emit();
  }

  private resetFrameworksActiveState() {
    this.frameworks = this.frameworks.map(framework => {
      framework.active = false;
      return framework;
    });
  }
}
