/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CheckboxFormContent, Form } from '../../../../models';
import { Observable, takeUntil } from 'rxjs';
import { Select } from '@ngxs/store';
import { ResourceAdminState } from '@app/app/page-modules/resource/store/admin/resource-admin.state';

@Component({
	selector: 'ptl-form-checkbox',
	templateUrl: './form-checkbox.component.html',
	styleUrls: ['../form.component.scss']
})
export class FormCheckboxComponent implements OnInit, OnChanges, OnDestroy {
	private subscriptionEnd$ = new EventEmitter<void>();

	/** Receives the input data object */
	private _form: Form | undefined;
	private _canRemoveItem: boolean | undefined;
	private _position: number | string | undefined;

	@Input()
	set form( value: Form | undefined ) {
		if ( typeof value === 'string' ) {
			this._form = JSON.parse(decodeURIComponent(value));
		} else {
			this._form = value;
		}
	}

	get form() {
		return this._form;
	}

	/** Position in the Form sections */
	@Input()
	set position( value: number | string | undefined ) {
		if ( typeof value === 'string' ) {
			this._position = JSON.parse(decodeURIComponent(value));
		} else {
			this._position = value;
		}
	}

	get position() {
		return this._position;
	}

	@Input()
	set canRemoveItem( value ) {
		if ( typeof value === 'string' ) {
			this._canRemoveItem = JSON.parse(decodeURIComponent(value));
		} else {
			this._canRemoveItem = value;
		}
	}

	get canRemoveItem() {
		return this._canRemoveItem;
	}

	/** Emits content data on saveForm() */
	@Output() formElementAdded = new EventEmitter<Form>();

	/** Emits removing event of this form with index */
	@Output() formElementRemoved = new EventEmitter<void>();

	@ViewChild('checkboxFormHtml') private checkboxFormHtml: ElementRef | undefined;

	@Select(ResourceAdminState.contentStateChanged)
	contentStateChanged$: Observable<boolean>;

	checkboxForm: FormGroup;
	expanded = true;
	hasError = false;
	focused = false;
	saveInProgress = false;

	constructor( private fb: FormBuilder ) {
		this.checkboxForm = this.fb.group({
			title: ['', [Validators.required]]
		});
	}

	ngOnChanges( changes: SimpleChanges ): void {
		if ( this.form?.content ) {
			this.checkboxForm.patchValue({
				title: (this.form.content as CheckboxFormContent)?.title
			});
		}
	}

	ngOnInit(): void {
		if (this.form && !!this.form.uid) {
			const content = (this.form.content as CheckboxFormContent);
			this.expanded = this.form.newAddedForm ?? false;
			this.checkboxForm.patchValue({
				title: content.title
			});
		}
		if (this.form && !this.form.uid && this.checkboxFormHtml) {
			setTimeout(() => {
				(this.checkboxFormHtml?.nativeElement as HTMLElement)?.focus();
			}, 100);
		}

		this.contentStateChanged$.pipe(
      takeUntil(this.subscriptionEnd$)
    ).subscribe(( ) => {
			this.saveInProgress = false;
		});
	}

	ngOnDestroy(): void {
		this.subscriptionEnd$?.emit();
	}

	saveForm(): void {
		this.hasError = false;
		if ( this.checkboxForm.valid ) {
			this.expanded = false;
			this.outputData();
			this.saveInProgress = true;
		} else {
			this.hasError = true;
		}
	}

	expandForm(): void {
		this.expanded = true;
	}

	collapseForm( event: PointerEvent ): void {
		event.stopPropagation();
		this.expanded = false;
	}

	outputData(): void {
		if ( this.form && this.checkboxForm ) {
			const outputData: Form = {
				...this.form,
				newAddedForm: false,
				content: {
					...this.checkboxForm.value,
					type: 'CHECKBOX'
				} as CheckboxFormContent
			};

			this.formElementAdded.emit(outputData);
		}
	}

	removeForm(): void {
		this.formElementRemoved.emit();
	}
}
