<!--
~ Copyright (C) 2019 - Potentially Ltd
~
~ Please see distribution for license.
-->

<div class="form-section" (focusin)="focused=true" (focusout)="focused=false" (click)="expandForm()"
     (keydown.enter)="$event.stopPropagation()">
  <form
    class="form-block"
    #textBoxFormElement
    tabindex="0"
    [class.has-error]="hasError"
    [class.is_focused]="focused"
    [attr.aria-label]="('translations.global.ariaLabels.thisIsTextboxForm' | transloco)"
    [formGroup]="textboxForm"
    (ngSubmit)="saveForm()">
    <div class="form-block-inner">
      <div class="form-block-title-box">
        <input
          type="text"
          class="form-block-input-field no-border full-width no-padding medium bold"
          required
          (input)="hasError = false"
          formControlName="title"
          [placeholder]="'translations.instruction' | transloco">
      </div>
      <ng-container *ngIf="expanded">
        <div class="form-block-help-text-box extra-small"></div>
        <div class="form-section-box small">
          <div class="form-section-box small mb-8">
            <label for="minWordsLimit">{{ 'translations.setWordLimit' | transloco }}</label>
          </div>
          <input
            class="form-block-input-field count-field small"
            type="number"
            placeholder="{{'translations.textForm.placeholders.minWordsLimit' | transloco}}"
            min="0"
            required
            formControlName="minWordsLimit">-
          <input
            class="form-block-input-field count-field small ml-4"
            type="number"
            min="1"
            placeholder="{{'translations.textForm.placeholders.maxWordsLimit' | transloco}}"
            required
            formControlName="wordLimit">
          {{ 'translations.textForm.labels.words' | transloco }}
        </div>
        <div class="form-section-box small error-message" *ngIf="textboxForm.errors?.rangeError">
          {{ 'translations.textForm.errors.rangeError' | transloco }}
        </div>
        <div class="form-section-box small error-message" *ngIf="textboxForm.errors?.minLimitError">
          {{ 'translations.textForm.errors.minLimitError' | transloco }}
        </div>
        <div class="form-section-box errors-placeholder" *ngIf="!textboxForm.errors">
        </div>
        <div class="form-ai-assistant" *ngIf="aiAssistantFeatureEnabled">
          <mat-slide-toggle
            class="mat-slide-toggle enable-ai-toggle mat-primary"
            color="primary"
            formControlName="aiAssistantEnabled"
            [checked]="aiAssistantEnabled"
            (change)="onAiAssistantToggle($event)">
            {{ 'translations.enableAiAssistant' | transloco }}
          </mat-slide-toggle>

          <div *ngIf="aiAssistantEnabled" class="template-type">
            <button
              mat-stroked-button
              class="small"
              [ngClass]="{'active': isDialogOpen}"
              [title]="'translations.global.dataForms.aiAssistant.settings.selectTemplate' | transloco"
              type="button"
              (click)="openAiTemplatesDialog()"
              *ngIf="canRemoveItem"
              [attr.aria-label]="'translations.global.dataForms.aiAssistant.settings.selectTemplate' | transloco">
              {{ 'translations.global.dataForms.aiAssistant.settings.selectTemplate' | transloco }}
            </button>

            <button
              mat-stroked-button
              [ngClass]="{'active': isTemplateSettingsEnabled}"
              class="small"
              [title]="'translations.global.dataForms.aiAssistant.settings.createYourOwn' | transloco"
              type="button"
              (click)="openAiSettings()"
              *ngIf="canRemoveItem"
              [attr.aria-label]="'translations.global.dataForms.aiAssistant.settings.createYourOwn' | transloco">
              {{ 'translations.global.dataForms.aiAssistant.settings.createYourOwn' | transloco }}
            </button>
          </div>
        </div>
        <button mat-icon-button type="button" class="form-edit-icon"
                [attr.aria-label]="('translations.global.ariaLabels.icons.edit' | transloco)">
          <svg class="svg" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
          </svg>
        </button>
        <ptl-form-ai-assistant-settings
          *ngIf="aiAssistantEnabled && isTemplateSettingsEnabled"
          #formAiAssistantSettings
          [assistantId]="aiAssistantSettingsUid"
          [aiAssistantData]="aiAssistantData"
          [updatedAssistantSettings]="updatedAssistantSettings"
          (formChanged)="formChanged($event)"
          (saveAsTemplate)="saveAsTemplate($event)"
          [cardUid]="cardUid">
        </ptl-form-ai-assistant-settings>
      </ng-container>
      <span class="setting-text extra-small"
            *ngIf="!expanded">{{ 'translations.global.button.settings' | transloco }}</span>
    </div>
    <div class="form-block-buttons" *ngIf="expanded">
      <div class="form-controls">
        <button
          mat-flat-button
          class="form-button-remove small f_delete-block"
          type="button"
          color="warn"
          [title]=" 'translations.global.button.delete' | transloco"
          *ngIf="canRemoveItem"
          [attr.aria-label]="('translations.global.ariaLabels.deleteTextBoxForm' | transloco)"
          (click)="removeForm()">
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
          </svg>
          <span>{{ 'translations.global.button.delete' | transloco }}</span>
        </button>
        <button
          mat-flat-button
          class="form-button-save small btn-with-loader f_update-block"
          [title]="!form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco)"
          color="primary"
          type="submit"
          [attr.aria-label]="('translations.global.ariaLabels.addTextBoxForm' | transloco)">
          <svg class="svg svg-icon icon-left" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#check"></use>
          </svg>
          <span>{{ !form?.uid ? ('translations.addToForm' | transloco) : ('translations.global.button.update' | transloco) }}</span>
          <mat-spinner class="is-right" *ngIf="saveInProgress" [diameter]="20"></mat-spinner>
        </button>
        <button
          mat-flat-button
          class="form-button-save small f_cancel-block"
          [title]="'translations.global.button.cancel' | transloco"
          color="accent"
          type="button"
          (click)="collapseForm($event)"
          [attr.aria-label]="('translations.global.ariaLabels.collapseForm' | transloco)">
          <span>{{ 'translations.global.button.cancel' | transloco }}</span>
        </button>
      </div>
    </div>
  </form>
</div>
