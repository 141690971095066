/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ObservableResult } from '../../../../shared/store';
import { Injectable } from '@angular/core';
import { MembersDataService } from './members-data.service';
import {
  MemberInvitationRequest,
  MemberInvitationResponse,
  MemberRecord,
  MemberRecordLearnerPlaylist,
  MemberRecordLearnerPlaylistCardsResponse, MemberRecordPlaylistCardResponse,
  MemberRecordPlaylistsRequest,
  Members, MembersInvitationBulkResponse,
  MembersSearchResponse, UserSearch, ValidateEmailsFromCsvFileResponse
} from '../../../../shared/models/admin/members.model';
import { delay } from 'rxjs/operators';
import { Page } from '../../../../shared/models/page';
import { MemberReview } from '../../../../shared/models';
import { StatsData, StatsSummary } from '../../../../shared/models/analytics/analytics.model';
import {
  MembersBooleanSearchRequest,
  ReviewsSearchRequest
} from '../../../../shared/models/admin/boolean-filters.model';
import { MembersMessageRequest } from '../../../../shared/models/messages/messages.model';
import {
  AssessmentMemberMetrics, AssessmentMemberMetricsSummary
} from '@app/app/page-modules/resource/components/editor/analytics/assessment-analytics.model';

@Injectable()

export class MockMembersDataService implements MembersDataService {

  private members = [
    {
      _id: '0',
      createdAt: new Date(),
      email: '',
      externalId: '0',
      lastActiveOn: new Date(),
      firstName: 'firstName',
      lastName: 'lastName',
      organizationId: '0',
      password: '1234564',
      roles: ['Workspace admin'],
      status: '',
    },
  ] as Members[];

  private memberRecord = {
    about: {
      email: 'some@email.com',
      firstName: 'firstName',
      lastName: 'lastName',
      joinedDate: new Date(),
      folio: 'folio',
    },
    thumbnail: '',
    lastActiveOn: new Date(),
    momentsLogged: 10,
    badgesEarned: 10,
    playlistsCompleted: 10,
    roles: [{
      type: 'LEARNER',
      _id: '',
      name: [],
    }],
    groups: [{
      id: '123456',
      name: 'Group 1',
      type: 'STANDARD'
    }],
  } as MemberRecord;

  private membersSearchResponse = {
    members: [...this.members],
    page: 1,
    pageSize: 1,
    total: 1,
  };

  private invitationsResponse: Page<MemberInvitationResponse> = {
    hasContent: false,
    pageSize: 10,
    totalNumberOfElement: 0,
    currentPageNumber: 0,
    numberOfPages: 1,
    content: [],
  };

  addMembersToGroup(userGroupUid: string, request: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  removeMembersFromGroup(userGroupUid: string, request: string[]): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  loadMemberRecord(userUid?: string, playlistUid?: string): ObservableResult<MemberRecord> {
    return ObservableResult.ofSuccess({ ...this.memberRecord }).pipe(delay(1500));
  }

  getLearnerPlaylistCards(learnerPlaylistId: string): ObservableResult<MemberRecordLearnerPlaylistCardsResponse> {
    return ObservableResult.ofSuccess();
  }

  getPlaylistLearnerCardsByUserId(playlistUid: string, userUid: string): ObservableResult<MemberRecordPlaylistCardResponse[]> {
    return ObservableResult.ofSuccess();
  }

  getMemberPlaylistsByType(
    userUid: string,
    request: MemberRecordPlaylistsRequest,
  ): ObservableResult<Page<MemberRecordLearnerPlaylist>> {
    return ObservableResult.ofSuccess();
  }

  searchNonAdminUsers(page: number, pageSize: number, searchString: string): ObservableResult<Page<UserSearch>> {
    return ObservableResult.ofSuccess();
  }

  searchUsers(page: number, pageSize: number, searchString: string, organization?: string): ObservableResult<Page<UserSearch>> {
    return ObservableResult.ofSuccess();
  }

  getAllInvitationsList(page: number, pageSize: number, email?: string): ObservableResult<Page<MemberInvitationResponse>> {
    return ObservableResult.ofSuccess(this.invitationsResponse);
  }

  getAcceptedInvitationsList(page: number, pageSize: number): ObservableResult<Page<MemberInvitationResponse>> {
    return ObservableResult.ofSuccess(this.invitationsResponse);
  }

  getNotAcceptedInvitationsList(page: number, pageSize: number): ObservableResult<Page<MemberInvitationResponse>> {
    return ObservableResult.ofSuccess(this.invitationsResponse);
  }

  inviteNewMembers(request: MemberInvitationRequest): ObservableResult<MembersInvitationBulkResponse> {
    return ObservableResult.ofSuccess();
  }

  importNewMembers(csvFile: File, groupIdsQueryParam: string, playlistIdsQueryParam: string):
    ObservableResult<MembersInvitationBulkResponse> {
    return ObservableResult.ofSuccess();
  }

  resendInvitation(invitationId: string): ObservableResult<void> {
    return ObservableResult.ofSuccess();
  }

  validateUsersFromCsvFile(csvFile: File, playlistUid: string): ObservableResult<ValidateEmailsFromCsvFileResponse> {
    return ObservableResult.ofSuccess();
  }

  getLearnerCard(playlistUid: string, userUid: string, cardUid: string): ObservableResult<MemberRecordPlaylistCardResponse> {
    return ObservableResult.ofSuccess();
  }

  getLearnerCardForReviewer(userUid: string, cardUid: string): ObservableResult<MemberRecordPlaylistCardResponse> {
    return ObservableResult.ofSuccess();
  }

  getStatisticsNewMembers(from: string, to: string): ObservableResult<StatsData[]> {
    return ObservableResult.ofSuccess([]);
  }

  getStatisticsActiveMembers(from: string, to: string): ObservableResult<StatsData[]> {
    return ObservableResult.ofSuccess([]);
  }

  getStatisticsSummary(): ObservableResult<StatsSummary> {
    return ObservableResult.ofSuccess({
      totalMembers: 10,
      learners: 10,
      instructors: 10,
      admins: 10,
      publishedPlaylists: 10,
      startedPlaylists: 10,
      completedPlaylists: 10,
      createdBadges: 10,
      issuedBadges: 10,
    });
  }

  searchMembers(request: MembersBooleanSearchRequest, page: number, size: number): ObservableResult<MembersSearchResponse> {
    return ObservableResult.ofSuccess(this.membersSearchResponse);
  }

  searchCardMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    cardUid: string,
    page: number,
    size: number
    ): ObservableResult<MembersSearchResponse> {
    return ObservableResult.ofSuccess(this.membersSearchResponse);
  }

  searchPlaylistMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    page: number,
    size: number
  ): ObservableResult<MembersSearchResponse> {
    return ObservableResult.ofSuccess(this.membersSearchResponse);
  }

  booleanExportMembers(
    request: MembersBooleanSearchRequest,
  ): ObservableResult<string> {
    return ObservableResult.ofSuccess()
  }

  booleanExportPlaylistMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string
  ): ObservableResult<string> {
    return ObservableResult.ofSuccess()
  }

  booleanExportCardMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    cardUid: string
  ): ObservableResult<string> {
    return ObservableResult.ofSuccess()
  }

  booleanExportEventCardMembers(
    request: MembersBooleanSearchRequest,
    playlistUid: string,
    eventUid: string
  ): ObservableResult<string> {
    return ObservableResult.ofSuccess()
  }

  sendMessageToMembers(request: MembersMessageRequest): ObservableResult<void> {
    return ObservableResult.ofSuccess()
  }

  sendMessageToPlaylistMembers(request: MembersMessageRequest, playlistUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess()
  }

  sendMessageToCardMembers(request: MembersMessageRequest, playlistUid: string, cardUid: string): ObservableResult<void> {
    return ObservableResult.ofSuccess()
  }

  searchPlaylistReviews(
    request: ReviewsSearchRequest,
    playlistUid: string,
    page: number,
    size: number
  ): ObservableResult<Page<MemberReview>> {
    return ObservableResult.ofSuccess()
  }

  searchCardReviews(
    request: ReviewsSearchRequest,
    cardUid: string,
    page: number,
    size: number
  ): ObservableResult<Page<MemberReview>> {
    return ObservableResult.ofSuccess()
  }

  getAssessmentMetrics(
    playlistUid: string,
    cardUid: string,
    request: MembersBooleanSearchRequest
  ): ObservableResult<AssessmentMemberMetrics> {
    return ObservableResult.ofSuccess()
  }

  getAssessmentMetricsSummary(
    playlistUid: string,
    cardUid: string,
    request: MembersBooleanSearchRequest
  ): ObservableResult<AssessmentMemberMetricsSummary> {
    return ObservableResult.ofSuccess()
  }
}
