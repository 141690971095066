/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserAuthState } from '../../store/user-auth.state';
import { Select } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from '../../../shared/models/organization';
import { ACCOUNT_DATA_SERVICE, AccountDataService } from '../../../page-modules/account/services/account-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { FormsHelper } from '../../../shared/helpers/forms-helper';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { InvisibleReCaptchaComponent, ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { environment } from '../../../../environments/environment';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  templateUrl: './email-recovery.component.html',
  styleUrls: ['./email-recovery.component.scss'],
})
export class EmailRecoveryComponent implements OnInit {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  emailForm: FormGroup;
  reCaptchaElement = null;
  reCaptchaSiteKeyV2 = environment.reCaptchaSiteKeyV2;
  showMaintenanceOverlay: boolean;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    @Inject(ACCOUNT_DATA_SERVICE) private accountService: AccountDataService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translationService: TranslationService,
    private captchaService: ReCaptchaV3Service,
    private scriptService: ScriptService,
    private cdr: ChangeDetectorRef,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
  }

  ngOnInit() {
    this.emailForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      newEmail: ['', Validators.compose([Validators.required, Validators.email])],
    }, {
      validator: FormsHelper.isEqual('email', 'newEmail'),
    });
  }

  updateEmail() {
    if (this.emailForm.valid) {
      this.captchaElem.execute();
    }
  }

  resetCaptcha(): void {
    this.cdr.detectChanges();
  }

  proceedIfValid(captchaResponseV2: string) {
    this.proceedEmailRecovery(captchaResponseV2);
    setTimeout(() => {
      this.scriptService.cleanup();
      this.captchaElem.resetCaptcha();
    }, 1500);
  }

  private proceedEmailRecovery(captchaResponseV2: string) {
    this.scriptService.cleanup();
    const newEmail = this.emailForm.get('email').value?.trim();
    const token = this.activatedRoute.snapshot.queryParamMap.get('token');
    this.captchaService.execute(environment.reCaptchaSiteKeyV3, 'emailRecovery', (captchaResponseV3) => {
      this.accountService.updateUserEmail(newEmail, token, captchaResponseV3, captchaResponseV2)
        .subscribe(({ isSuccess, error }) => {
          if (isSuccess) {
            RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
            SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'emailUpdated');
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        });
    });
  }

}
