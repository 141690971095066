<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div
  class="editor-content-container f_editor-content-container"
  [ngClass]="{'empty' : !content?.length}"
  (dragover)="onFileDrag($event)"
  (drop)="onFileDrop($event, content?.length ? content.length : 0)">
  <div
    class="editor-item-empty-placeholder"
    *ngIf="content?.length && content[0].type !== 'PARAGRAPH'"
    (dragover)="onFileDrag($event, true)"
    (drop)="onFileDrop($event, 0)"
    (click)="onEmptyPlaceholderFocus(-1)"
    (keydown.enter)="onEmptyPlaceholderFocus(-1)">
  </div>

  <ng-container *ngFor="let contentItem of content; trackBy: trackByFn; index as idx">
    <ptl-media-embeds
      class="editor-element f_editor-element position-{{contentItem.position}}"
      *ngIf="isContentUrl(contentItem)"
      [contentItem]="contentItem"
      [canRemoveItem]="canRemoveItem"
      (removeMediaContent)="onContentRemoval(idx, contentItem)"
      (updateMediaPosition)="onUpdateMediaPosition(idx, $event, contentItem)"
      (mediaContentReplaced)="onReplaceMedia(idx, $event, contentItem)"
      (regularLinkReplaced)="onReplaceRegularLink($event)"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-media-embeds>

    <ptl-embed-code
      class="editor-element f_editor-element position-{{contentItem.position}}"
      *ngIf="contentItem.type === 'EMBED_CODE'"
      [contentItem]="contentItem"
      (updateEmbedPosition)="onUpdateMediaPosition(idx, $event, contentItem)"
      (removeEmbedContent)="onContentRemoval(idx, contentItem)"
      (updateEmbedContent)="onContentUpdate(idx, $event, contentItem)">
    </ptl-embed-code>

    <ptl-table
      class="editor-element f_editor-element editor-element-TABLE"
      *ngIf="contentItem.type === 'TABLE'"
      (tinymceEditorUpdate)="onContentUpdate(idx, $event, contentItem)"
      (removeEmbedContent)="onContentRemoval(idx, contentItem)"
      [contentData]="contentItem">
    </ptl-table>

    <ptl-media-uploads
      class="editor-element f_editor-element position-{{contentItem.position}}"
      *ngIf="isContentUpload(contentItem)"
      [mediaUploadData]="contentItem"
      [canRemoveItem]="canRemoveItem"
      [markInMemoryUploadsAsNotUploaded]="markInMemoryUploadsAsNotUploaded"
      [streamVideoBytePlaceholder]="streamVideoBytePlaceholder"
      (removeMediaContent)="onContentRemoval(idx, contentItem)"
      (updateMediaPosition)="onUpdateMediaPosition(idx, $event, contentItem)"
      (updateMediaCaption)="onUpdateMediaCaption(idx, $event, contentItem)"
      (mediaContentReplaced)="onReplaceMedia(idx, $event, contentItem)"
      (regularLinkReplaced)="onReplaceRegularLink($event)"
      (updateMediaHyperlink)="onUpdateMediaHyperlink(idx, $event, contentItem)"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-media-uploads>

    <ptl-external-content-summary
      class="editor-element f_editor-element position-{{contentItem.position}}"
      *ngIf="contentItem.type === 'EXTERNAL_CONTENT_SUMMARY'"
      [content]="contentItem"
      [canRemoveItem]="canRemoveItem"
      (removeContent)="onContentRemoval(idx, contentItem)"
      (mediaContentReplaced)="onReplaceMedia(idx, $event, contentItem)"
      (regularLinkReplaced)="onReplaceRegularLink($event)"
      (updateMediaPosition)="onUpdateMediaPosition(idx, $event, contentItem)"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)"></ptl-external-content-summary>

    <ptl-form
      class="editor-element f_editor-element"
      [ngClass]="{'editor-element__collector': contentItem.content?.type === 'COLLECTOR'}"
      *ngIf="contentItem.type === 'FORM'"
      [form]="contentItem"
      [canRemoveItem]="canRemoveItem"
      (removeFormContent)="onContentRemoval(idx, contentItem)"
      (updateFormContent)="onContentUpdate(idx, $event, contentItem)"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-form>

    <ptl-tinymce-editor
      class="editor-element f_editor-element"
      [class.first-medium-editor]="idx === 0"
      *ngIf="contentItem.type === 'PARAGRAPH'"
      [placeholder]="placeholder"
      [contentData]="contentItem"
      [firstEditor]="idx === 0"
      [lastEditor]="idx === content.length - 1"
      [editorOptions]="editorOptions"
      (tinymceEditorUpdate)="onContentUpdate(idx, $event, contentItem)"
      (removeEditorContent)="onContentRemoval(idx, contentItem)"
      (toolbarUpdate)="onToolbarUpdate($event, idx)"
      (inputFocus)="onInputFocus()"
      (inputBlur)="onInputBlur()"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-tinymce-editor>

    <ptl-medium-separator
      class="editor-element f_editor-element"
      [canRemoveItem]="canRemoveItem"
      *ngIf="contentItem.type === 'SEPARATOR'"
      (removeSeparator)="onContentRemoval(idx, contentItem)"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-medium-separator>

    <ptl-chart
      class="editor-element f_editor-element"
      *ngIf="contentItem.type === 'CHART'"
      [charContent]="contentItem"
      [canRemoveItem]="canRemoveItem"
      [chartSectionUid]="chartSectionUid"
      (removeChartContent)="onContentRemoval(idx, contentItem)"
      (updateChartContent)="onContentUpdate(idx, $event, contentItem)"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-chart>

    <ptl-pi-trait-chart
      class="editor-element f_editor-element"
      *ngIf="contentItem.type === 'TRAITS_CHART'"
      [showStaticData]="true"
      [content]="contentItem"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-pi-trait-chart>

    <ptl-pi-team-role-chart
      class="editor-element f_editor-element"
      *ngIf="contentItem.type === 'TEAM_ROLES_CHART'"
      [showStaticData]="true"
      [content]="contentItem"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-pi-team-role-chart>

    <ptl-pi-big-six-chart
      class="editor-element f_editor-element"
      *ngIf="contentItem.type === 'BIG_SIX_CHART'"
      [showStaticData]="true"
      [content]="contentItem"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-pi-big-six-chart>

    <ptl-pi-jungian-chart
      class="editor-element f_editor-element"
      *ngIf="contentItem.type === 'JUNGIAN_TYPES_CHART'"
      [showStaticData]="true"
      [content]="contentItem"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-pi-jungian-chart>

    <ptl-pi-spectrum-chart
      class="editor-element f_editor-element"
      *ngIf="contentItem.type === 'SPECTRUM_CHART'"
      [showStaticData]="true"
      [content]="contentItem"
      (dragover)="onFileDrag($event)"
      (drop)="onFileDrop($event, idx)">
    </ptl-pi-spectrum-chart>

    <ptl-form-booking
      [id]="contentItem.uid"
      *ngIf="contentItem.content?.type === 'BOOKING'"
      [canRemoveItem]="canRemoveItem"
      [form]="contentItem"
      (formElementAdded)="onContentUpdate(idx, $event, contentItem)"
      (formElementRemoved)="onContentRemoval(idx, contentItem)">
    </ptl-form-booking>

    <div class="editor-item-empty-placeholder"
         *ngIf="isEmptyPlaceholderShown(contentItem, idx)"
         [ngStyle]="{ 'pointer-events': isEmptyPlaceholderEnabled(idx) ? '' : 'none' }"
         (dragover)="onFileDrag($event, true)"
         (drop)="onFileDrop($event, idx + 1)"
         (keydown.enter)="onEmptyPlaceholderFocus(idx)"
         (click)="onEmptyPlaceholderFocus(idx)">
    </div>
  </ng-container>
</div>
