<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->


<div class="content-media">
  <div class="regular-link-replace" *ngIf="showReplaceLink">
    <ptl-link-input
      [showLinkField]="true"
      (mediaContentAdded)="onMediaContentAdded($event)"
      (regularLinkAdded)="onRegularLinkAdded($event)">
    </ptl-link-input>
    <button mat-icon-button (click)="showReplaceLink = false"><i class="material-icons" aria-hidden="true">clear</i>
    </button>
  </div>

  <div
      #media
      class="content-media-html f_content-media-html content-media-html--position-{{position}} f_content-media-html--position-{{position}}"
      [ngClass]="{
      'not-image' : !isUploadedImage,
      'unset-postioning': isInResourceModule ? isNewEditorEnabled && position !== 'CENTER' && position !== 'FULL_WIDTH' : null
      }"
      tabindex="0"
      (click)="showMediaToolbar()"
      (keydown.enter)="showMediaToolbar()"
      (blur)="hideMediaToolbar($event, media)"
      (window:resize)="hideMediaToolbar()">

    <ng-content></ng-content>

    <div
      class="content-media-toolbar"
      *ngIf="showToolbar"
      [ngStyle]="{top: (toolbarPosition.top / 16) + 'rem'}">
      <div class="content-media-toolbar-inner">
        <ul class="content-media-toolbar-items">
          <ng-container *ngIf="!disableMediaPosition">
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'LEFT'"
              tabindex="0"
              data-id="LEFT"
              [attr.aria-label]="('translations.global.ariaLabels.alignLeft' | transloco)"
              (click)="setMediaPosition('LEFT')"
              (keydown.enter)="setMediaPosition('LEFT')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-left"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignLeft' | transloco }}</span>
            </li>
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'RIGHT'"
              tabindex="0"
              data-id="RIGHT"
              [attr.aria-label]="('translations.global.ariaLabels.alignRight' | transloco)"
              (click)="setMediaPosition('RIGHT')"
              (keydown.enter)="setMediaPosition('RIGHT')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-right"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignRight' | transloco }}</span>
            </li>
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'CENTER'"
              tabindex="0"
              data-id="CENTER"
              [attr.aria-label]="('translations.global.ariaLabels.alignCenter' | transloco)"
              (click)="setMediaPosition('CENTER')"
              (keydown.enter)="setMediaPosition('CENTER')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-center"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignCenter' | transloco }}</span>
            </li>
            <li
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'FULL_WIDTH'"
              tabindex="0"
              data-id="FULL_WIDTH"
              [attr.aria-label]="('translations.global.ariaLabels.fullWidthAlign' | transloco)"
              (click)="setMediaPosition('FULL_WIDTH')"
              (keydown.enter)="setMediaPosition('FULL_WIDTH')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#align-full-width"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.alignFullWidth' | transloco }}</span>
            </li>
            <li
              *ngIf="isUploadedImage"
              class="content-media-toolbar-item"
              [class.content-media-toolbar-item-active]="position === 'THUMBNAIL'"
              tabindex="0"
              data-id="THUMBNAIL"
              (click)="setMediaPosition('THUMBNAIL')"
              (keydown.enter)="setMediaPosition('THUMBNAIL')">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#small-image"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.thumbnail' | transloco }}</span>
            </li>
          </ng-container>
          <ng-container
            *ngIf="isUploadedImage || isUploadedDocument || isUploadedVideo || isUploadedAudio || isExternalContent">
            <li
              class="content-media-toolbar-item"
              [attr.aria-label]="('translations.global.ariaLabels.replace' | transloco)"
              tabindex="0"
              data-id="REPLACE"
              (click)="replaceMedia()"
              (keydown.enter)="replaceMedia()">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#edit"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.replace' | transloco }}</span>
            </li>
          </ng-container>
          <ng-container *ngIf="isUploadedImage || isUploadedDocument || isUploadedVideo || isUploadedAudio">

            <li
              class="content-media-toolbar-item"
              [attr.aria-label]="('translations.global.ariaLabels.addCaption' | transloco)"
              tabindex="0"
              data-id="CAPTION"
              (click)="addCaption()"
              (keydown.enter)="addCaption()">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#caption"></use>
              </svg>
              <span
                class="label small">{{ 'translations.editor.toolbarNew.floating.option.caption' | transloco }}</span>
            </li>
            <li
              *ngIf="!isUploadedVideo && !isUploadedAudio"
              class="content-media-toolbar-item"
              [attr.aria-label]="('translations.global.ariaLabels.addLink' | transloco)"
              tabindex="0"
              data-id="LINK"
              (click)="addLink()"
              (keydown.enter)="addLink()">
              <svg class="svg" aria-hidden="true">
                <use ogSvgUnify="assets/sprite/sprite.svg#link"></use>
              </svg>
              <span class="label small">{{ 'translations.editor.toolbarNew.floating.option.link' | transloco }}</span>
            </li>

          </ng-container>

          <li
            *ngIf="canRemoveItem"
            class="content-media-toolbar-item f_delete-block"
            [attr.aria-label]="('translations.global.ariaLabels.removeItem' | transloco)"
            tabindex="0"
            data-id="DELETE"
            (click)="removeMedia()"
            (keydown.enter)="removeMedia()">
            <svg class="svg" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
            </svg>
            <span class="label small">{{ 'translations.editor.toolbarNew.floating.option.delete' | transloco }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="showAddCaption">
      <div
        class="content-media-position-toolbar"
        [ngStyle]="{top: (toolbarPosition.top / 16) + 'rem', left: (toolbarPosition.left / 16) + 'rem'}">
        <form [formGroup]="captionControl" (submit)="setCaption()">
          <div class="top">
            <mat-form-field class="no-padding">
              <label for="contentInput" class="input-text">{{ 'translations.shortDescription' | transloco }}</label>
              <input
                id='contentInput'
                matInput
                type="text"
                class="input-text"
                [formControl]="captionControl"
                (window:resize)="showAddCaption = false">
            </mat-form-field>
          </div>
          <div class="bottom">
            <button
              data-id="CAPTION"
              mat-icon-button type="submit" class="input-text"
              [attr.aria-label]="('translations.global.ariaLabels.addDescription' | transloco)">
              <mat-icon aria-hidden="true">check</mat-icon>
            </button>
            <button mat-icon-button class="input-text" type="button"
                    [attr.aria-label]="('translations.global.ariaLabels.close' | transloco)" (click)="close($event)">
              <mat-icon aria-hidden="true">clear</mat-icon>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div *ngIf="showAddLink">
      <div
        class="content-media-position-toolbar"
        [ngStyle]="{top: (toolbarPosition.top / 16) + 'rem', left: (toolbarPosition.left / 16) + 'rem'}"
        (window:resize)="showAddLink = false">
        <form class="formContainer" [formGroup]="hyperlinkForm" (submit)="setHyperLink()">
          <div class="top">
            <mat-form-field class="no-padding">
              <label for="linkInput"
                     class="input-text">{{ 'translations.mediaContent.label.url' | transloco }}</label>
              <input
                id='linkInput'
                matInput
                type="text"
                class="input-text"
                formControlName="url">
            </mat-form-field>
          </div>
          <div class="bottom">
            <mat-checkbox
              id="newTab"
              class="input-text"
              formControlName="openUrlInTab"
              labelPosition="before"
              [aria-label]="'translations.mediaContent.label.openInNewTab' | transloco"
              color="primary">
              <label for="newTab">{{ 'translations.mediaContent.label.openInNewTab' | transloco }}</label>
            </mat-checkbox>
            <button mat-icon-button type="submit" class="input-text">
              <mat-icon aria-hidden="true">check</mat-icon>
            </button>
            <button mat-icon-button class="input-text" type="button" (click)="close($event)">
              <mat-icon aria-hidden="true">clear</mat-icon>
            </button>
          </div>
        </form>
      </div>
    </div>

    <div class="file-upload-content" *ngIf="showUpload">
      <ptl-file-upload
        [replace]="true"
        [uploadType]="replacedFileType"
        [doNotShowButton]="true"
        (mediaContentAdded)="onMediaContentAdded($event)">
      </ptl-file-upload>
    </div>
  </div>
</div>
