/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  EditorContent,
  EditorContentRemovalEvent,
  EditorContentUpdateEvent,
  MediumEditorToolbarEvent
} from '../../models';
import { EditorContentViewComponent } from '../editor-content-view/editor-content-view.component';
import { EditorToolbarComponent } from '../editor-toolbar/editor-toolbar.component';
import { LanguageCodeHelper } from '../../../shared/helpers/language-code-helper';

@Component({
  selector: 'ptl-editor',
  templateUrl: './editor-main.component.html',
  styleUrls: ['./editor-main.component.scss'],
})
export class EditorMainComponent implements AfterViewInit {

  /** Receives the content */
  @Input() content: EditorContent[];
  /** Receives the placeholder text */
  @Input() placeholder = 'Add your text';
  /** edit editor options */
  @Input() editorOptions;

  @Input() disableAutoFocus = false;
  /** Enables url toolbar button */
  @Input() link: boolean;
  /** Enables upload toolbar button */
  @Input() upload: boolean;
  /** Enables embed code toolbar button */
  @Input() embed: boolean;
  /** Enables form toolbar button */
  @Input() form: boolean;
  /** Enables text form toolbar button */
  @Input() textFormOnly: boolean;
  /** Enables separator toolbar button */
  @Input() separator: boolean;
  /** Enables quiz toolbar button */
  @Input() quiz: boolean;
  /** Enables chart toolbar button */
  @Input() chart: boolean;
  /** Enables insert learnerCollectorSummary toolbar button */
  @Input() collector: boolean;
  /** Treat in-memory uploads as not uploaded */
  @Input() markInMemoryUploadsAsNotUploaded: boolean;
  /** Additional floating element id */
  @Input() floatingElementId: string;
  /** chart section uid */
  @Input() chartSectionUid: string;
  /** check if should show toolbar or not */
  @Input() showToolbar: boolean;

  @Input() emptyContentDisable: boolean;

  @Input() toolbarEnabled = true;

  @Input() canRemoveItem = true;

  @Input() streamVideoBytePlaceholder = false;

  /** Emits on content update. */
  @Output() contentUpdate = new EventEmitter<EditorContentUpdateEvent>();
  /** Emits on content removal. */
  @Output() contentRemoval = new EventEmitter<EditorContentRemovalEvent>();
  /** Emits data on new content from child components */
  @Output() contentCreated = new EventEmitter<EditorContent>();
  /** Outputs the various click and change events */
  @Output() inputFocus = new EventEmitter<void>();
  /** Outputs the various click and change events */
  @Output() inputBlur = new EventEmitter<void>();

  @ViewChild('editorToolbarNew', {static: false}) editorToolbarNew: EditorToolbarComponent;
  @ViewChild('editorContentView') editorContentView: EditorContentViewComponent;

  toolbarButtonVisible = false;
  toolbarOpen = false;
  toolbarButtonOffsetTop = 0;
  toolbarButtonOffsetLeft = 0;
  languageDirection = LanguageCodeHelper.getBodyLanguageDir();
  hasFloatingLinkBox = false;

  private bubbleInsertElement: Element;
  private bubbleInsertContentUid: string;
  private toolbarLeftOffsetDelta = -27;
  private toolbarButtonIndex: number;

  constructor(private elem: ElementRef) {

    this.toolbarButtonOffsetLeft = this.toolbarLeftOffsetDelta;
    if (window.innerWidth <= 900) {
      this.toolbarLeftOffsetDelta = -27;
    }
  }

  ngAfterViewInit() {
    if (this.showToolbar) {
      this.toolbarButtonVisible = true;
    }
  }

  onContentRemoval(contentRemovalEvent: EditorContentRemovalEvent) {
    this.contentRemoval.emit(contentRemovalEvent);
  }

  onContentUpdate(contentUpdateEvent: EditorContentUpdateEvent) {
    this.contentUpdate.emit(contentUpdateEvent);
  }

  onContentCreation(contentCreated: EditorContent) {
    if (contentCreated.index === undefined) {
      contentCreated.index = this.toolbarButtonIndex;
    }
    if (this.bubbleInsertElement) {
      this.editorContentView.bubbleInsertSplit(this.bubbleInsertElement, this.bubbleInsertContentUid);
    }
    this.contentCreated.emit(contentCreated);
    this.toolbarButtonVisible = false;
    this.toolbarOpen = false;
  }

  onContentUpload(contentUploaded: EditorContent) {
    this.contentCreated.emit(contentUploaded);
    this.toolbarButtonVisible = false;
    this.toolbarOpen = false;
  }

  onRegularLinkInsertion(link: string) {
    this.editorContentView.insertLinkBeforeBreakpoint(link);
    this.toolbarButtonVisible = false;
    this.toolbarOpen = false;
  }

  onToolbarUpdate(event: MediumEditorToolbarEvent) {
    // fire toolbar functionality only if at least one toolbar button present
    if (this.link || this.upload || this.form || this.separator || this.chart || this.quiz) {
      this.toolbarButtonVisible = event.type === 'SHOW_TOOLBAR';
      this.toolbarOpen = false;
      if (event.type === 'SHOW_TOOLBAR') {
        this.bubbleInsertElement = event.element;
        this.bubbleInsertContentUid = event.contentUid;
        this.toolbarButtonOffsetTop = event.offsetTop;
        this.toolbarButtonIndex = 0;
        if (this.toolbarButtonOffsetTop !== 0) {
          const contentItems = this.elem.nativeElement.querySelectorAll('.f_editor-element');
          this.toolbarButtonOffsetLeft = this.toolbarLeftOffsetDelta;

          for (const contentItem of contentItems) {
            const leftPositionedElement = contentItem.querySelector(
              '.content-media__html--position-LEFT, .content-media__html--position-THUMBNAIL,' +
              '.content-media-html--position-LEFT, .content-media-html--position-THUMBNAIL');
            if (leftPositionedElement) {
              const contentOffsetTop = leftPositionedElement.offsetTop;
              const contentHeight = leftPositionedElement.clientHeight;
              const topRadius = 20;
              const bottomRadius = 10;
              const buttonPadding = 20;
              if (this.toolbarButtonOffsetTop >= contentOffsetTop - topRadius &&
                this.toolbarButtonOffsetTop < contentOffsetTop + contentHeight + bottomRadius) {
                this.toolbarButtonOffsetLeft = leftPositionedElement.clientWidth + buttonPadding + this.toolbarLeftOffsetDelta;
              }
            }

            if (this.toolbarButtonOffsetTop > contentItem.offsetTop) {
              ++this.toolbarButtonIndex;
            }
          }
        }
        const floatingElement = this.floatingElementId ? document.getElementById(this.floatingElementId) : undefined;

        if (floatingElement) {
          this.toolbarButtonOffsetLeft = this.toolbarLeftOffsetDelta;
          const contentOffsetTop = floatingElement.offsetTop;
          const contentHeight = floatingElement.clientHeight;
          const topRadius = 20;
          const bottomRadius = 10;
          const buttonPadding = 50;

          if (this.toolbarButtonOffsetTop >= contentOffsetTop - topRadius &&
            this.toolbarButtonOffsetTop < contentOffsetTop + contentHeight + bottomRadius) {
            this.toolbarButtonOffsetLeft = floatingElement.clientWidth + buttonPadding + this.toolbarLeftOffsetDelta - 20;
          }
        }

        if (this.editorToolbarNew) {
          setTimeout(() => this.editorToolbarNew?.setToolbarHidden());
        }
      } else {
        this.bubbleInsertElement = undefined;
        this.bubbleInsertContentUid = undefined;
        this.toolbarButtonIndex = undefined;
      }
    }
  }

  onInputFocus() {
    this.inputFocus.emit();
  }

  onInputBlur() {
    this.inputBlur.emit();
  }
}
