/*
 * Copyright (C) 2020 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output} from '@angular/core';
import { MediaType, MediaUploadData } from '../../../../../../shared/models';

@Component({
  selector: 'ptl-uploads-placeholder',
  templateUrl: './uploads-placeholder.component.html',
  styleUrls: ['./uploads-placeholder.component.scss'],
})
export class UploadsPlaceholderComponent {
  @Input() dataLoaded = false;
  @Input() mediaType: MediaType | undefined;
  @Input() description: string | undefined;
  @Input() content: MediaUploadData;
	@Output() contentUploadProgressEmitter: EventEmitter<number> = new EventEmitter<number>();

  contentUploadProgress(progress: number): void {
    this.contentUploadProgressEmitter.emit(progress);
  }
}
