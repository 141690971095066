<!--
  ~ Copyright (C) 2019 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<form [formGroup]="settingsForm">

  <div class="pass-criteria-box firts">
    <div class="title small bold">{{ 'translations.quiz.settings.title.whenQuizCompleted' | transloco }}</div>

    <mat-radio-group class="pass-criteria-options-list">
      <mat-radio-button
        class="pass-criteria-option"
        [checked]="settings?.submissionCriteria?.mustPass"
        (change)="onChangeCompletionMustPass(true)"
        value="true"
        color="primary"
        name="submissionCriteriaMustPass"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.option.whenPassQuiz' | transloco }}</span>
      </mat-radio-button>

      <mat-radio-button
        class="pass-criteria-option"
        value="false"
        [checked]="!settings?.submissionCriteria?.mustPass"
        (change)="onChangeCompletionMustPass(false)"
        color="primary"
        name="submissionCriteriaMustPass"
        tabIndex="0">
        <span
          class="small">{{ 'translations.quiz.settings.option.whenCompleteQuizRegardlessResult' | transloco }}</span>
      </mat-radio-button>

    </mat-radio-group>
  </div>

  <div class="pass-criteria-box">
    <div class="title small bold">{{ 'translations.quiz.settings.title.whenSomeonePassQuiz' | transloco }}</div>

    <mat-radio-group class="pass-criteria-options-list">
      <mat-radio-button
        class="pass-criteria-option"
        [checked]="passCriteriaOption === 'ALL_CORRECT'"
        (change)="onPassCountChange('ALL_CORRECT')"
        value="true"
        color="primary"
        name="passCount"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.option.whenAnswerAllCorrect' | transloco }}</span>
      </mat-radio-button>

      <mat-radio-button
        class="pass-criteria-option"
        value="false"
        [checked]="passCriteriaOption === 'PARTOF_CORRECT'"
        (change)="onPassCountChange('PARTOF_CORRECT')"
        color="primary"
        name="passCount"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.option.whenAnswerPartCorrect' | transloco }}</span>
      </mat-radio-button>

      <ptl-pass-criteria-count
        class="pass-criteria-popup"
        *ngIf="showPassCriteriaOption"
        [value]="passCriteria.value"
        [questionsCount]="questionsCount"
        (selectedValue)="onCriteriaSelected($event)">
      </ptl-pass-criteria-count>

    </mat-radio-group>
  </div>

  <div class="pass-criteria-box">
    <div class="title small bold">{{ 'translations.quiz.settings.retake.label' | transloco }}</div>

    <mat-radio-group formControlName="retakeCriteria" class="pass-criteria-options-list">
      <mat-radio-button
        class="pass-criteria-option"
        value="UNTIL_PASSED"
        color="primary"
        name="passCriteriaRetake"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.retake.options.untilPassed' | transloco }}</span>
      </mat-radio-button>

      <mat-radio-button
        class="pass-criteria-option"
        value="UNLIMITED"
        color="primary"
        name="passCriteriaRetake"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.retake.options.unlimited' | transloco }}</span>
      </mat-radio-button>

      <mat-radio-button
        class="pass-criteria-option"
        value="ONCE"
        color="primary"
        name="passCriteriaRetake"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.retake.options.once' | transloco }}</span>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="pass-criteria-box">
    <div class="title small bold">{{ 'translations.quiz.settings.feedback.label' | transloco }}</div>

    <mat-radio-group formControlName="resultDisplayPolicy" class="pass-criteria-options-list">
      <mat-radio-button
        class="pass-criteria-option"
        value="SUMMARY"
        color="primary"
        name="reportSummary"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.feedback.options.summary' | transloco }}</span>
      </mat-radio-button>

      <mat-radio-button
        class="pass-criteria-option"
        value="DETAILED"
        color="primary"
        name="reportSummary"
        tabIndex="0">
        <span class="small">{{ 'translations.quiz.settings.feedback.options.detailed' | transloco }}</span>
      </mat-radio-button>

    </mat-radio-group>
  </div>
</form>
