/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  DiagnosticFormattedQuestion,
  DiagnosticQuestionCopy,
  DiagnosticQuestionRequest,
  DiagnosticQuestionResponse,
  Organization
} from '../../../../../shared/models';
import { DATA_SERVICE, QuizDataService } from '../../../../services/data.service';
import { Store } from '@ngxs/store';
import { LanguageCodeHelper } from '../../../../../shared/helpers/language-code-helper';
import { SupportedLanguage } from '../../../../../shared/models/languages/languages.model';
import { ResourceAdminState } from '../../../../../page-modules/resource/store/admin/resource-admin.state';

@Component({
  selector: 'ptl-quiz-questions-section',
  templateUrl: './quiz-questions-section.component.html'
})
export class QuizQuestionsSectionComponent implements OnChanges {

  /** Receives questions to edit */
  @Input() questions: DiagnosticQuestionResponse[] = [];

  @Input() quizUid: string;

  @Input() languageCode: string;

  @Input() currentLanguage: SupportedLanguage;

  @Input() organization: Organization;

  /** Emits the questionsChanged event */
  @Output() questionsChanged = new EventEmitter<void>();

  resourceUid: string;

  questionTypes = ['RATING_QUESTION', 'MULTIPLE_CHOICE_QUESTION', 'DRAGDROP_QUESTION'];

  formattedQuestions: DiagnosticFormattedQuestion[];

  expanded = true;

  constructor(
    @Inject(DATA_SERVICE) private dataService: QuizDataService,
    private store: Store
  ) {
    this.resourceUid = this.store.selectSnapshot(ResourceAdminState.resourceUid);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.questions) {
      this.formatQuestions();
    }
  }

  addQuestion(question: DiagnosticQuestionRequest) {
    this.dataService.addQuestion(this.resourceUid, this.quizUid, question, this.languageCode).subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          this.questions.push(value);
          this.formatQuestions();
          this.questionsChanged.emit();
        }
      }
    );
  }

  copyQuestion(data: DiagnosticQuestionCopy) {
    this.dataService.copyQuestion(this.resourceUid, this.quizUid, data.questionUid, this.languageCode).subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          this.questions.splice(data.index + 1, 0, value);
          this.formatQuestions();
          this.questionsChanged.emit();
        }
      }
    );
  }

  updateQuestion(question: DiagnosticQuestionRequest) {
    this.dataService.updateQuestion(this.resourceUid, this.quizUid, question.uid, question, this.languageCode).subscribe(
      ({ isSuccess, value }) => {
        if (isSuccess) {
          const idx = this.questions.findIndex(q => q.uid === value.uid);
          this.questions.splice(idx, 1, value);
          this.formatQuestions();
          this.questionsChanged.emit();
        }
      }
    );
  }

  reorderQuestions(tableData: DiagnosticFormattedQuestion[]) {
    // TODO
  }

  onFeedbackRemoved(question: DiagnosticQuestionRequest) {
    this.dataService.clearQuestionFeedback(this.resourceUid, this.quizUid, question.uid, this.languageCode)
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          const idx = this.questions.findIndex(q => q.uid === question.uid);
          this.questions[idx].feedback = [];
          this.formatQuestions();
          this.questionsChanged.emit();
        }
      });
  }

  deleteQuestion(question: DiagnosticQuestionRequest) {
    this.dataService.deleteQuestion(this.resourceUid, this.quizUid, question.uid).subscribe(
      ({ isSuccess }) => {
        if (isSuccess) {
          const idx = this.questions.findIndex(q => q.uid === question.uid);
          this.questions.splice(idx, 1);
          this.formatQuestions();
          this.questionsChanged.emit();
        }
      }
    );
  }

  private formatQuestions() {
    this.formattedQuestions = this.questions.map(question => ({
      ...question,
      instruction: LanguageCodeHelper.getDataByUserLanguageCode(question.instructions, this.organization, this.languageCode),
      value: LanguageCodeHelper.getDataByUserLanguageCode(question.values, this.organization, this.languageCode),
      choices: question.options.map(option =>
        ({
          ...option,
          optionUid: option.uid,
          value: LanguageCodeHelper.getDataByUserLanguageCode(option.values, this.organization, this.languageCode),
        })),
      title: LanguageCodeHelper.getDataByUserLanguageCode(
        question.instructions,
        this.organization,
        this.languageCode).value.replace(/<[^>]*>/g, ' ')
        .replace(/&nbsp;/g, ' '),
    }));
  }

}
