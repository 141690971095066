<!--
  ~ Copyright (C) 2023 - Potentially Ltd
  ~
  ~ Please see distribution for license.
  -->

<div class="framework-chips-list">
  <div class="framework-chip-item-row f_framework-chip-item-row" *ngFor="let tag of childrenTags; let i = index;">
    <div class="framework-chip-item-row-outer f_framework-chip-item-row-outer">
      <ng-container *ngIf="tag.children && tag.children.length">
        <button mat-icon-button class="drop-down-btn" tabindex="0"
                [attr.aria-label]="('translations.global.ariaLabels.expand' | transloco: {title: tag.title})"
                (click)="toggleChildrenView($event)">
          <svg class="svg icon arrow-down" aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#chevron-down"></use>
          </svg>
          <svg class="svg icon arrow-up"  aria-hidden="true">
            <use ogSvgUnify="assets/sprite/sprite.svg#chevron-up"></use>
          </svg>
        </button>
      </ng-container>
      <div class="framework-chip-item-row-inner">
        <div class="framework-chip-item small" [ngClass]="{ 'has-child' : tag.children && tag.children.length }" [class.category]="frameworkType === 'CATEGORIES'">
          {{tag.title}}
        </div>
        <div class="framework-chip-item-actions">
          <button class="mat-icon-button action-btn" (click)="openChildSearchInput(tag)">
            <svg class="svg f_inside-dialog" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#link"></use>
            </svg>
          </button>
          <button class="mat-icon-button action-btn" (click)="onDeleteItem(tag)">
            <svg class="svg f_inside-dialog" aria-hidden="true">
              <use ogSvgUnify="assets/sprite/sprite.svg#delete"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="framework-add-child-container" *ngIf="tag.tagId === editableTag?.tagId">
      <input
        #inputElement
        matInput
        class="search-child-input"
        [matAutocomplete]="childTagsAuto"
        type="text"
        placeholder="Search for children"
        [attr.aria-label]="'Search for children'"
        (paste)="onChildSearchTextPaste($event, tag)"
        (keyup)="onChildSearchInputChange($event, tag)"/>

      <button mat-icon-button
              type="button"
              class="remove"
              (click)="closeInput()">
        <svg class="svg svg-icon f_inside-dialog" aria-hidden="true">
          <use ogSvgUnify="assets/sprite/sprite.svg#close"></use>
        </svg>
      </button>
      <mat-autocomplete
        [panelWidth]="450"
        #childTagsAuto="matAutocomplete"
        [displayWith]="disabledAutocomplete"
        (optionSelected)="onChildTagSelect($event)">
        <mat-option *ngFor="let option of childrenFilteredTags" class="f_inside-dialog" [value]="option">
          {{ option.title }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <div class="child-tree-container f_child-tree-container"
         *ngIf="tag.children && tag.children.length">
      <ptl-frameworks-tags-list-tree
        [frameworkType]="frameworkType"
        (tagDeleted)="onChildTagDeleteItem($event)"
        (childTagAdded)="onChildAdded($event)"
        [allTags]="allTags"
        [childrenTags]="tag.children">
      </ptl-frameworks-tags-list-tree>
    </div>
  </div>
</div>
