<div [ngStyle]="{'top.px': position, 'left.px': leftPosition}" class="toolbar-options-container">
  <div class="toolbar-option-tooltip-box"
       [class.is_active]="tooltipData?.buttonType === selectedOptionButtonType && tooltipData?.subType === selectedOptionSubType">
    <div class="toolbar-option-tooltip-image" *ngIf="tooltipData?.image">
      <img [src]="tooltipData?.image" alt=""/>
    </div>
    <div class="toolbar-option-tooltip-text">
      <div class="title medium bold">
        {{ tooltipData?.title | transloco }}
      </div>
      <div class="description small">
        {{ tooltipData?.description | transloco }}
      </div>
    </div>
  </div>
</div>
