/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { ChangeDetectorRef, Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { UserAuthState } from '../../store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AUTH_SERVICE, BasicAuthService } from '../../services/basic-auth.service';
import { SnackbarHelper } from '../../../shared/helpers/snackbar-helper';
import { TranslationService } from '../../../shared/services/translation/translation.service';
import { InvisibleReCaptchaComponent, ReCaptchaV3Service, ScriptService } from 'ngx-captcha';
import { environment } from '../../../../environments/environment';
import { RedirectHelper } from '../../../page-modules/resource/store/editor/content/helpers/redirect.helper';
import { MaintenanceService } from '../../../shared/services/maintenance/maintenance.service';

@Component({
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  @ViewChild('captchaElem', { static: false }) captchaElem: InvisibleReCaptchaComponent;

  isLoading: boolean;
  email: string;
  reCaptchaElement = null;
  reCaptchaSiteKeyV2 = environment.reCaptchaSiteKeyV2;
  showMaintenanceOverlay: boolean;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    @Inject(AUTH_SERVICE) private authService: BasicAuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngZone: NgZone,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private captchaService: ReCaptchaV3Service,
    private captchaScriptService: ScriptService,
    private maintenanceService: MaintenanceService,
  ) {
    this.showMaintenanceOverlay = this.maintenanceService.showMaintenanceMessage();
  }

  ngOnInit() {
    this.email = this.activatedRoute.snapshot.queryParamMap.get('email');
  }

  resendEmailVerification() {
    if (this.email) {
      this.captchaElem.execute();
    }
  }

  resetCaptcha(): void {
    this.cdr.detectChanges();
  }

  proceedIfValid(captchaResponseV2: string) {
    this.proceedResend(captchaResponseV2);
  }

  private captchaCleanup() {
    this.captchaScriptService.cleanup();
    this.captchaElem.resetCaptcha();
  }

  private proceedResend(captchaResponseV2: string) {
    this.isLoading = true;
    this.captchaScriptService.cleanup();
    const organizationUid = this.store.selectSnapshot(UserAuthState.organizationDetails)._id;
    const email = this.email;
    this.captchaService.execute(environment.reCaptchaSiteKeyV3, 'verifyFolioEmail', (captchaResponseV3: string) => {
      this.authService.resendVerificationEmail(email, organizationUid, captchaResponseV3, captchaResponseV2)
        .subscribe(({ isSuccess, error }) => {
          this.isLoading = false;
          this.captchaCleanup();
          if (isSuccess) {
            RedirectHelper.redirectByUrl(this.ngZone, this.router, this.activatedRoute, '/signin');
            SnackbarHelper.showTranslatableSnackBar(this.ngZone, this.snackBar, this.translationService, 'verifyYourAccount');
          } else {
            SnackbarHelper.showSnackBar(this.ngZone, this.snackBar, error);
          }
        });
    });
  }

}
