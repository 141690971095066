/*
 * Copyright (C) 2019 - Potentially Ltd
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TriggerUserAccountVerification } from '../../store/user-auth.actions';
import { ActivatedRoute } from '@angular/router';
import { UserAuthState } from '../../store/user-auth.state';
import { Observable } from 'rxjs';
import { Organization } from '../../../shared/models';

@Component({
  selector: 'ptl-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.scss'],
})
export class AccountVerificationComponent {

  @Select(UserAuthState.organizationDetails)
  organizationData$: Observable<Organization>;

  constructor(store: Store, route: ActivatedRoute) {
    const token = route.snapshot.queryParamMap.get('token');
    if (token) {
      store.dispatch(new TriggerUserAccountVerification(token));
    }
  }
}
